import React from 'react';
 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a>
        </div> */}

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Scaling-Sustainability-Maturity-Framework">English</NavLink></a>
         
        </div>

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}

       
        <h1 id="mhead">झा स्केलिंग स्थिरता परिपक्वता ढांचा</h1>
   
    


     
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/scalee.webp'></img>
            </div>
            <div>
           <p>यहां झा स्केलिंग सस्टेनेबिलिटी मैच्योरिटी फ्रेमवर्क है जो नीचे दिए गए विवरण के साथ निर्माणाधीन है। परिपक्वता के चार स्तरों के साथ मानव कल्याण, प्रकृति कल्याण और पृथ्वी कल्याण के लिए स्केलिंग स्थिरता फ्रेमवर्क बनाने में सावधानीपूर्वक योजना और विचार शामिल है। ऐसी रूपरेखा विकसित करने में आपकी सहायता के लिए यहां एक सामान्य मार्गदर्शिका दी गई है:


            </p>
            <h2>परिपक्वता के चार स्तर परिभाषित करें</h2>
           
<ol>
    <li><span>दीक्षा : </span>
    <ul>
        <li>स्थिरता सिद्धांतों के प्रति बुनियादी जागरूकता और प्रतिबद्धता।</li>
        <li>स्थायी प्रथाओं को शामिल करने के प्रारंभिक प्रयास।</li>
        <li>सीमित दायरा और प्रभाव.</li>
    </ul>

</li>


<li><span>विकास :</span>
    <ul>
        <li>मानव, प्रकृति और पृथ्वी कल्याण के अंतर्संबंध की बढ़ती समझ।</li>
        <li>व्यापक स्थिरता पहलों को लागू करना।</li>
        <li>विभिन्न क्षेत्रों में प्रयासों का विस्तार।</li>
    </ul>

</li>


<li><span>अनुकूलन :</span>
    <ul>
        <li>स्थायी प्रथाओं का उन्नत एकीकरण।</li>
        <li>डेटा और फीडबैक के आधार पर निरंतर सुधार।</li>
        <li>हितधारकों के साथ सहयोगात्मक भागीदारी।</li>
    </ul>

</li>
  
<li><span>परिवर्तन : </span>
    <ul>
        <li>स्थिरता के लिए समग्र और परिवर्तनकारी दृष्टिकोण।</li>
        <li>उद्योग मानक स्थापित करने में नेतृत्व।</li>
        <li>उभरती चुनौतियों के लिए निरंतर नवाचार और अनुकूलन।</li>
    </ul>

</li>
</ol>


<h2>फ़्रेमवर्क घटक</h2>
           
           <ol>
               <li><span>मानव कल्याण : </span>
               <ul>
                   <li><span>स्वास्थ्य और कल्याण कार्यक्रम: शारीरिक, मानसिक और भावनात्मक कल्याण को बढ़ावा देने वाली पहलों को लागू करें।</span></li>
                   <li><span>कार्य-जीवन संतुलन : </span>ऐसे वातावरण को बढ़ावा दें जो कार्य-जीवन संतुलन और कर्मचारी संतुष्टि को प्राथमिकता दे।</li>
                   <li><span>सामुदायिक जुड़ाव : </span>कर्मचारियों को स्थानीय समुदायों के साथ जुड़ने और उनका समर्थन करने के लिए प्रोत्साहित करें।</li>
               </ul>
           
           </li>

           <li><span>प्रकृति कल्याण : </span>
               <ul>
                   <li><span>जैव विविधता संरक्षण : </span>जैव विविधता को संरक्षित और बढ़ाने के लिए प्रथाओं को लागू करें।</li>
                   <li><span>पर्यावरण-अनुकूल संचालन : </span>पर्यावरणीय प्रभाव को कम करने के लिए प्रक्रियाओं को अनुकूलित करें।</li>
                   <li><span>स्थायी सोर्सिंग :</span>स्थायी रूप से प्राप्त सामग्रियों के उपयोग को प्राथमिकता दें।</li>
               </ul>
           
           </li>

           <li><span>पृथ्वी कल्याण : </span>
               <ul>
                   <li><span>कार्बन तटस्थता : </span>नवीकरणीय ऊर्जा और कार्बन ऑफसेट पहल के माध्यम से कार्बन तटस्थता के लिए प्रयास करें।</li>
                   <li><span>अपशिष्ट न्यूनीकरण : </span>अपशिष्ट न्यूनीकरण और पुनर्चक्रण कार्यक्रम लागू करें।</li>
                   <li><span>जलवायु लचीलापन : </span>जलवायु परिवर्तन के प्रभाव को अनुकूलित करने और कम करने के लिए रणनीतियाँ विकसित करें।</li>
               </ul>
           
           </li>
           </ol>



           <h2>कार्यान्वयन चरण</h2>
           
           <ol>
               <li><span>आकलन : </span>
               <ul>
                   <li>कल्याण के प्रत्येक आयाम में वर्तमान प्रथाओं का प्रारंभिक मूल्यांकन करें।</li>
                   <li>शक्तियों, कमजोरियों, अवसरों और खतरों को पहचानें।</li>
                
               </ul>
           
           </li>
           
           
           <li><span>लक्ष्य की स्थापना :</span>
               <ul>
                   <li>मानव, प्रकृति और पृथ्वी कल्याण में परिपक्वता के प्रत्येक स्तर के लिए विशिष्ट, मापने योग्य, प्राप्त करने योग्य, प्रासंगिक और समयबद्ध (स्मार्ट) लक्ष्य स्थापित करें।</li>
               </ul>
           
           </li>
           
           
           <li><span>कार्यान्वयन योजनाएँ : </span>
               <ul>
                   <li>परिपक्वता के प्रत्येक स्तर के लिए कार्यों, जिम्मेदारियों और समय-सीमाओं को निर्दिष्ट करते हुए विस्तृत योजनाएँ विकसित करें।</li>
                   <li>विभिन्न संदर्भों में स्केलेबिलिटी और प्रतिकृति पर विचार करें।</li>
               </ul>
           
           </li>
             
           <li><span>जाचना और परखना : </span>
               <ul>
                   <li>मजबूत निगरानी और मूल्यांकन तंत्र लागू करें।</li>
                   <li>नियमित रूप से प्रगति का आकलन करें, फीडबैक एकत्र करें और तदनुसार रणनीतियों को समायोजित करें।
</li>
               </ul>
           
           </li>


           <li><span>हितधारकों की वचनबद्धता : </span>
               <ul>
                   <li>कर्मचारियों, समुदायों, आपूर्तिकर्ताओं और नियामक निकायों सहित सभी स्तरों पर हितधारकों के साथ जुड़ें।</li>
                   <li>सहयोग और साझा जिम्मेदारी को बढ़ावा दें।

</li>
               </ul>
           
           </li>


           <li><span>शिक्षा एवं संचार : </span>
               <ul>
                   <li>जागरूकता बढ़ाने और स्थिरता की संस्कृति को बढ़ावा देने के लिए सतत शिक्षा और संचार प्रदान करें।</li>
                   <li>उपलब्धियों का जश्न मनाएं और सर्वोत्तम प्रथाओं को साझा करें।

</li>
               </ul>
           
           </li>

           <li><span>निरंतर सुधार : </span>
               <ul>
                   <li>निरंतर सुधार की संस्कृति को अपनाएं।</li>
                   <li>उभरते मानकों और चुनौतियों के साथ तालमेल बिठाने के लिए स्थिरता ढांचे को नियमित रूप से दोबारा देखें और अद्यतन करें।

</li>
               </ul>
           
           </li>

           </ol>
           

<p>अपने संगठन या पहल के विशिष्ट संदर्भ के आधार पर रूपरेखा को अनुकूलित करना याद रखें, और स्थिरता और संबंधित क्षेत्रों के विशेषज्ञों से इनपुट लें।</p>
           </div>
        </div>









               
        
       

    </div>
);
}