import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        <a href=""><NavLink to="/Zha-Global-Community-of-Practice">English</NavLink></a>
        </div>
 

            <div className="social-ma">

<h1>झा ग्लोबल कम्युनिटी ऑफ प्रैक्टिस</h1>
</div>


<div className="internship">
        <img src="../img/practice.webp"></img>
        </div>
<div className="goalpointsp">

<p>
वैश्विक स्थिरता समुदाय अभ्यास (सीओपी) बनाने में ज्ञान साझा करने, सहयोग करने और स्थिरता प्रथाओं को आगे बढ़ाने के लिए विशेषज्ञों और चिकित्सकों को एक साथ लाना शामिल है। यहां आपके सीओपी के लिए एक सुझाई गई रूपरेखा दी गई है:
</p>


<ol className="goalpoints">
    <li><span>उद्देश्य : </span> </li>
    <ul>
        <li>उद्देश्य को परिभाषित करें: स्थिरता में विचारों, सर्वोत्तम प्रथाओं और नवाचारों के आदान-प्रदान को सुविधाजनक बनाना।
 </li>
        <li>वैश्विक स्थिरता चुनौतियों पर सहयोग को प्रोत्साहित करें।
 </li>
 <li>सीखने और विकास के लिए एक सहायक समुदाय को बढ़ावा देना।
</li>
    </ul>

    <li><span>सदस्यता : </span> </li>
    <ul>
        <li>स्थिरता के प्रति जुनूनी पेशेवरों, विशेषज्ञों और संगठनों को आमंत्रित करें।
 </li>
        <li>उद्योगों, भौगोलिक क्षेत्रों और विशेषज्ञता क्षेत्रों में विविध प्रतिनिधित्व।
 </li>
    </ul>

    <li><span>संचार : </span> </li>
    <ul>
        <li>चर्चा, संसाधन साझाकरण और सहयोग के लिए एक समर्पित ऑनलाइन मंच स्थापित करें।
 </li>
        <li>वास्तविक समय पर बातचीत की सुविधा के लिए नियमित आभासी बैठकें, वेबिनार या कार्यशालाएँ।
 </li>
    </ul>


    <li><span>सामग्री और ज्ञान साझा करना : </span> </li>
    <ul>
        <li>केस स्टडीज, सफलता की कहानियां और सीखे गए सबक साझा करें।
 </li>
        <li>अनुसंधान परियोजनाओं, श्वेतपत्रों या प्रकाशनों पर सहयोग करें।
 </li>
    </ul>

    <li><span>कामकाजी समूह : </span> </li>
    <ul>
        <li>विशिष्ट स्थिरता स्तंभों या उद्योगों को संबोधित करते हुए केंद्रित उपसमूह बनाएं।
 </li>
        <li>सदस्यों को परियोजनाओं या पहलों में सक्रिय रूप से योगदान करने में सक्षम बनाना।
 </li>
    </ul>

    <li><span>आयोजन :</span> </li>
    <ul>
        <li>नवाचारों को प्रदर्शित करने और उपलब्धियों का जश्न मनाने के लिए वार्षिक सम्मेलन या कार्यक्रम आयोजित करें।
 </li>
        <li>प्रासंगिक स्थिरता विषयों पर मुख्य वक्ता और पैनल चर्चाएँ प्रस्तुत करें।
 </li>
    </ul>

    <li><span>साझेदारी : </span> </li>
    <ul>
        <li>प्रभाव बढ़ाने के लिए शैक्षणिक संस्थानों, गैर सरकारी संगठनों और व्यवसायों के साथ सहयोग करें।
 </li>
        <li>संयुक्त पहल या अनुसंधान परियोजनाओं के लिए साझेदारी स्थापित करें।
 </li>
    </ul>

    <li><span>मेट्रिक्स और रिपोर्टिंग : </span> </li>
    <ul>
        <li>स्थिरता को बढ़ावा देने पर सीओपी के प्रभाव को मापने के लिए मेट्रिक्स विकसित करें।
 </li>
        <li>सदस्यों और हितधारकों को नियमित रूप से प्रगति और उपलब्धियों की रिपोर्ट करें।
 </li>
    </ul>

    <li><span>प्रशिक्षण एवं विकास : </span> </li>
    <ul>
        <li>उभरती स्थिरता प्रवृत्तियों और उपकरणों पर प्रशिक्षण सत्र या कार्यशालाएँ पेश करें।
 </li>
        <li>सदस्यों के बीच ज्ञान हस्तांतरण के लिए परामर्श कार्यक्रम।
 </li>
    </ul>

    <li><span>प्रतिपुष्टि व्यवस्था : </span> </li>
    <ul>
        <li>सदस्य इनपुट के आधार पर सीओपी में लगातार सुधार करने के लिए फीडबैक लूप स्थापित करें।
 </li>
        <li>गतिविधियों की प्रासंगिकता और प्रभावशीलता का नियमित रूप से आकलन करें।
 </li>
    </ul>

    
  </ol>
  <p>वैश्विक स्थिरता समुदाय अभ्यास की सफलता के लिए सदस्यों के बीच समावेशिता, सक्रिय भागीदारी और साझा जिम्मेदारी की भावना को बढ़ावा देना याद रखें।</p>
</div>
        
     

        </div>

    );
}

