import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        <a href=""><NavLink to="/Zha-Global-Community-of-Practice">English</NavLink></a>
        </div>
 

            <div className="social-ma">

<h1>ഴ ഗ്ലോബൽ കമ്മ്യൂണിറ്റി ഓഫ് പ്രാക്ടീസ്</h1>
</div>


<div className="internship">
        <img src="../img/practice.webp"></img>
        </div>
<div className="goalpointsp">

<p>
ഒരു ഗ്ലോബൽ സസ്‌റ്റെയ്‌നബിലിറ്റി കമ്മ്യൂണിറ്റി ഓഫ് പ്രാക്ടീസ് (സി‌ഒ‌പി) സൃഷ്‌ടിക്കുന്നത് അറിവ് പങ്കിടുന്നതിനും സഹകരിക്കുന്നതിനും സുസ്ഥിരതാ സമ്പ്രദായങ്ങൾ മുന്നോട്ട് കൊണ്ടുപോകുന്നതിനും വിദഗ്ധരെയും പ്രാക്ടീഷണർമാരെയും ഒരുമിച്ച് കൊണ്ടുവരുന്നത് ഉൾപ്പെടുന്നു. നിങ്ങളുടെ CP-ന് നിർദ്ദേശിച്ച ഒരു രൂപരേഖ ഇതാ:


</p>


<ol className="goalpoints">
    <li><span>ലക്ഷ്യങ്ങൾ : </span> </li>
    <ul>
        <li>ഉദ്ദേശ്യം നിർവചിക്കുക: സുസ്ഥിരതയിൽ ആശയങ്ങൾ, മികച്ച സമ്പ്രദായങ്ങൾ, നവീകരണങ്ങൾ എന്നിവയുടെ കൈമാറ്റം സുഗമമാക്കുക.
 </li>
        <li>ആഗോള സുസ്ഥിരത വെല്ലുവിളികളിൽ സഹകരണം പ്രോത്സാഹിപ്പിക്കുക.
 </li>
 <li>പഠനത്തിനും വികസനത്തിനും പിന്തുണ നൽകുന്ന ഒരു സമൂഹത്തെ വളർത്തുക.
</li>
    </ul>

    <li><span>അംഗത്വം : </span> </li>
    <ul>
        <li>സുസ്ഥിരതയിൽ താൽപ്പര്യമുള്ള പ്രൊഫഷണലുകളെയും വിദഗ്ധരെയും ഓർഗനൈസേഷനുകളെയും ക്ഷണിക്കുക.
 </li>
        <li>വ്യവസായങ്ങൾ, ഭൂമിശാസ്ത്രം, വൈദഗ്ധ്യം എന്നിവയിലുടനീളം വൈവിധ്യമാർന്ന പ്രാതിനിധ്യം.
 </li>
    </ul>

    <li><span>ആശയവിനിമയം :</span> </li>
    <ul>
        <li>ചർച്ചകൾ, വിഭവങ്ങൾ പങ്കിടൽ, സഹകരണം എന്നിവയ്ക്കായി ഒരു സമർപ്പിത ഓൺലൈൻ പ്ലാറ്റ്ഫോം സ്ഥാപിക്കുക.
 </li>
        <li>തത്സമയ ആശയവിനിമയം സുഗമമാക്കുന്നതിന് പതിവ് വെർച്വൽ മീറ്റിംഗുകൾ, വെബിനാറുകൾ അല്ലെങ്കിൽ വർക്ക് ഷോപ്പുകൾ.
 </li>
    </ul>


    <li><span>ഉള്ളടക്കവും അറിവും പങ്കിടൽ : </span> </li>
    <ul>
        <li>കേസ് പഠനങ്ങളും വിജയഗാഥകളും പഠിച്ച പാഠങ്ങളും പങ്കിടുക.
 </li>
        <li>ഗവേഷണ പ്രോജക്ടുകൾ, വൈറ്റ്പേപ്പറുകൾ അല്ലെങ്കിൽ പ്രസിദ്ധീകരണങ്ങൾ എന്നിവയിൽ സഹകരിക്കുക.
 </li>
    </ul>

    <li><span>വർക്കിംഗ് ഗ്രൂപ്പുകൾ : </span> </li>
    <ul>
        <li>നിർദ്ദിഷ്ട സുസ്ഥിര സ്തംഭങ്ങളെയോ വ്യവസായങ്ങളെയോ അഭിസംബോധന ചെയ്യുന്ന ഫോക്കസ് ചെയ്ത ഉപഗ്രൂപ്പുകൾ സൃഷ്ടിക്കുക.
 </li>
        <li>പ്രോജക്ടുകളിലേക്കോ സംരംഭങ്ങളിലേക്കോ സജീവമായി സംഭാവന നൽകാൻ അംഗങ്ങളെ പ്രാപ്തരാക്കുക.
 </li>
    </ul>

    <li><span>ഇവന്റുകൾ :</span> </li>
    <ul>
        <li>പുതുമകൾ പ്രദർശിപ്പിക്കുന്നതിനും നേട്ടങ്ങൾ ആഘോഷിക്കുന്നതിനും വാർഷിക കോൺഫറൻസുകളോ പരിപാടികളോ സംഘടിപ്പിക്കുക.
 </li>
        <li>പ്രസക്തമായ സുസ്ഥിരത വിഷയങ്ങളിൽ മുഖ്യ സ്പീക്കറുകളും പാനൽ ചർച്ചകളും ഫീച്ചർ ചെയ്യുക.
 </li>
    </ul>

    <li><span>പങ്കാളിത്തങ്ങൾ : </span> </li>
    <ul>
        <li>സ്വാധീനം വർദ്ധിപ്പിക്കുന്നതിന് അക്കാദമിക് സ്ഥാപനങ്ങൾ, എൻ‌ജി‌ഒകൾ, ബിസിനസ്സുകൾ എന്നിവയുമായി സഹകരിക്കുക.
 </li>
        <li>സംയുക്ത സംരംഭങ്ങൾക്കോ ഗവേഷണ പദ്ധതികൾക്കോ പങ്കാളിത്തം സ്ഥാപിക്കുക.
 </li>
    </ul>

    <li><span>മെട്രിക്സും റിപ്പോർട്ടിംഗും :</span> </li>
    <ul>
        <li>സുസ്ഥിരത പ്രോത്സാഹിപ്പിക്കുന്നതിൽ കോപിയുടെ സ്വാധീനം അളക്കാൻ മെട്രിക്‌സ് വികസിപ്പിക്കുക.
 </li>
        <li>പുരോഗതിയും നേട്ടങ്ങളും അംഗങ്ങൾക്കും പങ്കാളികൾക്കും പതിവായി റിപ്പോർട്ട് ചെയ്യുക.
 </li>
    </ul>

    <li><span>പരിശീലനവും വികസനവും : </span> </li>
    <ul>
        <li>ഉയർന്നുവരുന്ന സുസ്ഥിര പ്രവണതകളെയും ടൂളുകളേയും കുറിച്ച് പരിശീലന സെഷനുകളോ വർക്ക്ഷോപ്പുകളോ വാഗ്ദാനം ചെയ്യുക.
 </li>
        <li>അംഗങ്ങൾക്കിടയിൽ അറിവ് കൈമാറ്റം ചെയ്യുന്നതിനുള്ള മെന്റർഷിപ്പ് പ്രോഗ്രാമുകൾ.
 </li>
    </ul>

    <li><span>പ്രതികരണ സംവിധാനം : </span> </li>
    <ul>
        <li>അംഗങ്ങളുടെ ഇൻപുട്ടിനെ അടിസ്ഥാനമാക്കി CoP തുടർച്ചയായി മെച്ചപ്പെടുത്തുന്നതിന് ഒരു ഫീഡ്ബാക്ക് ലൂപ്പ് സ്ഥാപിക്കുക.
 </li>
        <li>പ്രവർത്തനങ്ങളുടെ പ്രസക്തിയും ഫലപ്രാപ്തിയും പതിവായി വിലയിരുത്തുക.
 </li>
    </ul>

    
  </ol>
  <p>ആഗോള സുസ്ഥിരതാ കമ്മ്യൂണിറ്റി ഓഫ് പ്രാക്ടീസിന്റെ വിജയത്തിനായി അംഗങ്ങൾക്കിടയിൽ ഉൾപ്പെടുത്തൽ, സജീവ പങ്കാളിത്തം, പങ്കിട്ട ഉത്തരവാദിത്തബോധം എന്നിവ പ്രോത്സാഹിപ്പിക്കുന്നതിന് ഓർക്കുക.</p>
</div>
        
     

        </div>

    );
}

