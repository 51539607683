import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
   <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Family-Wellness-Program">English</NavLink></a>

        </div>
         
 
            <div className="social-ma">

{/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
<h1>ಝಾ ಕುಟುಂಬ ಸ್ವಾಸ್ಥ್ಯ ಕಾರ್ಯಕ್ರಮ: ಸಾಮರಸ್ಯ ಜೀವನಕ್ಕಾಗಿ ಪೂರ್ವಭಾವಿ ಸಮಾಲೋಚನೆ</h1>

<div className='second-mcontent'>
                    <img src='../../img/family.png'></img>
                </div>
 
 


 
<h3>ಉದ್ದೇಶ:</h3>
<p>ಝಾ ಕುಟುಂಬ ಸ್ವಾಸ್ಥ್ಯ ಕಾರ್ಯಕ್ರಮವು ಒತ್ತಡ, ಆತಂಕ ಮತ್ತು ವಾದಗಳನ್ನು ತಡೆಗಟ್ಟಲು ಪೂರ್ವಭಾವಿ ಸಮಾಲೋಚನೆಯನ್ನು ನೀಡುವ ಮೂಲಕ ಸಾಮರಸ್ಯದ ಜೀವನವನ್ನು ಉತ್ತೇಜಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾದ ಸಮಗ್ರ ಉಪಕ್ರಮವಾಗಿದೆ. ಗಂಡಂದಿರು, ಹೆಂಡತಿಯರು ಮತ್ತು ಮಕ್ಕಳ ಭಾವನಾತ್ಮಕ ಯೋಗಕ್ಷೇಮವನ್ನು ಬಲಪಡಿಸಲು ಈ ಕಾರ್ಯಕ್ರಮವನ್ನು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ, ಸವಾಲುಗಳನ್ನು ಒಟ್ಟಿಗೆ ಜಯಿಸುವ ಸಾಮರ್ಥ್ಯವನ್ನು ಹೊಂದಿರುವ ಚೇತರಿಸಿಕೊಳ್ಳುವ ಕುಟುಂಬ ಘಟಕವನ್ನು ಪೋಷಿಸುತ್ತದೆ.
</p>

 

<ol className="goalpoints">
    <h3>ಕಾರ್ಯಕ್ರಮದ ಘಟಕಗಳು:</h3>

<li><span>ಪೂರ್ವಭಾವಿ ಕುಟುಂಬ ಸಮಾಲೋಚನೆ ಅವಧಿಗಳು:</span>  
<ul>
    <li>ವೈಯಕ್ತಿಕ ಮತ್ತು ಸಾಮೂಹಿಕ ಒತ್ತಡಗಳನ್ನು ಪರಿಹರಿಸಲು ಪತಿ, ಪತ್ನಿ ಮತ್ತು ಮಕ್ಕಳಿಗೆ ವಿಶೇಷ ಸಮಾಲೋಚನೆ ಅವಧಿಗಳು.



</li>
    <li>ಪರಿಣಾಮಕಾರಿ ಸಂವಹನವನ್ನು ನಿರ್ಮಿಸಲು ಒತ್ತು ನೀಡುವುದು, ಪರಸ್ಪರರ ದೃಷ್ಟಿಕೋನಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ಮತ್ತು ಕುಟುಂಬದ ಡೈನಾಮಿಕ್ಸ್ ಅನ್ನು ಹೆಚ್ಚಿಸುವುದು.


</li>
</ul>
</li> 

<li><span>ಸಮಗ್ರ ಸ್ವಾಸ್ಥ್ಯ ಕಾರ್ಯಾಗಾರಗಳು: </span>  
<ul>
    <li>ಒತ್ತಡ ನಿರ್ವಹಣೆ, ಸಂಘರ್ಷ ಪರಿಹಾರ, ಮತ್ತು ಭಾವನಾತ್ಮಕ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವವನ್ನು ಬೆಳೆಸುವುದು ಸೇರಿದಂತೆ ವಿವಿಧ ವಿಷಯಗಳ ವ್ಯಾಪ್ತಿಯನ್ನು ಒಳಗೊಂಡಿರುವ ಸೂಕ್ತವಾದ ಕಾರ್ಯಾಗಾರಗಳು.




</li>
    <li>ಇಡೀ ಕುಟುಂಬಕ್ಕೆ ಭಾಗವಹಿಸಲು ಮತ್ತು ಒಟ್ಟಿಗೆ ಕಲಿಯಲು ಸಂವಾದಾತ್ಮಕ ಅವಧಿಗಳು.




</li>
</ul>
</li> 

<li><span>ಕುಟುಂಬ ಬಂಧ ಚಟುವಟಿಕೆಗಳು:</span>  
<ul>
    <li>ಏಕತೆಯ ಭಾವವನ್ನು ಬಲಪಡಿಸುವ, ಕುಟುಂಬದ ಬಾಂಧವ್ಯ ಮತ್ತು ತಂಡದ ಕೆಲಸಗಳನ್ನು ಉತ್ತೇಜಿಸುವ ಚಟುವಟಿಕೆಗಳನ್ನು ಸಂಯೋಜಿಸಿ.
</li>
    <li>ಕೌಟುಂಬಿಕ ಸಂಪರ್ಕಗಳನ್ನು ಬಲಪಡಿಸಲು ಹೊರಾಂಗಣ ಘಟನೆಗಳು, ಸೃಜನಶೀಲ ಕಾರ್ಯಾಗಾರಗಳು ಮತ್ತು ಸಹಯೋಗದ ಯೋಜನೆಗಳು.





</li>
</ul>
</li> 

<li><span>ವೈಯುಕ್ತಿಕ ಸ್ವಾಸ್ಥ್ಯ ಯೋಜನೆಗಳು: </span>  
<ul>
    <li>ಪ್ರತಿ ಕುಟುಂಬದ ಸದಸ್ಯರಿಗೆ ಅವರ ಅನನ್ಯ ಅಗತ್ಯತೆಗಳು ಮತ್ತು ಸವಾಲುಗಳನ್ನು ಆಧರಿಸಿ ವೈಯಕ್ತಿಕಗೊಳಿಸಿದ ಕ್ಷೇಮ ಯೋಜನೆಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲಾಗಿದೆ.
</li>
    <li>ಸಂದರ್ಭಗಳು ವಿಕಸನಗೊಂಡಂತೆ ಈ ಯೋಜನೆಗಳನ್ನು ಕಾರ್ಯಗತಗೊಳಿಸಲು ಮತ್ತು ಹೊಂದಿಸಲು ನಡೆಯುತ್ತಿರುವ ಬೆಂಬಲ.
</li>
</ul>
</li> 

<li><span>ಪೋಷಕರ ಬೆಂಬಲ ಮತ್ತು ಶಿಕ್ಷಣ: </span>  
<ul>
    <li>ಕಾರ್ಯಾಗಾರಗಳು ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳು ವಿಶೇಷವಾಗಿ ಪೋಷಕರಿಗೆ ತಮ್ಮ ಪೋಷಕರ ಕೌಶಲ್ಯಗಳನ್ನು ಹೆಚ್ಚಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ.
</li>
    <li>ಮಕ್ಕಳೊಂದಿಗೆ ಪರಿಣಾಮಕಾರಿ ಸಂವಹನ, ಸಕಾರಾತ್ಮಕ ಶಿಸ್ತು ತಂತ್ರಗಳು ಮತ್ತು ಪೋಷಣೆಯ ಮನೆಯ ವಾತಾವರಣವನ್ನು ಪೋಷಿಸುವ ಮಾರ್ಗದರ್ಶನ.
</li>
</ul>
</li> 
<li><span>ಯುವ ಸಬಲೀಕರಣ ಅಧಿವೇಶನಗಳು: </span>  
<ul>
    <li>ಮಕ್ಕಳು ಮತ್ತು ಹದಿಹರೆಯದವರು ಎದುರಿಸುತ್ತಿರುವ ವಿಶಿಷ್ಟ ಸವಾಲುಗಳನ್ನು ಪರಿಹರಿಸಲು ಮೀಸಲಾಗಿರುವ ವಿಶೇಷ ಕೌನ್ಸೆಲಿಂಗ್ ಅವಧಿಗಳು ಮತ್ತು ಕಾರ್ಯಾಗಾರಗಳು.
</li>
    <li>ಮುಕ್ತ ಸಂಭಾಷಣೆಯ ಉತ್ತೇಜನ ಮತ್ತು ನಿಭಾಯಿಸುವ ಕಾರ್ಯವಿಧಾನಗಳ ಅಭಿವೃದ್ಧಿ.
</li>
</ul>
</li> 

</ol>

 
 

 

 

 

 
<h3>ಪ್ರಯೋಜನಗಳು:</h3>
<ul className="goalpoints">
  <li>ಪರಿಣಾಮಕಾರಿ ಸಂವಹನ ಮತ್ತು ತಿಳುವಳಿಕೆಯ ಮೂಲಕ ಕುಟುಂಬ ಬಂಧಗಳನ್ನು ಬಲಪಡಿಸಲಾಗಿದೆ.</li>  
  <li>ಕುಟುಂಬದಲ್ಲಿನ ಒತ್ತಡ, ಆತಂಕ ಮತ್ತು ವಾದಗಳಲ್ಲಿ ಕಡಿತ.</li>  
  <li>ಪೋಷಕರು ಮತ್ತು ಮಕ್ಕಳಿಗಾಗಿ ಭಾವನಾತ್ಮಕ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವವನ್ನು ಹೆಚ್ಚಿಸಿದೆ.</li>  
  <li>ಕುಟುಂಬದ ಘಟಕದಲ್ಲಿ ತಂಡದ ಕೆಲಸ ಮತ್ತು ಸಮಸ್ಯೆ-ಪರಿಹರಿಸುವ ಕೌಶಲ್ಯಗಳ ಅಭಿವೃದ್ಧಿ.</li>  
  <li>ಧನಾತ್ಮಕ ಮತ್ತು ಬೆಂಬಲಿತ ಮನೆಯ ವಾತಾವರಣದ ಪ್ರಚಾರ.
</li>  
 
</ul>


<h3>ಭಾಗವಹಿಸುವುದು ಹೇಗೆ:</h3>
<ul className="goalpoints">
  <li>ಝಾ ಫ್ಯಾಮಿಲಿ ವೆಲ್ನೆಸ್ ಪ್ರೋಗ್ರಾಂನಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿರುವ ಕುಟುಂಬಗಳು ತಮ್ಮ ಮೀಸಲಾದ ಕೌನ್ಸೆಲಿಂಗ್ ಸೇವೆಗಳ ಹಾಟ್‌ಲೈನ್ ಅಥವಾ ವೆಬ್‌ಸೈಟ್ ಮೂಲಕ ಝಾ ಫೌಂಡೇಶನ್ ಅನ್ನು ತಲುಪುವ ಮೂಲಕ ನೋಂದಾಯಿಸಿಕೊಳ್ಳಬಹುದು.</li>  
  <li>ಭಾಗವಹಿಸುವಿಕೆಯು ಸ್ವಯಂಪ್ರೇರಿತ, ಗೌಪ್ಯ ಮತ್ತು ಸಮಗ್ರ ಯೋಗಕ್ಷೇಮಕ್ಕಾಗಿ ಪೂರ್ವಭಾವಿ ಬೆಂಬಲವನ್ನು ಬಯಸುವ ಕುಟುಂಬಗಳಿಗೆ ಮುಕ್ತವಾಗಿದೆ.</li>  
 
 
</ul>
     
       
       <p>ಝಾ ಫ್ಯಾಮಿಲಿ ವೆಲ್‌ನೆಸ್ ಪ್ರೋಗ್ರಾಂ ಅನ್ನು ಕುಟುಂಬಗಳು ಏಕೀಕೃತ ತಂಡವಾಗಿ ಜೀವನದ ಸವಾಲುಗಳನ್ನು ನ್ಯಾವಿಗೇಟ್ ಮಾಡಲು ಸಬಲೀಕರಣಗೊಳಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ, ಎಲ್ಲಾ ಸದಸ್ಯರಿಗೆ ಆರೋಗ್ಯಕರ ಮತ್ತು ಬೆಂಬಲ ವಾತಾವರಣವನ್ನು ಬೆಳೆಸುತ್ತದೆ. ಈ ಕಾರ್ಯಕ್ರಮದ ಕುರಿತು ಪ್ರತಿಕ್ರಿಯೆ ಅಥವಾ ಸುಧಾರಣೆಗೆ ಸಲಹೆಗಳು ಸ್ವಾಗತಾರ್ಹ.</p>
       
       
        </div>


        </div>




 
        


    );
}

