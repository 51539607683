import React from "react";
import "./Sponsors.css"
import { NavLink } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
export default function Sponsors(){

    return(

        <div>
                <div  id="language" >
        
                <a href=""><NavLink to="/sponsors">English</NavLink></a>


        </div>
            <div className="social-main">

            <h1>Zha Sponsorship Program</h1>
            </div>

            <div className="titlesponsor">
                <div className="titleimg">
                <img src="../img/billboard.png"></img>
                </div>
                <p>झा फाउंडेशन द्वारा आयोजित ऑर्गेनिक फार्मर्स लीग प्रतियोगिता के प्रायोजकों को प्रमुख प्रदर्शन के अवसरों का आनंद मिलेगा। आपके ब्रांड के विज्ञापनों को रणनीतिक रूप से प्रतिस्पर्धा स्थलों पर रखा जाएगा, यह सुनिश्चित करते हुए कि उन्हें विविध और संलग्न दर्शकों द्वारा देखा जाए। इसके अतिरिक्त, एक मूल्यवान प्रायोजक के रूप में, आपकी कंपनी को हमारी वेबसाइट पर समर्पित "प्रायोजक" अनुभाग में प्रमुखता से दिखाया जाएगा। यह ऑनलाइन उपस्थिति आपकी दृश्यता को और बढ़ाएगी और टिकाऊ कृषि और प्राकृतिक किसान लीग के मिशन का समर्थन करने के लिए आपकी प्रतिबद्धता को प्रदर्शित करेगी। एक प्रायोजक के रूप में हमारे साथ जुड़ें और पर्यावरण-अनुकूल कृषि पद्धतियों के प्रति उत्साही समुदाय के समक्ष अपने ब्रांड को प्रदर्शित करने के अवसर का लाभ उठाएं।</p>

            </div>
           

            <div className="freepik">
<h5 className="cardtitle">यहां जैविक किसान लीग के प्रायोजकों के लिए लाभ दिए गए हैं।</h5>
</div>

           
<div className="cards">
   
<Card style={{ width: '18rem' }}>
<Card.Body>
           <Card.Title>मीडिया कवरेज</Card.Title>
           <Card.Text>
           यूट्यूब मीडिया दिन में कम से कम 30 मिनट के लिए झा कार्यक्रमों की लाइव स्ट्रीम लेगा और हमारे झा कार्यक्रमों को टेलीविजन समाचार मीडिया में कवर किया जाएगा।  </Card.Text>
         </Card.Body>
       </Card>


   <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>बढ़ी हुई दृश्यता</Card.Title>
           <Card.Text>
           किसी प्रमुख कृषि क्षेत्र के स्थान पर 3 महीने के लिए होर्डिंग को प्रायोजित करके, आप लक्षित दर्शकों के बीच महत्वपूर्ण दृश्यता प्राप्त करते हैं। यह एक्सपोज़र आपके ब्रांड की पहचान को काफी हद तक बढ़ा सकता है।
           </Card.Text>
         </Card.Body>
       </Card>
   
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>लागत प्रभावी विज्ञापन</Card.Title>
           <Card.Text>
           एक कृषि क्षेत्र स्थान पर 3 महीने के लिए एक होर्डिंग प्रायोजित करके, आप केवल 12,000 रुपये की लागत पर अपने लक्षित दर्शकों तक प्रभावी ढंग से पहुंच सकते हैं। यह आपके ब्रांड या उत्पादों को बढ़ावा देने का एक अत्यधिक लागत प्रभावी तरीका है।
           </Card.Text>
         </Card.Body>
       </Card>
   
      
   
   </div>
   <div className="cards2">
   

   <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>ऑनलाइन दृश्यता</Card.Title>
           <Card.Text>
           भौतिक जमाखोरी प्लेसमेंट के अलावा, आपके प्रायोजन में ऑनलाइन प्रदर्शन भी शामिल है। आपका ब्रांड वेबसाइट पर प्रदर्शित किया जाएगा, जिससे आपकी डिजिटल उपस्थिति और पहुंच बढ़ेगी।
           </Card.Text>
         </Card.Body>
       </Card>
   
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>संयुक्त विज्ञापन के अवसर</Card.Title>
           <Card.Text>
           जब आप किसी होर्डिंग को प्रायोजित करते हैं, तो आपको अन्य डीलरों के साथ विज्ञापन करने का लाभ मिलता है। इस साझा प्लेटफ़ॉर्म से दृश्यता बढ़ सकती है क्योंकि ग्राहकों को एक ही स्थान पर कई व्यवसायों पर ध्यान देने की अधिक संभावना है।
           </Card.Text>
         </Card.Body>
       </Card>
   
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>अनुकूलित एक्सपोज़र</Card.Title>
           <Card.Text>
           यदि आपके ग्राहक को कई फार्म गेम स्पॉट पर विज्ञापन की आवश्यकता है, तो आप होर्डिंग प्लेसमेंट को बढ़ाकर आसानी से अपने प्रायोजन को बढ़ा सकते हैं। यह लचीलापन आपको अपनी विज्ञापन रणनीति को अपनी विशिष्ट आवश्यकताओं और बजट के अनुरूप बनाने की अनुमति देता है।
           </Card.Text>
         </Card.Body>
       </Card>
   
   </div>


   
 
   
        </div>
    
    );
}