import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
  return (
    <div>
      <div className="hmain">


      <div  id="language" >
        
        <a href=""><NavLink to="/Rural-Primary-Health-Care">English</NavLink></a>

        </div>

        {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <h1 id="mhead"> ழ கிராமப்புற ஆரம்ப சுகாதாரம்</h1>

        <div className='second-mcontent'>
          <img src='../../img/health.png'></img> 
        </div>


      </div>


      <section className="visionmi">

        <div className="purpose">

          <p>Zha அறக்கட்டளையில், சமூகங்களை மாற்றுவதற்கான அணுகக்கூடிய சுகாதாரத்தின் சக்தியை நாங்கள் நம்புகிறோம். சுகாதார சேவைகளில், குறிப்பாக கிராமப்புறங்களில் உள்ள இடைவெளியைக் குறைப்பதே எங்கள் நோக்கம், ஒவ்வொரு தனிநபருக்கும் தரமான ஆரம்ப சுகாதாரம் கிடைப்பதை உறுதி செய்வதாகும்.

          </p>
        </div>

        <h3>எங்கள் அர்ப்பணிப்பு</h3>
        <div className="missi">
          <h4 className="toleftneed">கிராமப்புற அதிகாரமளித்தல்:</h4>
          <p> ழ அறக்கட்டளையின் முன்முயற்சிகளின் மையத்தில், சிறந்த சுகாதார விளைவுகளுக்குத் தேவையான கருவிகள் மற்றும் ஆதாரங்களுடன் கிராமப்புற சமூகங்களை மேம்படுத்துவதற்கான அர்ப்பணிப்பு உள்ளது. தொலைதூரப் பகுதிகளில் உள்ள தனிநபர்கள் எதிர்கொள்ளும் தனித்துவமான சவால்களை நாங்கள் அங்கீகரிக்கிறோம் மற்றும் நிலையான சுகாதாரத் தீர்வுகளை வழங்குவதற்கு அர்ப்பணித்துள்ளோம்.</p>

          <h4 className="toleftneed">விரிவான சுகாதாரம்:</h4>
          <p>எங்கள் ஆரம்ப சுகாதார சேவைகள் பரந்த அளவிலான மருத்துவ, தடுப்பு மற்றும் ஆரோக்கிய திட்டங்களை உள்ளடக்கியது. வழக்கமான சோதனைகள் முதல் சுகாதாரக் கல்வி வரை, கிராமப்புற மக்களின் பல்வேறு தேவைகளை நிவர்த்தி செய்யும் ஒரு முழுமையான சுகாதார சுற்றுச்சூழல் அமைப்பை உருவாக்குவதை நோக்கமாகக் கொண்டுள்ளோம்.</p>
        </div>


        <h3>எங்கள் அணுகுமுறை</h3>
        <div className="missi">
          <h4 className="toleftneed">சமூகத்தை மையப்படுத்திய பராமரிப்பு:</h4>
          <p> ழ அறக்கட்டளை சமூகத்தை மையமாகக் கொண்ட சுகாதாரப் பாதுகாப்பின் வலிமையை நம்புகிறது. எங்கள் திட்டங்கள் உள்ளூர் சமூகங்களுடன் இணைந்து, அவர்களின் குறிப்பிட்ட தேவைகள், கலாச்சார நுணுக்கங்கள் மற்றும் கிடைக்கக்கூடிய வளங்களைக் கருத்தில் கொண்டு வடிவமைக்கப்பட்டுள்ளன. குடியிருப்பாளர்களுடன் கைகோர்த்து செயல்படுவதன் மூலம், உள்ளடக்கிய மற்றும் பயனுள்ள சுகாதார மாதிரியை உருவாக்க நாங்கள் முயற்சி செய்கிறோம்.</p>

          <h4 className="toleftneed">திறமையான சுகாதார வல்லுநர்கள்:</h4>
          <p>நேர்மறையான தாக்கத்தை ஏற்படுத்துவதில் ஆர்வமுள்ள அர்ப்பணிப்புள்ள சுகாதார நிபுணர்களின் குழுவில் நாங்கள் பெருமிதம் கொள்கிறோம். எங்கள் மருத்துவர்கள், செவிலியர்கள் மற்றும் உதவி ஊழியர்கள் மிகவும் திறமையானவர்கள் மட்டுமல்ல, அவர்கள் சேவை செய்யும் சமூகங்களுக்குள் நீடித்த தொடர்புகளை உருவாக்குவதற்கும் அர்ப்பணிப்புடன் உள்ளனர்.</p>
        </div>

        <h3>எங்கள் சேவைகள்</h3>
        <div className="missi">
          <h4 className="toleftneed">மொபைல் கிளினிக்குகள்:</h4>
          <p>ஜா அறக்கட்டளை மிகவும் தொலைதூர மூலைகளை அடையும் மொபைல் கிளினிக்குகளை இயக்குகிறது, தேவைப்படுபவர்களின் வீட்டு வாசலுக்கு நேரடியாக சுகாதார சேவையை கொண்டு வருகிறது. இந்த கிளினிக்குகள் சமீபத்திய மருத்துவ வசதிகளுடன் பொருத்தப்பட்டுள்ளன மற்றும் அனுபவம் வாய்ந்த சுகாதார நிபுணர்களால் பணியாற்றப்படுகின்றன.</p>

          <h4 className="toleftneed">சுகாதார கல்வி திட்டங்கள்:</h4>
          <p>தடுப்பு என்பது நல்ல ஆரோக்கியத்திற்கு முக்கியமாகும். தனிநபர்களின் நல்வாழ்வைப் பற்றிய தகவலறிந்த முடிவுகளை எடுப்பதற்குத் தேவையான அறிவைக் கொண்டவர்களை மேம்படுத்துவதற்காக நாங்கள் வழக்கமான சுகாதாரக் கல்வித் திட்டங்களை நடத்துகிறோம். சுகாதார நடைமுறைகள் முதல் நோய் தடுப்பு வரை, ஆரோக்கியமான வாழ்க்கைக்கான அடித்தளத்தை உருவாக்குவதே எங்கள் குறிக்கோள்.</p>

          <h4 className="toleftneed">அவசர பதில்:</h4>
          <p>கிராமப்புறங்களில் ஏற்படும் மருத்துவ அவசரநிலைகளுக்கு விரைவாகப் பதிலளிக்க Zha அறக்கட்டளை தயாராக உள்ளது. எங்களின் அவசரகாலப் பதிலளிப்புக் குழுக்கள் பலவிதமான சூழ்நிலைகளைக் கையாளப் பயிற்றுவிக்கப்பட்டுள்ளன, உதவி மிகவும் தேவைப்படும்போது அது வெகு தொலைவில் இல்லை என்பதை உறுதிசெய்கிறது.</p>
        </div>


      </section>
    </div>


  );
}

