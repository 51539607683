import React from 'react';
 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a>
        </div> */}

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Scaling-Sustainability-Maturity-Framework">English</NavLink></a>
         
        </div>

       
        <h1 id="mhead">ழ அளவிடுதல் நிலைத்தன்மை முதிர்வு கட்டமைப்பு
</h1>
   
    


     
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/scalee.webp'></img>
            </div>
            <div>
           <p>கீழே உள்ள விவரங்களுடன் கட்டமைக்கப்பட்ட Zha அளவிடுதல் நிலைத்தன்மை முதிர்வு கட்டமைப்பானது இங்கே உள்ளது. மனித ஆரோக்கியம், இயற்கை ஆரோக்கியம் மற்றும் பூமியின் ஆரோக்கியம் ஆகியவற்றிற்கான அளவிடுதல் நிலைத்தன்மை கட்டமைப்பை உருவாக்குவது நான்கு நிலை முதிர்ச்சியுடன் கவனமாக திட்டமிடல் மற்றும் பரிசீலிக்கப்படுகிறது. அத்தகைய கட்டமைப்பை உருவாக்க உங்களுக்கு உதவும் பொதுவான வழிகாட்டிகள்:

            </p>
            <h2>முதிர்ச்சியின் நான்கு நிலைகளை வரையறுக்கவும்</h2>
           
<ol>
    <li><span>துவக்கம் : </span>
    <ul>
        <li>கொள்கைகளுக்கான அடிப்படை விழிப்புணர்வு மற்றும் அர்ப்பணிப்பு.</li>
        <li>நிலையான நடைமுறைகளை இணைப்பதற்கான ஆரம்ப முயற்சிகள்.</li>
        <li>வரையறுக்கப்பட்ட நோக்கம் மற்றும் தாக்கம்.</li>
    </ul>

</li>


<li><span>வளர்ச்சி : </span>
    <ul>
        <li>மனித, இயற்கை மற்றும் பூமியின் ஆரோக்கியத்தின் ஒன்றோடொன்று இணைந்திருப்பது பற்றிய வளர்ந்து வரும் புரிதல்.</li>
        <li>விரிவான நிலைத்தன்மை முயற்சிகளை செயல்படுத்துதல்.</li>
        <li>பல்வேறு களங்களில் முயற்சிகளை விரிவுபடுத்துதல்.</li>
    </ul>

</li>


<li><span>மேம்படுத்தல் : </span>
    <ul>
        <li>நிலையான நடைமுறைகளின் மேம்பட்ட ஒருங்கிணைப்பு.</li>
        <li>தரவு மற்றும் பின்னூட்டத்தின் அடிப்படையில் தொடர்ச்சியான முன்னேற்றம்.</li>
        <li>பங்குதாரர்களுடன் கூட்டணி.</li>
    </ul>

</li>
  
<li><span>மாற்றம் : </span>
    <ul>
        <li>நிலைத்தன்மைக்கான முழுமையான மற்றும் உருமாறும் அணுகுமுறை.</li>
        <li>தொழில் தரங்களை அமைப்பதில் தலைமை.</li>
        <li>வளர்ந்து வரும் சவால்களுக்குத் தொடர்ந்து புதுமை மற்றும் தழுவல்.</li>
    </ul>

</li>
</ol>


<h2>கட்டமைப்பின் கூறுகள்</h2>
           
           <ol>
               <li><span>மனித ஆரோக்கியம் : </span>
               <ul>
                   <li><span>உடல்நலம் மற்றும் நல்வாழ்வு திட்டங்கள் : </span>உடல், மன மற்றும் உணர்ச்சி நல்வாழ்வை ஊக்குவிக்கும் முன்முயற்சிகளை செயல்படுத்தவும்.</li>
                   <li><span>வேலை வாழ்க்கை சமநிலை : </span>வேலை-வாழ்க்கை சமநிலை மற்றும் பணியாளர் திருப்திக்கு முன்னுரிமை அளிக்கும் சூழல்களை வளர்ப்பது.</li>
                   <li><span>சமூக ஈடுபாடு : </span>உள்ளூர் சமூகங்களுடன் ஈடுபடவும் ஆதரவளிக்கவும் பணியாளர்களை ஊக்குவிக்கவும்.
</li>
               </ul>
           
           </li>

           <li><span>இயற்கை ஆரோக்கியம் : </span>
               <ul>
                   <li><span>பல்லுயிர் பாதுகாப்பு : </span>பல்லுயிர் பெருக்கத்தைப் பாதுகாக்கவும் மேம்படுத்தவும் நடைமுறைகளைச் செயல்படுத்தவும்.</li>
                   <li><span>சூழல் நட்பு செயல்பாடுகள் : </span>சுற்றுச்சூழல் பாதிப்பைக் குறைக்க செயல்முறைகளை மேம்படுத்தவும்.</li>
                   <li><span>நிலையான ஆதாரம் : </span>நிலையான மூலப்பொருட்களின் பயன்பாட்டிற்கு முன்னுரிமை கொடுங்கள்.</li>
               </ul>
           
           </li>

           <li><span>பூமி ஆரோக்கியம் : </span>
               <ul>
                   <li><span>கார்பன் நடுநிலை : </span>புதுப்பிக்கத்தக்க ஆற்றல் மற்றும் கார்பன் ஆஃப்செட் முயற்சிகள் மூலம் கார்பன் நடுநிலைமைக்கு பாடுபடுங்கள்.</li>
                   <li><span>கழிவு குறைப்பு : </span>கழிவு குறைப்பு மற்றும் மறுசுழற்சி திட்டங்களை செயல்படுத்துதல்.</li>
                   <li><span>காலநிலை தாங்கும் திறன் : </span>காலநிலை மாற்றத்தின் தாக்கத்தை தகவமைத்து தணிக்க உத்திகளை உருவாக்குங்கள்.</li>
               </ul>
           
           </li>
           </ol>



           <h2>செயல்படுத்தும் படிகள்</h2>
           
           <ol>
               <li><span>மதிப்பீடு : </span>
               <ul>
                   <li>ஆரோக்கியத்தின் ஒவ்வொரு பரிமாணத்திலும் தற்போதைய நடைமுறைகளின் ஆரம்ப மதிப்பீட்டை நடத்தவும்.</li>
                   <li>பலங்கள், பலவீனங்கள், வாய்ப்புகள் மற்றும் அச்சுறுத்தல்களை அடையாளம் காணவும்.</li>
                
               </ul>
           
           </li>
           
           
           <li><span>இலக்கு நிர்ணயம் : </span>
               <ul>
                   <li>மனித, இயற்கை மற்றும் பூமியின் ஆரோக்கியத்தின் ஒவ்வொரு நிலை முதிர்ச்சிக்கும் குறிப்பிட்ட, அளவிடக்கூடிய, அடையக்கூடிய, பொருத்தமான மற்றும் நேர-கட்டுமான (SMART) இலக்குகளை நிறுவுதல்.</li>
               </ul>
           
           </li>
           
           
           <li><span>செயல்படுத்தும் திட்டங்கள் : </span>
               <ul>
                   <li>செயல்கள், பொறுப்புகள் மற்றும் காலக்கெடுவைக் குறிப்பிட்டு, முதிர்ச்சியின் ஒவ்வொரு நிலைக்கும் விரிவான திட்டங்களை உருவாக்குங்கள்.</li>
                   <li>வெவ்வேறு சூழல்களில் அளவிடுதல் மற்றும் பிரதிபலிப்பு ஆகியவற்றைக் கவனியுங்கள்.</li>
               </ul>
           
           </li>
             
           <li><span>கண்காணிப்பு மற்றும் மதிப்பீடு : </span>
               <ul>
                   <li>வலுவான கண்காணிப்பு மற்றும் மதிப்பீட்டு வழிமுறைகளை செயல்படுத்தவும்.</li>
                   <li>தொடர்ந்து முன்னேற்றத்தை மதிப்பிடவும், கருத்துக்களை சேகரிக்கவும், அதற்கேற்ப உத்திகளை சரிசெய்யவும்.
</li>
               </ul>
           
           </li>


           <li><span>பங்குதாரர் ஈடுபாடு : </span>
               <ul>
                   <li>ஊழியர்கள், சமூகங்கள், சப்ளையர்கள் மற்றும் ஒழுங்குமுறை அமைப்புகள் உட்பட அனைத்து நிலைகளிலும் பங்குதாரர்களுடன் ஈடுபடுங்கள்.</li>
                   <li>ஒத்துழைப்பை வளர்ப்பது மற்றும் பொறுப்பை பகிர்ந்து கொள்ளுங்கள்.

</li>
               </ul>
           
           </li>


           <li><span>கல்வி மற்றும் தொடர்பு : </span>
               <ul>
                   <li>விழிப்புணர்வை ஏற்படுத்துவதற்கும், நிலைத்தன்மையின் கலாச்சாரத்தை வளர்ப்பதற்கும் தொடர்ந்து கல்வி மற்றும் தகவல்தொடர்புகளை வழங்குதல்.</li>
                   <li>சாதனைகளைக் கொண்டாடுங்கள் மற்றும் சிறந்த நடைமுறைகளைப் பகிர்ந்து கொள்ளுங்கள்.

</li>
               </ul>
           
           </li>

           <li><span>தொடர்ச்சியான முன்னேற்றம் : </span>
               <ul>
                   <li>தொடர்ச்சியான முன்னேற்றத்தின் கலாச்சாரத்தைத் தழுவுங்கள்.</li>
                   <li>வளர்ந்து வரும் தரநிலைகள் மற்றும் சவால்களுடன் சீரமைக்க, நிலைத்தன்மை கட்டமைப்பை தொடர்ந்து மறுபரிசீலனை செய்து புதுப்பிக்கவும்.
</li>
               </ul>
           
           </li>

           </ol>
           

<p>உங்கள் நிறுவனம் அல்லது முன்முயற்சியின் குறிப்பிட்ட சூழலின் அடிப்படையில் கட்டமைப்பைத் தனிப்பயனாக்க நினைவில் கொள்ளுங்கள், மேலும் நிலைத்தன்மை மற்றும் தொடர்புடைய துறைகளில் நிபுணர்களிடமிருந்து உள்ளீட்டைப் பெறவும்.</p>
           </div>
        </div>









               
        
       

    </div>
);
}