import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability">English</NavLink></a>



</div>
            
            <div className="social-ma">

<h1>ఎందుకు సస్టైనబిలిటీ కారణం?</h1>
</div>


<div className="internship">
        <img src="../img/360.jpg"></img>
        </div>
<div className="goalpointsp">

<p>ఝా ఫౌండేషన్ వంటి సుస్థిరత అభివృద్ధి క్లబ్‌ను అన్ని దేశాలకు విస్తరించడం ద్వారా విభిన్న పర్యావరణ మరియు సామాజిక సవాళ్లను పరిష్కరించడం ద్వారా ప్రపంచ ప్రభావం చూపుతుంది. ఇది వనరులు, విజ్ఞానం మరియు ఉత్తమ అభ్యాసాల భాగస్వామ్యాన్ని అనుమతిస్తుంది, విస్తృత స్థాయిలో స్థిరమైన అభివృద్ధి వైపు సహకార విధానాన్ని ప్రోత్సహిస్తుంది. అదనంగా, గ్లోబల్ సమస్యల యొక్క ఒకదానితో ఒకటి అనుసంధానించబడిన స్వభావం మరింత స్థిరమైన మరియు స్థితిస్థాపకమైన ప్రపంచాన్ని సృష్టించడానికి విస్తృత భాగస్వామ్యం యొక్క అవసరాన్ని నొక్కి చెబుతుంది.దిగువ పేర్కొన్న విధంగా నిలకడలేని మానవ జీవితానికి కారణాలు. మానవుల కోసం నిలకడలేని జీవనశైలి అనేక పరస్పర అనుసంధాన కారకాలకు కారణమని చెప్పవచ్చు:
</p>


<ol className="goalpoints">
    <li><span>మితిమీరిన మొబైల్ ఫోన్ వాడకం : </span>పిల్లలు మరియు తల్లిదండ్రులు మొబైల్ ఫోన్‌లలో గణనీయమైన సమయాన్ని వెచ్చిస్తున్నారు, ఇది శారీరక శ్రమ, పఠన అలవాట్లు మరియు సామాజిక పరస్పర చర్యలలో క్షీణతకు దారితీసింది.</li>
    <li><span>వీడియో గేమ్ మితిమీరిన వినియోగం : </span>పిల్లలు, ముఖ్యంగా, వీడియో గేమ్‌లకు ఎక్కువ గంటలు కేటాయిస్తున్నారు, తరచుగా క్రీడలలో పాల్గొనడం మరియు పఠనం పట్ల ప్రేమను పెంపొందించడం వంటి వాటితో.</li>
    <li><span>సోషల్ ట్రస్ట్ యొక్క క్షీణత : </span>డిజిటల్ పరధ్యానం కారణంగా వ్యక్తుల మధ్య ముఖాముఖి పరస్పర చర్యలు తగ్గడం విశ్వాసం మరియు వ్యక్తుల మధ్య సంబంధాల క్షీణతకు దోహదపడుతుంది, మా సామాజిక కనెక్షన్ల నాణ్యత గురించి ఆందోళనలను పెంచుతుంది.</li>


    <li><span>అధిక వినియోగం మరియు భౌతికవాదం : </span>సౌకర్యవంతమైన మరియు సంతృప్తికరమైన జీవితానికి అవసరమైన దానికంటే ఎక్కువ భౌతిక ఆస్తులు మరియు వనరుల వినియోగం స్థిరమైన జీవనశైలికి దారి తీస్తుంది.

</li>
    <li><span>వనరుల క్షీణత : </span>శిలాజ ఇంధనాలు, ఖనిజాలు మరియు మంచినీటితో సహా సహజ వనరులను అతిగా దోచుకోవడం వాటి క్షీణతకు దారి తీస్తుంది మరియు పర్యావరణ క్షీణతకు దోహదం చేస్తుంది.


</li>
    <li><span>వ్యర్థాల ఉత్పత్తి : </span>అసమర్థ వనరుల వినియోగం మరియు అక్రమ వ్యర్థాలను పారవేయడం కాలుష్యం, పల్లపు పేరుకుపోవడం మరియు పర్యావరణంలోకి హానికరమైన పదార్థాల విడుదలకు దోహదం చేస్తుంది.


</li>
    <li><span>అధిక శక్తి వినియోగం : </span>అధిక విద్యుత్ వినియోగం, వేడి చేయడం మరియు రవాణా వంటి శక్తి-ఇంటెన్సివ్ పద్ధతులపై ఆధారపడటం వలన గ్రీన్‌హౌస్ వాయు ఉద్గారాలు మరియు పర్యావరణ ఒత్తిడి పెరగవచ్చు.


</li>
    <li><span>రవాణా మరియు ప్రయాణ అలవాట్లు : </span>రవాణా కోసం వ్యక్తిగత వాహనాలపై ఆధారపడటం, ప్రత్యేకించి సమర్థవంతమైన ప్రజా రవాణా లేని ప్రాంతాలలో, వాయు కాలుష్యం, రద్దీ మరియు శిలాజ ఇంధన వినియోగానికి దోహదం చేస్తుంది.
</li>
    <li><span>ఆహారోత్పత్తి మరియు వినియోగం : </span>మోనోకల్చర్ ఫార్మింగ్, మితిమీరిన రసాయనిక ఇన్‌పుట్‌ల వినియోగం మరియు ఆహారాన్ని సుదూర రవాణా చేయడంతో సహా నిలకడలేని వ్యవసాయ పద్ధతులు పర్యావరణ క్షీణత మరియు ఆహార భద్రత సవాళ్లకు దారితీయవచ్చు.
</li>
    <li><span>అటవీ నిర్మూలన మరియు నివాస విధ్వంసం : </span>వ్యవసాయం, పట్టణీకరణ మరియు మౌలిక సదుపాయాల అభివృద్ధి కోసం భూమిని క్లియర్ చేయడం వలన జీవవైవిధ్యం కోల్పోవడం, పర్యావరణ వ్యవస్థల అంతరాయం మరియు కార్బన్ సీక్వెస్ట్రేషన్ తగ్గుతుంది.

</li>
<li><span>జీవిత విజయాలను జరుపుకోవడం : </span>ఇతరులు జీవించి ఉన్నప్పుడు వారు సాధించిన విజయాలను జరుపుకోవడానికి మేము సమయాన్ని వెచ్చించము.
</li>
<li><span>సానుభూతిని నిర్లక్ష్యం చేయడం : </span>ఇతరుల నష్టం మొదలైన వాటి కోసం మా సంతాపాన్ని పంచుకోవడానికి మేము సమయం కేటాయించము</li>
    <li><span>మితిమీరిన చేపలు పట్టడం మరియు సముద్ర క్షీణత : </span>అధిక చేపలు పట్టడం మరియు విధ్వంసక ఫిషింగ్ పద్ధతులతో సహా నిలకడలేని ఫిషింగ్ పద్ధతులు చేపల నిల్వలను క్షీణింపజేస్తాయి మరియు సముద్ర పర్యావరణ వ్యవస్థలకు హాని కలిగిస్తాయి.
</li> 

<li><span>స్థిరమైన అవస్థాపన లేకపోవడం : </span>సరిపోని పట్టణ ప్రణాళిక, అసమర్థమైన భవనాల డిజైన్‌లు మరియు కాలం చెల్లిన మౌలిక సదుపాయాలు వనరుల అసమర్థత మరియు పర్యావరణ ఒత్తిడికి దోహదం చేస్తాయి.

</li> 

<li><span>వినియోగదారు ప్రవర్తన మరియు ఎంపికలు : </span>స్థిరమైన ఉత్పత్తులను కొనుగోలు చేయడం వంటి వినియోగదారులు చేసే ఎంపికలు వనరులు ఎక్కువగా ఉండే మరియు పర్యావరణానికి హాని కలిగించే వస్తువులు మరియు సేవలకు డిమాండ్‌ను పెంచుతాయి.

</li> 

<li><span>పర్యావరణ అవగాహన మరియు విద్య లేకపోవడం : </span>కొన్ని ప్రవర్తనల పర్యావరణ ప్రభావాలపై తగినంత జ్ఞానం మరియు అవగాహన లేకపోవడం అనాలోచిత నిలకడలేని అభ్యాసాలకు దారి తీస్తుంది.
</li> 

<li><span>పాలసీ మరియు రెగ్యులేటరీ ఫ్రేమ్‌వర్క్‌లు : </span>బలహీనమైన లేదా సరిపోని పర్యావరణ విధానాలు, అమలులో లేకపోవడం మరియు నిలకడలేని పద్ధతులను ప్రోత్సహించే సబ్సిడీలు నిలకడలేని జీవనశైలికి దోహదం చేస్తాయి.
</li> 
</ol>



<p>నిలకడలేని జీవనశైలిని పరిష్కరించడానికి విద్య, విధాన మార్పులు, సాంకేతిక ఆవిష్కరణలు మరియు సాంస్కృతిక మరియు సామాజిక నిబంధనలలో మార్పులతో సహా బహుముఖ విధానం అవసరం. స్థిరమైన పద్ధతులను ప్రోత్సహించడం, బాధ్యతాయుతమైన వినియోగాన్ని ప్రోత్సహించడం మరియు పర్యావరణ అనుకూల సాంకేతికతలను అవలంబించడం వంటివి మరింత స్థిరమైన భవిష్యత్తు దిశగా ముఖ్యమైన దశలు.</p>



</div>
        
     

        </div>

    );
}

