import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


// import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Common-Humans-COPs">English</NavLink></a>

        </div>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Zha Common Man COP’s </h1>
</div>


<div className="internship">
        <img src="../img/commonm.avif"></img>
        </div>
<div className="goalpointsp">

<p>Zha Common Man Community of Practices (COPs) உலகளவில் தனிநபர்களிடையே ஒரு நிலையான மனநிலையை வளர்ப்பதை நோக்கமாகக் கொண்டுள்ளது. Zha அறக்கட்டளையின் உலகளாவிய குழு உறுப்பினர்களால் வழிகாட்டப்பட்ட, COP கள் மாதாந்திர மன்றங்களில் கல்வி கற்பதற்கும், விவாதிப்பதற்கும், நிலையான நடைமுறைகளை நோக்கிய நடவடிக்கைகளுக்கு ஊக்கமளிப்பதற்கும் கவனம் செலுத்துகின்றன. விழிப்புணர்வை ஏற்படுத்துதல், சிறந்த நடைமுறைகளைப் பகிர்ந்துகொள்வது மற்றும் நிலைத்தன்மைக்கு அர்ப்பணிக்கப்பட்ட உலகளாவிய வலையமைப்பை வளர்ப்பது ஆகியவை இலக்குகளில் அடங்கும். நன்மைகளில் அறிவுப் பரிமாற்றம், கூட்டு முயற்சிகள், மேலும் நிலையான உலகிற்கு பங்களிக்க தனிநபர்களை மேம்படுத்துதல் ஆகியவை அடங்கும்.
</p>
<p>
எனவே ஒவ்வொரு நாட்டிற்கும் எங்களின் பல்வேறு COP கள் உள்ளன, அவை அமர்வுகள் மற்றும் தீர்வு முடிவுகளை வழங்குவதில் கவனம் செலுத்த வேண்டும், பின்வரும் நிகழ்ச்சி நிரல்களின் அடிப்படையில் புதுமையான முயற்சிகளை உருவாக்குகின்றன.
</p>

<ol className="goalpoints">
    <li><span>கல்வி மற்றும் விழிப்புணர்வு :</span>Zha இன் COP கள், காலநிலை மாற்றம் மற்றும் நிலைத்தன்மை பற்றிய அறிவைப் பெறுவதற்கு பொதுவான மக்களுக்கு ஒரு தளத்தை வழங்குகின்றன, அவற்றின் முக்கியத்துவத்தைப் பற்றிய ஆழமான புரிதலை வளர்க்கின்றன.</li>
    <li><span>உள்ளூர் தாக்கம் : </span> பொதுவான நபர்களை ஈடுபடுத்துவதன் மூலம், COP கள் உள்ளூர் சமூகங்களுக்கு நடவடிக்கை எடுக்க அதிகாரம் அளிக்கின்றன, உலகளாவிய நிலைத்தன்மை முயற்சிகளுக்கு கூட்டாக பங்களிக்கின்றன.
</li>
    <li><span>நடத்தை மாற்றம் :</span>காலநிலை மாற்றத்தின் முக்கியத்துவத்தை ஆரம்பத்தில் புரிந்து கொள்ளாத பங்கேற்பாளர்களிடையே நிலையான தேர்வுகளை ஊக்குவிப்பதன் மூலம், தினசரி பழக்கவழக்கங்கள் மற்றும் நடைமுறைகளில் செல்வாக்கு செலுத்துவதை COP கள் நோக்கமாகக் கொண்டுள்ளன.</li>


    <li><span>சமூக அதிகாரமளித்தல் :</span>வழிகாட்டுதல் மற்றும் ஒத்துழைப்பின் மூலம், COP கள் தனிநபர்கள் தங்கள் சமூகங்களுக்குள் நிலைத்தன்மைக்கான வக்கீல்களாக மாறுவதற்கு அதிகாரம் அளிக்கிறது, இது நேர்மறையான மாற்றத்தின் சிற்றலை விளைவை உருவாக்குகிறது.

</li>
    <li><span>குளோபல் நெட்வொர்க் : </span>COPகள் பல்வேறு பின்னணியில் உள்ள மக்களை இணைக்கின்றன, உலகளாவிய பொறுப்புணர்வு உணர்வை வளர்க்கின்றன மற்றும் தனிப்பட்ட செயல்கள் கூட்டாக கிரகத்தை எவ்வாறு பாதிக்கின்றன என்பதை விளக்குகிறது.

</li>
    <li><span>ஐநா இலக்குகளுடன் சீரமைத்தல் : </span>ஜாவின் COPகள் ஐக்கிய நாடுகளின் நிலைத்தன்மை இலக்குகளுடன் நேரடியாக ஒத்துப்போகின்றன, பரந்த உலகளாவிய நோக்கங்களை அடைவதற்கான அடிமட்ட அணுகுமுறையை வழங்குகின்றன.


</li>
    <li><span>உள்ளடக்கிய பங்கேற்பு :</span>பொது மக்களைக் குறிவைப்பதன் மூலம், COP கள் உள்ளடக்கத்தை உறுதி செய்கின்றன, பரந்த மக்கள்தொகையில் ஈடுபடுகின்றன மற்றும் நிலைத்தன்மையின் மொழி அல்லது கருத்துகளை அறிந்திருக்காதவர்களை விலக்குவதைத் தவிர்க்கின்றன.


</li>
    <li><span>மாதாந்திர கருத்துக்களம் :</span>வழக்கமான மன்றங்கள் தொடர்ந்து உரையாடல் மற்றும் கற்றலுக்கான ஒரு நிலையான தளத்தை வழங்குகின்றன, காலப்போக்கில் நிலைத்தன்மையின் முக்கியத்துவத்தை வலுப்படுத்துகின்றன மற்றும் பங்கேற்பாளர்களின் தற்போதைய நனவின் ஒரு பகுதியாக மாற்றுகின்றன.
</li>
    <li><span>நடைமுறை தீர்வுகள் :</span>COP கள் நடைமுறை, அடையக்கூடிய தீர்வுகளில் கவனம் செலுத்துகின்றன, இது சாதாரண மக்கள் தங்கள் அன்றாட வாழ்வில் செயல்படுத்த முடியும், இது நிலைத்தன்மையை மேலும் அணுகக்கூடியதாகவும் குறைவான சுருக்கமாகவும் ஆக்குகிறது.
</li>
    <li><span>தழுவல் மற்றும் மீள்தன்மை : </span>COP களின் மூலம் சாதாரண மக்களுக்கு கல்வி கற்பது சமூகங்கள் மாறிவரும் சுற்றுச்சூழல் நிலைமைகளுக்கு ஏற்றவாறு மாற்றியமைக்க உதவுகிறது மற்றும் காலநிலை மாற்றத்தின் தாக்கங்களுக்கு எதிராக பின்னடைவை வளர்க்க உதவுகிறது.

</li>
 
 </ol>
 
 <p>சாராம்சத்தில், காலநிலை மாற்றம் மற்றும் நிலைத்தன்மை இலக்குகள் மற்றும் அன்றாட தனிநபர்களின் நடைமுறை நடவடிக்கைகள் ஆகியவற்றின் சுருக்கமான கருத்துக்களுக்கு இடையிலான இடைவெளியைக் குறைப்பதில் ஜாவின் காமன் மேன் சிஓபிகள் முக்கிய பங்கு வகிக்கின்றன, மேலும் நிலையான மற்றும் தகவலறிந்த உலகளாவிய சமூகத்தை உருவாக்குகின்றன.</p>
 </div>

        </div>

    );
}

