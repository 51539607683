import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                    ನಮ್ಮ ಫೌಂಡೇಶನ್ ಲಾಭರಹಿತ ಸಂಸ್ಥೆಯಾಗಿದ್ದು, ಕೃಷಿ ವ್ಯವಹಾರದಲ್ಲಿ ಉಚಿತವಾಗಿ ಡೈನಾಮಿಕ್ ಕನ್ಸಲ್ಟಿಂಗ್ ಮೂಲಕ ಭಾರತೀಯ ರೈತರಿಗೆ ಸಹಾಯ ಮಾಡಲು, ರೈತರಿಗೆ ಮೌಲ್ಯವರ್ಧಿತ ಪ್ರಯೋಜನಗಳನ್ನು ನೀಡಲು ಮತ್ತು ವಿನಂತಿಗಳ ಆಧಾರದ ಮೇಲೆ ಅವರ ಹೊಲಗಳನ್ನು ನಿರ್ವಹಿಸಲು ಪ್ರಾರಂಭಿಸಲಾಗಿದೆ. ಆದ್ದರಿಂದ ನಾವು ನಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಮೌಲ್ಯವನ್ನು ತಲುಪಿಸುವ ಉತ್ಪನ್ನವನ್ನು ಆವಿಷ್ಕರಿಸಬೇಕು ಎಂದು ನಾವು ನೋಡಿದ್ದೇವೆ.
                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/abou2.jpg" />
     <p>
     ನಾವು ಸುಸ್ಥಿರ ಮತ್ತು ಅಂತರ್ಗತ ಬೆಳವಣಿಗೆಯನ್ನು ವೇಗಗೊಳಿಸುತ್ತೇವೆ ಮತ್ತು ದಕ್ಷತೆಯನ್ನು ಚಾಲನೆ ಮಾಡುವ ಮೂಲಕ, ಬೆಳವಣಿಗೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಮೂಲಕ, ಕೈಗಾರಿಕೆಗಳನ್ನು ರೂಪಿಸುವ ಮತ್ತು ಸಾಂಸ್ಥಿಕ ಪರಿಣಾಮಕಾರಿತ್ವವನ್ನು ಹೆಚ್ಚಿಸುವ ಮೂಲಕ ಆಹಾರ ಮತ್ತು ಕೃಷಿ ಕಂಪನಿಗಳು ಮತ್ತು ಸಂಸ್ಥೆಗಳಿಗೆ ಪರಿವರ್ತನೆಯ ಪರಿಣಾಮವನ್ನು ನೀಡುತ್ತೇವೆ. ನಮ್ಮ ಸ್ವಾಮ್ಯದ ಪರಿಕರಗಳು ಮತ್ತು ಡಿಜಿಟಲ್ ಮತ್ತು ಸುಧಾರಿತ ವಿಶ್ಲೇಷಣಾ ಸಾಮರ್ಥ್ಯಗಳು, ವ್ಯಾಪಕವಾದ ಅನುಭವದೊಂದಿಗೆ ಸೇರಿ ನಾವು ನಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ನಾವು ತರುವ ಮೌಲ್ಯದ ಮೂಲಾಧಾರಗಳಾಗಿವೆ.
     </p>
 </div>
 <div className="about-card">
     <img src="../../img/abou3.jpg" />
     <p>
     ಝಾ ಫೌಂಡೇಶನ್ ಬಹು-ಶಿಸ್ತಿನ ಸಂಶೋಧನೆ, ಸಾಮರ್ಥ್ಯ ವರ್ಧನೆ, ಸ್ವಯಂ ಸುಧಾರಣೆಗಳಲ್ಲಿ ತೊಡಗಿಸಿಕೊಂಡಿದೆ ಮತ್ತು ಯುವ ಮನಸ್ಸು ಮತ್ತು ಹೃದಯದ ಆರೋಗ್ಯಕ್ಕೆ ಸಹಾಯ ಮಾಡುವ ಅಭಿಯಾನಗಳನ್ನು ಕೈಗೊಳ್ಳುತ್ತದೆ. ಇಡೀ ಪೀಳಿಗೆಯು ಹೆಚ್ಚು ಆತಂಕಕ್ಕೊಳಗಾಗುತ್ತದೆ, ಹೆಚ್ಚು ದುರ್ಬಲವಾಗಿರುತ್ತದೆ, ಹೆಚ್ಚು ಖಿನ್ನತೆಗೆ ಒಳಗಾಗುತ್ತದೆ. ಅವರು ಅಪಾಯಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುವಲ್ಲಿ ಕಡಿಮೆ ಆರಾಮದಾಯಕರಾಗಿದ್ದಾರೆ. ಇದು ಒಂದು ಪೀಳಿಗೆಯಲ್ಲಿ ನಿಜವಾದ ಬದಲಾವಣೆಯಾಗಿದೆ. ಆದ್ದರಿಂದ, ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವ, ಪ್ರೀತಿ, ಭರವಸೆ, ಸಾಂಸ್ಕೃತಿಕ ಗುರುತು, ವಿಮರ್ಶಾತ್ಮಕ ಪ್ರಜ್ಞೆ, ಸ್ವ-ನಿರ್ಣಯ ಮತ್ತು ಕೃಷಿ ಸುಧಾರಣೆಗಳ ಕೆಲಸದ ವಿಧಾನಗಳನ್ನು ಒಳಗೊಂಡಿರುವ ಕ್ಷೇಮವನ್ನು ಅಳೆಯುವ ಮಾರ್ಗಗಳನ್ನು ನಾವು ಅಭಿವೃದ್ಧಿಪಡಿಸುತ್ತಿದ್ದೇವೆ.
     </p>
 </div>

            </div>
            

            <div className="heightt">
                <h2 className="structuretit">Zha Transformation Method</h2>
                <img src="../img/height.png"></img>
            </div>
        </div>

    );
}