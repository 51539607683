import React from "react";
import "./Footer.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
// import FLOGO from ../../img/flogo.pn;
export default function Footer(){
    return(
      <div className="footfull">
         

         <div className="footer2">
<div className="footercol">

<div className="footercon">
  <h3>Follow Up</h3>

  <div className="firstcol">
  <div>
      <a href="https://zhafoundation.in/">
      <img className="zhafootlogo" src="../../img/footerlogo.webp" />
      </a>
      </div>
      

<div>
       
      <a href="https://www.facebook.com/ZHAFoundation1/">
    <i className="fa-brands fa-facebook-f" />
    </a>

    <a href="https://instagram.com/zha_foundation?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D">
        <i className="fa-brands fa-instagram" />
        </a>
      
        <a href="https://www.youtube.com/@zhafoundation">
      <i className="fa-brands fa-youtube" />
          </a>

          <a href="https://www.linkedin.com/company/zha-foundation-charitable-trust/">
      <i className="fa-brands fa-linkedin" />
          </a>
          </div>

          
      </div>
      </div>

      <div className="footercon">
          <h3>Site Map</h3>
          <ul className="footersite">
           {/* <a href="/"><li>Zha Vision</li></a> 
           <a href="about"><li>How Zha Function</li></a> 
           <a href="/Zha-Sports-Training-Club"><li>Zha Sports Training Club</li></a> 
           <a href="/Zha-Ecofriendly-Certification"><li>Zha Eco-frindly Certification</li></a> 
           <a href="/Zha-Sustainability-Club-Membership"><li>Zha Board Membership</li></a> 
           <a href="/ResearchandDevelopement"><li>Zha Sustainability R&D</li></a> 
           <a href="/Socialservices"><li>Zha Social Services</li></a> 
           <a href="/Contact"><li>Contact Us</li></a>  */}
           <Nav.Link><a href=""><NavLink to="#home">Zha Vision</NavLink></a></Nav.Link>
           <Nav.Link><a href=""><NavLink to="/about">How Zha Function</NavLink></a></Nav.Link>
           <Nav.Link><a href=""><NavLink to="/Zha-Sports-Training-Club">Zha Sports Training Club</NavLink></a></Nav.Link>
           <Nav.Link><a href=""><NavLink to="/Zha-Ecofriendly-Certification">Zha Eco-frindly Certification</NavLink></a></Nav.Link>
           <Nav.Link><a href=""><NavLink to="/Zha-Sustainability-Club-Membership">Zha Board Membership</NavLink></a></Nav.Link>
           <Nav.Link><a href=""><NavLink to="/ResearchandDevelopement">Zha Sustainability R&D</NavLink></a></Nav.Link>
           <Nav.Link><a href=""><NavLink to="/Socialservices">Zha Social Services</NavLink></a></Nav.Link>
           <Nav.Link><a href=""><NavLink to="/Contact">Contact Us</NavLink></a></Nav.Link>
          </ul> 
         </div>

         <div className="footercon">
  <h3>Reach Us</h3>
  <div className="towhithe">
  
  <div className="rachlist">
  <a href="https://www.facebook.com/karkiashokkumarauthor?mibextid=LQQJ4d">
  <i className="fa-brands fa-facebook-f" /> 
    <h6>  Founders’ Author Thoughts  </h6> 
    </a>
  </div>

  <div className="rachlist">
        <i class="fa-solid fa-phone"></i>
      <h6>+91 7305359417 </h6> 
      </div>

      <div className="rachlist">
         
        <i class="fa-solid fa-envelope"></i>
        
      <h6>admin@zhafoundation.in</h6> 
      </div>

      <div className="rachlist">
       
        <i class="fa-solid fa-house"></i>
      <h6>No.77D,Sethu Road,KK Nagar,Peravurani,
Thanjavur,Tamilnadu, India-614804. </h6> 
      </div>
      </div>
     
</div>


</div>


    </div>
    <div className="footercopyright"   >
  <div className="footerlinee"></div>
  <div className="footercopyrightcontent">
  <a href="www.zhafoundation.in">Copyright@ZhaFoundationCharitableTrust</a>
  </div>
</div> 

</div>
    );
}