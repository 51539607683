import React from "react";
import "./Socialservices.css"
import { NavLink } from "react-router-dom";
export default function Socialservices(){

    return(

        <div>
            <div className="social-main">
            <div  id="language" >
        
        <a href=""><NavLink to="/Socialservices">English</NavLink></a>

        </div>
            <h1>Social Service</h1>
            </div>
            <div className="social-content">

        <div className="social-box">
        <img src="../../img/sust.png"></img>
        <h2>वहनीयता
</h2>
        <p>
        झा फाउंडेशन भविष्य की पीढ़ियों के लिए ग्रह की रक्षा के लिए स्थिरता को प्राथमिकता देता है। वे ऊर्जा, कृषि, परिवहन और निर्माण में स्थायी समाधान लागू करते हैं। स्थिरता जिम्मेदार संसाधन उपयोग, नवीकरणीय ऊर्जा, अपशिष्ट में कमी और प्रकृति के साथ सद्भाव को बढ़ावा देती है। फाउंडेशन का लक्ष्य एक लचीली, पर्यावरण-अनुकूल दुनिया बनाना है जहां लोग और पर्यावरण संतुलन में रहें। साथ ही, ग्रामीण और उपनगरीय क्षेत्रों में रोजगार पैदा करना। गैर-लालची जीवन के लिए एक स्वस्थ समुदाय बनाना।


           

        </p>
        </div>
       
        <div className="social-box">
        <img src="../../img/property.png"></img>
        <h2>स्वास्थ्य शिविर</h2>
        <p>
        झा फाउंडेशन वंचितों की प्राथमिक स्वास्थ्य देखभाल तक पहुंच की चुनौतियों को पहचानता है। इसलिए हम सभी भौगोलिक क्षेत्रों में निःशुल्क मल्टीस्पेशलिटी स्वास्थ्य शिविरों का आयोजन करते हैं, जिसमें डॉक्टर द्वारा गहन जांच, बीपी, ब्लड शुगर और हीमोग्लोबिन (एचबी) की निगरानी जैसे विशेष परीक्षणों के साथ-साथ मुफ्त दवाओं का वितरण भी शामिल है। ये समय पर किए गए हस्तक्षेप रुग्णता और मृत्यु दर की घटनाओं को कम करने के लिए शीघ्र निदान और उपचार प्रदान करते हैं। ऐसे स्वास्थ्य शिविर हाशिए पर रहने वाले समुदायों की तत्काल स्वास्थ्य देखभाल आवश्यकताओं को पूरा करते हैं


            </p>
        </div>
        <div className="social-box">
        <img src="../../img/run.png"></img>
        <h2>युवा कल्याण के लिए खेल की सुविधा प्रदान करें
 </h2>

        <p>
        खेल हर किसी को, खासकर बच्चों और युवाओं को फिट रहने, कौशल विकसित करने, दोस्त बनाने और भावनात्मक उतार-चढ़ाव को प्रबंधित करने में मदद करता है। एक अच्छा रोल मॉडल बनकर, सकारात्मक दृष्टिकोण को प्रोत्साहित करके और रुचि दिखाकर अपने बच्चे को एक अच्छा खेल बनने में मदद करें। जीत और हार के बजाय प्रयास, भागीदारी और मौज-मस्ती पर ध्यान दें। यदि बच्चे खेल नहीं खेलना चाहते, तो उनके पास शारीरिक गतिविधि के लिए कई अन्य विकल्प हैं। झा उपनगरीय और ग्रामीण सरकारी स्कूल के बच्चों को खेल प्रतियोगिता आदि के लिए एक मंच प्रदान करने में मदद करने के लिए विभिन्न प्रतियोगिताओं का आयोजन करेंगे


            </p>
        </div>
            </div>
            <div className="goals">
                <h1 >हमारास्थिरता लक्ष्य
 <sapn className="goalsspan"></sapn></h1>
                <div className="susgoal">
                 <div>
                    <img src="../../img/Midnight.png"></img>
                    </div>   
                    <p>
                <ul>
        <li> <span>कोई गरीबी नहीं:</span> गरीबी को उसके सभी रूपों में समाप्त करें और गरीबों और कमजोर लोगों के लिए सामाजिक सुरक्षा सुनिश्चित करें।</li>
        <li><span>शून्य भूख:</span>  खाद्य सुरक्षा हासिल करना, पोषण में सुधार करना और टिकाऊ कृषि को बढ़ावा देना।</li>
        <li><span>अच्छा स्वास्थ्य और कल्याण: </span> स्वस्थ जीवन सुनिश्चित करें और सभी उम्र के लोगों के लिए कल्याण को बढ़ावा दें।</li>
        <li> <span>गुणवत्तापूर्ण शिक्षा:</span>  समावेशी और न्यायसंगत गुणवत्तापूर्ण शिक्षा सुनिश्चित करना और सभी के लिए आजीवन सीखने के अवसरों को बढ़ावा देना।</li>
        <li> <span>लैंगिक समानता: </span> लैंगिक समानता हासिल करना और सभी महिलाओं और लड़कियों को सशक्त बनाना।


</li>
            </ul>
                </p>

                </div>
            </div>
        </div>
    
    );
}