import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
<div  id="language" >
        <a href=""><NavLink to="/Zha-Global-Community-of-Practice">English</NavLink></a>
        </div>
 

            <div className="social-ma">

<h1>Zha உலகளாவிய நடைமுறை சமூகம்</h1>
</div>


<div className="internship">
        <img src="../img/practice.webp"></img>
        </div>
<div className="goalpointsp">

<p>
உலகளாவிய நிலைத்தன்மை நடைமுறைச் சமூகத்தை (CoP) உருவாக்குவது, அறிவைப் பகிர்ந்துகொள்வதற்கும், ஒத்துழைப்பதற்கும், நிலைத்தன்மை நடைமுறைகளை மேம்படுத்துவதற்கும் நிபுணர்கள் மற்றும் பயிற்சியாளர்களை ஒன்றிணைப்பதை உள்ளடக்குகிறது. உங்கள் சிஓபிக்கான பரிந்துரைக்கப்பட்ட அவுட்லைன் இதோ:

</p>


<ol className="goalpoints">
    <li><span>நோக்கங்கள் : </span> </li>
    <ul>
        <li>நோக்கத்தை வரையறுக்கவும்: கருத்து பரிமாற்றம், சிறந்த நடைமுறைகள் மற்றும் நிலைத்தன்மையில் புதுமைகளை எளிதாக்குதல்.
 </li>
        <li>உலகளாவிய நிலைத்தன்மை சவால்களில் ஒத்துழைப்பை ஊக்குவிக்கவும்.
 </li>
 <li>கற்றல் மற்றும் வளர்ச்சிக்கு ஆதரவான சமூகத்தை வளர்க்கவும்.
</li>
    </ul>

    <li><span>உறுப்பினர் : </span> </li>
    <ul>
        <li>நிலைத்தன்மையில் ஆர்வமுள்ள தொழில் வல்லுநர்கள், நிபுணர்கள் மற்றும் நிறுவனங்களை அழைக்கவும்.
 </li>
        <li>தொழில்கள், புவியியல் மற்றும் நிபுணத்துவப் பகுதிகளில் பல்வேறு பிரதிநிதித்துவம்.
 </li>
    </ul>

    <li><span>தொடர்பு : </span> </li>
    <ul>
        <li>விவாதங்கள், வளப் பகிர்வு மற்றும் ஒத்துழைப்பிற்காக ஒரு பிரத்யேக ஆன்லைன் தளத்தை நிறுவவும்.
 </li>
        <li>நிகழ்நேர தொடர்புகளை எளிதாக்குவதற்கு வழக்கமான மெய்நிகர் சந்திப்புகள், வெபினார்கள் அல்லது பட்டறைகள்.
 </li>
    </ul>


    <li><span>உள்ளடக்கம் மற்றும் அறிவுப் பகிர்வு: </span> </li>
    <ul>
        <li>வழக்கு ஆய்வுகள், வெற்றிக் கதைகள் மற்றும் கற்றுக்கொண்ட பாடங்களைப் பகிரவும்.
 </li>
        <li>ஆராய்ச்சி திட்டங்கள், ஒயிட் பேப்பர்கள் அல்லது வெளியீடுகளில் ஒத்துழைக்கவும்.
 </li>
    </ul>

    <li><span>பணிக்குழுக்கள்:  </span> </li>
    <ul>
        <li>குறிப்பிட்ட நிலைத்தன்மைத் தூண்கள் அல்லது தொழில்களுக்கு கவனம் செலுத்தும் துணைக்குழுக்களை உருவாக்கவும்.
 </li>
        <li>திட்டங்கள் அல்லது முன்முயற்சிகளில் தீவிரமாக பங்களிக்க உறுப்பினர்களை இயக்கவும்.
 </li>
    </ul>

    <li><span>நிகழ்வுகள் :</span> </li>
    <ul>
        <li>புதுமைகளை வெளிப்படுத்தவும் சாதனைகளைக் கொண்டாடவும் வருடாந்திர மாநாடுகள் அல்லது நிகழ்வுகளை ஏற்பாடு செய்யுங்கள்.
 </li>
        <li>தொடர்புடைய நிலைத்தன்மை தலைப்புகளில் முக்கிய பேச்சாளர்கள் மற்றும் குழு விவாதங்கள் இடம்பெறும்.
 </li>
    </ul>

    <li><span>கூட்டாண்மைகள் : </span> </li>
    <ul>
        <li>தாக்கத்தை அதிகரிக்க கல்வி நிறுவனங்கள், அரசு சாரா நிறுவனங்கள் மற்றும் வணிகங்களுடன் ஒத்துழைக்கவும்.
 </li>
        <li>கூட்டு முயற்சிகள் அல்லது ஆராய்ச்சி திட்டங்களுக்கு கூட்டாண்மைகளை நிறுவுதல்.
 </li>
    </ul>

    <li><span>அளவீடுகள் மற்றும் அறிக்கையிடல் : </span> </li>
    <ul>
        <li>நிலைத்தன்மையை மேம்படுத்துவதில் CoP இன் தாக்கத்தை அளவிட அளவீடுகளை உருவாக்கவும்.
 </li>
        <li>உறுப்பினர்கள் மற்றும் பங்குதாரர்களுக்கு முன்னேற்றம் மற்றும் சாதனைகளை தொடர்ந்து தெரிவிக்கவும்.
 </li>
    </ul>

    <li><span>பயிற்சி மற்றும் மேம்பாடு : </span> </li>
    <ul>
        <li>வளர்ந்து வரும் நிலைத்தன்மை போக்குகள் மற்றும் கருவிகள் குறித்த பயிற்சி அமர்வுகள் அல்லது பட்டறைகளை வழங்குங்கள்.
 </li>
        <li>உறுப்பினர்களிடையே அறிவு பரிமாற்றத்திற்கான வழிகாட்டல் திட்டங்கள்.
 </li>
    </ul>

    <li><span>பின்னூட்ட பொறிமுறை : </span> </li>
    <ul>
        <li>உறுப்பினர் உள்ளீட்டின் அடிப்படையில் CoPஐ தொடர்ந்து மேம்படுத்த ஒரு பின்னூட்ட வளையத்தை நிறுவவும்.
 </li>
        <li>நடவடிக்கைகளின் பொருத்தத்தையும் செயல்திறனையும் தவறாமல் மதிப்பிடுங்கள்.
 </li>
    </ul>

    
  </ol>
  <p>உலகளாவிய நிலைத்தன்மை சமூக நடைமுறையின் வெற்றிக்காக உறுப்பினர்களிடையே உள்ளடக்கம், செயலில் பங்கேற்பு மற்றும் பகிரப்பட்ட பொறுப்புணர்வு ஆகியவற்றை ஊக்குவிக்க நினைவில் கொள்ளுங்கள்.</p>
</div>
        
     

        </div>

    );
}

