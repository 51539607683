import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>


<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process">English</NavLink></a>

        </div>
        
<h1 id="mhead">Zha Sustainable Certification Process</h1>
<p className="freecenter">“Free Certification with Zha Compliance”</p>
   
   <div className='second-mcontent'>
           <img src='../../img/certificate.jpg'></img>
       </div>


 

<section className="visionmi">
 
<p>ബിൽഡർമാർക്കും റിയൽറ്റർമാർക്കും പരിസ്ഥിതി സൗഹൃദ സർട്ടിഫിക്കേഷൻ ലഭിക്കുന്നതിന്, ഈ പൊതുവായ ഘട്ടങ്ങൾ പാലിക്കുക:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>റിസർച്ച് ഴ അംഗീകാര പരിപാടികൾ : </span> Our നിർമ്മാണ വ്യവസായത്തിന് ബാധകമായ ഞങ്ങളുടെ പ്രശസ്തമായ പരിസ്ഥിതി സൗഹൃദ സർട്ടിഫിക്കേഷൻ പ്രോഗ്രാമുകൾ, പരിസ്ഥിതി വിലയിരുത്തൽ മാനദണ്ഡങ്ങൾ സ്ഥാപിക്കുന്നതിനുള്ള ഗവേഷണം

</li>

<li><span>ആവശ്യകതകൾ മനസ്സിലാക്കുക : </span>  തിരഞ്ഞെടുത്ത സർട്ടിഫിക്കേഷന്റെ നിർദ്ദിഷ്ട മാനദണ്ഡങ്ങളും ആവശ്യകതകളും സ്വയം പരിചയപ്പെടുത്തുക. ഇതിൽ സുസ്ഥിര നിർമ്മാണ സാമഗ്രികൾ, ഊർജ്ജ കാര്യക്ഷമത, ജല സംരക്ഷണം, ഇൻഡോർ വായുവിന്റെ ഗുണനിലവാരം എന്നിവ ഉൾപ്പെട്ടേക്കാം.

</li>
<li><span>പരിശീലനവും വിദ്യാഭ്യാസവും : </span>  സുസ്ഥിരമായ നിർമ്മാണ രീതികളെക്കുറിച്ച് നിങ്ങളുടെ ടീമിന് വിദ്യാഭ്യാസം ഉണ്ടെന്ന് ഉറപ്പാക്കുക. ചില സർട്ടിഫിക്കേഷനുകൾക്ക് പ്രധാന ഉദ്യോഗസ്ഥർക്ക് ചില പരിശീലനം ആവശ്യമായി വന്നേക്കാം.
</li>
<li><span>സുസ്ഥിര സമ്പ്രദായങ്ങൾ നടപ്പിലാക്കുക : </span>  നിങ്ങളുടെ നിർമ്മാണ പ്രക്രിയകളിൽ പരിസ്ഥിതി സൗഹൃദ രീതികൾ സമന്വയിപ്പിക്കുക. ഇതിൽ റീസൈക്കിൾ ചെയ്ത വസ്തുക്കൾ ഉപയോഗിക്കുന്നത്, ഊർജ്ജ ഉപയോഗം ഒപ്റ്റിമൈസ് ചെയ്യൽ, ഗ്രീൻ ബിൽഡിംഗ് ടെക്നിക്കുകൾ സ്വീകരിക്കൽ എന്നിവ ഉൾപ്പെട്ടേക്കാം.

</li>
<li><span>ഡോക്യുമെന്റേഷൻ : </span> നിങ്ങളുടെ സുസ്ഥിരമായ പരിശ്രമങ്ങളുടെ സമഗ്രമായ രേഖകൾ സൂക്ഷിക്കുക. സ്ഥാപിത മാനദണ്ഡങ്ങൾ പാലിക്കുന്നുണ്ടെന്ന് കാണിക്കുന്ന, സർട്ടിഫിക്കേഷനായി ഡോക്യുമെന്റേഷൻ നിർണായകമാണ്.

</li>
<li><span>പങ്കാളികളെ ഉൾപ്പെടുത്തുക : </span> പരിസ്ഥിതി സൗഹൃദ സംരംഭങ്ങളിൽ കരാറുകാർ, വിതരണക്കാർ, തൊഴിലാളികൾ എന്നിവരുൾപ്പെടെ എല്ലാ പങ്കാളികളെയും ഉൾപ്പെടുത്തുക. സർട്ടിഫിക്കേഷൻ നേടുക എന്ന ലക്ഷ്യവുമായി എല്ലാവരും അണിനിരക്കണം.

</li>
<li><span>പ്രീ-അസെസ്‌മെന്റ് : </span> ഔദ്യോഗിക സർട്ടിഫിക്കേഷൻ പ്രക്രിയയ്ക്ക് മുമ്പ് മെച്ചപ്പെടുത്തേണ്ട മേഖലകൾ തിരിച്ചറിയുന്നതിന് ഒരു മുൻകൂർ മൂല്യനിർണയം പരിഗണിക്കുക. ഈ ഘട്ടം എന്തെങ്കിലും പോരായ്മകൾ പരിഹരിക്കാനും വിജയസാധ്യത വർദ്ധിപ്പിക്കാനും സഹായിക്കും.

</li>
<li><span>ഔപചാരിക അപേക്ഷ : </span> സർട്ടിഫിക്കേഷൻ ബോഡിക്ക് ഒരു ഔപചാരിക അപേക്ഷ സമർപ്പിക്കുക. ആവശ്യമായ എല്ലാ രേഖകളും തെളിവുകളും ആവശ്യാനുസരണം ഫീസും ഉൾപ്പെടുത്തുക.


</li>
<li><span>സൈറ്റ് പരിശോധന : </span> സർട്ടിഫിക്കേഷൻ ബോഡിയിൽ നിന്നുള്ള പ്രതിനിധികൾ സൈറ്റ് പരിശോധനയ്ക്ക് തയ്യാറെടുക്കുക. നിർദ്ദിഷ്‌ട പരിസ്ഥിതി സൗഹൃദ മാനദണ്ഡങ്ങളുമായി നിങ്ങളുടെ നിർമ്മാണ പ്രോജക്റ്റ് യോജിക്കുന്നുണ്ടോ എന്ന് അവർ വിലയിരുത്തും.

</li>
<li><span>സർട്ടിഫിക്കേഷൻ തീരുമാനം : </span> സർട്ടിഫിക്കേഷൻ തീരുമാനത്തിനായി കാത്തിരിക്കുക. വിജയിക്കുകയാണെങ്കിൽ, നിങ്ങളുടെ പ്രോജക്റ്റിന്റെ പരിസ്ഥിതി സൗഹൃദ നിലയുടെ ഔപചാരികമായ അംഗീകാരം നിങ്ങൾക്ക് ലഭിക്കും.

</li>

<li><span>സർട്ടിഫിക്കേഷൻ പ്രോത്സാഹിപ്പിക്കുക : </span> ഒരിക്കൽ സാക്ഷ്യപ്പെടുത്തിയാൽ, നിങ്ങളുടെ നിർമ്മാണ പ്രോജക്ടുകൾ പ്രോത്സാഹിപ്പിക്കുന്നതിന് പരിസ്ഥിതി സൗഹൃദ ലേബൽ ഉപയോഗിക്കുക. ഇത് നിങ്ങളുടെ കമ്പനിയുടെ പ്രശസ്തി വർദ്ധിപ്പിക്കാനും പരിസ്ഥിതി ബോധമുള്ള ഉപഭോക്താക്കളെ ആകർഷിക്കാനും കഴിയും.
</li>

<li><span>പ്രമോഷനുകളിൽ ചേർക്കാം : </span> ZHA പരിസ്ഥിതി സൗഹൃദ സർട്ടിഫിക്കേഷനിൽ ZHA ഫൗണ്ടേഷനിൽ നിന്ന് നിങ്ങൾക്ക് ഒരു സ്ഥിരീകരണ ഇമെയിൽ ലഭിച്ചുകഴിഞ്ഞാൽ, ബിൽഡർക്ക് അത് അതിന്റെ പ്രൊമോഷനിലും പരസ്യങ്ങളിലും പ്രസിദ്ധീകരിക്കാൻ കഴിയും. ZHA പരിസ്ഥിതി സൗഹൃദ സുസ്ഥിരത കൺസ്ട്രക്‌റ്ററിൽ സാക്ഷ്യപ്പെടുത്തിയ ബിൽഡർമാരെയും ZHA ഫൗണ്ടേഷൻ വെബ്‌സൈറ്റ് പട്ടികപ്പെടുത്തുന്നു.</li>

</ol>



<p>ഓർക്കുക, തിരഞ്ഞെടുത്ത സർട്ടിഫിക്കേഷൻ പ്രോഗ്രാമിനെ ആശ്രയിച്ച് നിർദ്ദിഷ്ട ഘട്ടങ്ങൾ വ്യത്യാസപ്പെടാം, അതിനാൽ തിരഞ്ഞെടുത്ത സർട്ടിഫിക്കേഷൻ ബോഡിയുടെ ആവശ്യകതകൾ സമഗ്രമായി അവലോകനം ചെയ്യേണ്ടത് അത്യാവശ്യമാണ്. </p>


</section>
 

</div>
 
 
);
}

