import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">

            <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Establishment-Story">English</NavLink></a>

        </div>
            


                <h1 id="mhead">Zha Establishment Story</h1>

                <div className='second-mcontent'>
                    <img src='../../img/story.jpg'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">

                    <p>Zha அறக்கட்டளையின் கதை பல்வேறு தொழில்களில் வெற்றிகரமான தொழில் வல்லுநர்களுடன் தொடங்குகிறது, அவர்கள் நிலையான வாழ்க்கையின் உலகளாவிய வளர்ச்சிக்கு பங்களிக்க விரும்பினர். இருப்பினும், அவர்களின் மதிப்புகளுடன் இணைந்த புதுமையான தளங்களின் பற்றாக்குறையை அவர்கள் கண்டறிந்தனர். அதே நேரத்தில், வெற்றிகரமான வணிகர்கள் பிரத்தியேக வணிகக் கழகங்களைக் கொண்டிருந்தனர், அது அவர்களின் சாதனைகளில் பெருமை சேர்க்கிறது.
                    </p>

                    <p>இந்த இடைவெளியை உணர்ந்து, ஜாவின் நிறுவனர் ஒரு மூலோபாய சமூகப் பணி வாரியத்தை உருவாக்க வேண்டியதன் அவசியத்தை நம்பினார். இந்த வாரியமானது அனைத்து மட்டங்களிலும் மற்றும் பல்வேறு இடங்களிலிருந்தும் திறமையான தொழில் வல்லுநர்கள் மற்றும் வணிகத் தலைவர்களைக் கொண்டிருக்கும். Zha நிலைத்தன்மை இயக்கத்தை பரப்புவது, பொது மக்களிடையே விழிப்புணர்வை ஏற்படுத்துவது மற்றும் உலகளாவிய தாக்கத்தை வளர்ப்பது இதன் நோக்கமாகும்.</p>

                    <p>Zha இன் நிறுவனர், கிரகம் மற்றும் அனைவரின் நல்வாழ்வுக்கும் பயனளிக்கும் நோக்கமுள்ள பொழுதுபோக்கை உருவாக்குவதைக் கற்பனை செய்தார், இது நிலைத்தன்மை கொள்கைகளில் பொதிந்துள்ள அக்கறை மற்றும் பகிர்வு மாதிரியால் ஈர்க்கப்பட்டது. எனவே, ஜா அறக்கட்டளையின் முக்கிய கருப்பொருள் நிலைத்தன்மையாக மாறியது.</p>

                    <p>Zha அறக்கட்டளையானது, பெருநிறுவனங்களுக்கு மட்டுமின்றி, அனைத்து தனிநபர்களுக்கும் பொருந்தக்கூடிய நிலைத்தன்மையின் முக்கியக் கோட்பாட்டை நிறுவ உறுதிபூண்டுள்ளது. ஐக்கிய நாடுகள் சபையின் நிலையான இலக்குகள் மற்றும் ESG அளவீடுகளுடன் இணைந்து, ஜா பொது மக்களுக்காக வடிவமைக்கப்பட்ட ஒரு வழிமுறையை உருவாக்கியுள்ளார்.</p>

                    <p>Zha நிலைத்தன்மை திட்டங்கள் பொது தனிநபர்கள், பெண்கள், ஆண்கள் மற்றும் மாணவர்கள் உட்பட, Zha நிலைத்தன்மை செயல்படுத்தும் கட்டமைப்பின் கொள்கைகள் மற்றும் மதிப்புகளை ஏற்றுக்கொள்வதற்கு அதிகாரம் அளிப்பதை நோக்கமாகக் கொண்டுள்ளன. இந்த கட்டமைப்பானது அனைத்து மனிதர்களும் பின்பற்றுவதற்கான ஒரு வழிமுறையாக செயல்படுகிறது, இது அவர்களின் நிலைத்தன்மை மனப்பான்மை திறன்களை அளவிட அனுமதிக்கிறது. தொழில்நுட்பம் மற்றும் கருவிகளைப் பயன்படுத்துவதன் மூலம், ஐக்கிய நாடுகளின் நிலைத்தன்மை இலக்குகளை பொதுமக்களுக்குப் புரிந்துகொள்ள உதவும் இலவச ஆதாரங்களை Zha அறக்கட்டளை வழங்குகிறது. இந்த முறையானது Zha நிலைத்தன்மையை செயல்படுத்தும் கட்டமைப்பிற்குள் முக்கிய நடைமுறைப் பகுதிகளை உள்ளடக்கியது, நிலையான வாழ்க்கைக்கான ஒரு விரிவான அணுகுமுறையை வடிவமைக்கிறது.</p>
                    
                    {/* <p>The story of the Zha Foundation begins with successful professionals across various industries who desired to contribute to the global development of sustainable living. However, they found a lack of innovative platforms aligning with their values. Simultaneously, successful businessmen had exclusive business clubs that instilled pride in their achievements.</p>

                    <p>Recognizing this gap, the founder of Zha believed in the necessity of forming a strategic social work board. This board would consist of accomplished professionals and business leaders at all levels and from diverse locations. The aim was to propagate the Zha sustainability movement, raising awareness among the general public and fostering a global impact.</p> */}
                </div>

                {/* <h3>Our Vision</h3> */}
                <div className="missi">
                    <h4  >Zha அறக்கட்டளை முயற்சிகளின் நிலைத்தன்மை விளைவுகள்:</h4>
                     

                </div>
                
<ol className="goalpoints">
    <li><span>தனிப்பட்ட அதிகாரமளித்தல் :</span> ழ வின் நிலைத்தன்மை திட்டங்கள் மூலம், பொதுவான தனிநபர்கள், பெண்கள், ஆண்கள் மற்றும் மாணவர்கள் ஒரு நிலையான மனநிலையைப் பின்பற்றுவதற்கு அதிகாரம் பெற்றுள்ளனர். உள்ளடக்கத்தில் அறக்கட்டளையின் கவனம், கிரகத்தின் முன்னேற்றத்திற்கு ஒவ்வொருவரும் பங்களிக்க முடியும் என்பதை உறுதி செய்கிறது.   

 </li>
    <li><span>உலகளாவிய தாக்கம் :</span>ஐக்கிய நாடுகள் சபையின் நிலையான இலக்குகள் மற்றும் ESG அளவீடுகளுடன் இணைவதன் மூலம், நிலையான வளர்ச்சிக்கான உலகளாவிய முயற்சிகளுக்கு Zha அறக்கட்டளை பங்களிக்கிறது. உலகெங்கிலும் உள்ள தனிநபர்களுக்குப் பொருந்தும் நிலைத்தன்மையின் முக்கியக் கோட்பாடு, நேர்மறையான மாற்றத்தை நோக்கிய கூட்டு முயற்சியை வலியுறுத்துகிறது.

 </li>
    <li><span>அளவிடக்கூடிய தாக்கம் : </span>ழ வின் முறையானது தனிநபர்களுக்கு அவர்களின் நிலைத்தன்மை மனப்பான்மை திறன்களை அளவிட ஒரு உறுதியான வழியை வழங்குகிறது. இது மக்கள் நிலையான வாழ்க்கைக்கான அவர்களின் பங்களிப்பைக் கண்காணிக்கவும் புரிந்துகொள்ளவும் அனுமதிக்கிறது.

 </li>
    <li><span>கல்வி மற்றும் விழிப்புணர்வு : </span>ழ அறக்கட்டளையானது இலவச ஆதாரங்களை வழங்குவதற்கு தொழில்நுட்பம் மற்றும் கருவிகளைப் பயன்படுத்துகிறது, ஐக்கிய நாடுகளின் நிலைத்தன்மை இலக்குகளைப் பற்றிய பொதுப் புரிதலை வளர்க்கிறது. இந்த கல்வி அம்சம் விழிப்புணர்வை ஊக்குவிக்கிறது மற்றும் நிலையான நடைமுறைகளின் பரந்த தத்தெடுப்பை ஊக்குவிக்கிறது.
 </li>
    <li><span>விரிவான கட்டமைப்பு :</span>Zha நிலைத்தன்மை செயல்படுத்தும் கட்டமைப்பு, அதன் முக்கிய நடைமுறைப் பகுதிகளுடன், நிலையான வாழ்க்கைக்கு ஒரு கட்டமைக்கப்பட்ட மற்றும் விரிவான அணுகுமுறையை வழங்குகிறது. இந்த கட்டமைப்பானது தனிநபர்களின் வாழ்க்கையின் பல்வேறு அம்சங்களில் நிலைத்தன்மையை இணைத்துக்கொள்வதில் வழிகாட்டுகிறது.

 </li>
    <li><span>சமூகத்தை கட்டியெழுப்புதல் : </span>பேண்தகைமை கொள்கைகளில் பொதிந்துள்ள அக்கறை மற்றும் பகிர்தல் மாதிரியானது சமூகம் மற்றும் கூட்டுப் பொறுப்புணர்வு உணர்வை வளர்க்கிறது. Zha அறக்கட்டளை தனிநபர்களை ஒத்துழைக்க ஊக்குவிக்கிறது, நிலையான நடைமுறைகளுக்கு அர்ப்பணிக்கப்பட்ட ஒத்த எண்ணம் கொண்டவர்களின் வலையமைப்பை உருவாக்குகிறது.


 </li>
     
  </ol>

 <p>ஒட்டுமொத்தமாக, Zha அறக்கட்டளையின் கதையைப் பின்தொடர்வது, நிலைத்தன்மை, கல்வி மற்றும் கூட்டு நடவடிக்கையை ஊக்குவிப்பதன் மூலம் தனிநபர்கள், சமூகங்கள் மற்றும் உலகளாவிய சூழலில் நேர்மறையான தாக்கத்தை ஏற்படுத்துகிறது.</p>

 

            </section>
        </div>


    );
}

