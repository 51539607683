import react from "react";
import React, { useState } from 'react';
import Video from './Videocarousel';
import Carousel from 'react-bootstrap/Carousel';
import "./Home.css";
import Testimonal from './Testimonal';
import Recue from './Recue';
import Card from 'react-bootstrap/Card';
import './Feedback.css';
import Recent from './Recent';
import Feedback from './Feedback';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Awards from './Feedback copy';
export default function Home() {
    return (
        <div>
<div className="hmain">
<div  id="language" >
        
        <a href=""><NavLink to="/">English</NavLink></a>

        </div>
    <div className="main-content">
        <h1>
            ZHA Foundation Charitable Trust
        </h1>
       <p>"సస్టైనబిలిటీ మైండ్‌సెట్ ఉన్న ప్రొఫెషనల్స్"</p>
    
        {/* <p>
        మా ఝా ఫౌండేషన్ అనేది మా ఇన్నోవేటివ్ టెక్ కన్సల్టింగ్ ద్వారా భారతీయ రైతులకు ఉచితంగా సహాయం చేయడానికి 2017లో స్థాపించబడిన లాభాపేక్షలేని సంస్థ, ఇది రైతుల స్థిరమైన జీవితాన్ని ప్రోత్సహించే వారి పద్ధతులను స్కేలింగ్ చేయడంలో విలువ జోడించిన ప్రయోజనాలను పొందడంలో రైతులకు సహాయపడుతుంది. వ్యవసాయ కార్యకలాపాలు, తద్వారా ప్రపంచంలో ఆహార స్థిరత్వాన్ని సృష్టిస్తుంది.
        </p> */}
    </div>
    <div className="img-align">
    <img src="../../img/hero.webp"></img>
    </div>
    </div> 
   
    <div className="unique">
  <p>80G Unique Registration Number of Zha Foundation - AAATZ1755HF20231
</p>
  <p>12A Unique Registration Number of Zha Foundation - AAATZ1755HE20231</p>
</div>

    <section className="visionmi">



<div className="purpose">
<h4>దృష్టి</h4>
<p>తరువాతి తరాలకు మెరుగైన భవిష్యత్తును నిర్మించేందుకు, ZHA ఫౌండేషన్ స్థిరమైన మనస్తత్వాన్ని పెంపొందించడం మరియు మన గ్రహం యొక్క సహజ వనరులను పరిరక్షించడం అనే ద్వంద్వ ప్రయోజనానికి అంకితం చేయబడింది.</p>
</div>

<div className="missi">
  <h4>మిషన్</h4>
<p>2017లో స్థాపించబడిన ఝా ఫౌండేషన్, స్థిరమైన మనస్తత్వాన్ని పెంపొందించడానికి కట్టుబడి ఉన్న ప్రపంచ సమాజాన్ని పెంపొందించడానికి కృషి చేస్తుంది. మేము తనిఖీ చేయని దురాశ మరియు అసూయ యొక్క ప్రతికూల ప్రభావాలను అధిగమించాలని లక్ష్యంగా పెట్టుకున్నాము, దేశాలకు మరియు గ్రహం యొక్క సున్నితమైన సమతుల్యతకు వాటి సంభావ్య హానిని గుర్తించాము. ప్రొఫెషనల్స్ మరియు బిజినెస్ లీడర్‌లతో సహకరిస్తూ, మా ఫౌండేషన్ స్థిరమైన భవిష్యత్తు కోసం, విభేదాల నుండి దూరంగా ఉండటం మరియు మా ఝా సభ్యులకు మూడు మార్గదర్శక సూత్రాల ద్వారా ఐక్యతను పెంపొందించడం కోసం వాదిస్తుంది: రాజకీయ ఆధారిత చర్చలు లేవు, మతం లేదా కులం ఆధారంగా చర్చలు లేవు మరియు మానుకోవాలనే నిబద్ధత. తోటి సభ్యుల గురించి ప్రతికూలంగా మాట్లాడటం నుండి.
</p>
 
<p>
మంచి రేపటి కోసం సుస్థిర అభివృద్ధి అత్యవసరం, భవిష్యత్ తరాల శ్రేయస్సుకు హాని కలగకుండా ప్రస్తుత అవసరాలను సమతుల్యం చేసుకోవడం. మన సమాజాల మనుగడ మరియు భాగస్వామ్య గ్రహం స్థిరమైన మనస్తత్వాన్ని పెంపొందించడంపై ఆధారపడి ఉంటుంది. పదిహేడు పరస్పర అనుసంధాన లక్ష్యాలను కలిగి ఉన్న సుస్థిర అభివృద్ధి లక్ష్యాలు (SDGలు) "ప్రజలు మరియు గ్రహం కోసం ఇప్పుడు మరియు భవిష్యత్తులో శాంతి మరియు శ్రేయస్సు కోసం భాగస్వామ్య బ్లూప్రింట్" వలె పనిచేస్తాయి. ఇందులో, మేము ఈ క్రింది వాటిపై దృష్టి పెడుతున్నాము
</p>
 
{/*  
<p className="goalpoints">
<span>లక్ష్యం 1: </span>భాగస్వామ్యం మరియు జీవన నమూనా భావనలను ప్రోత్సహించడం ద్వారా అన్ని రకాల పేదరికాన్ని నిర్మూలించండి.<br />
<span>లక్ష్యం 2: </span>ఆహార స్థిరత్వాన్ని ప్రోత్సహించడం ద్వారా శూన్య ఆకలిని సాధించండి.<br />
<span>లక్ష్యం 3: </span>సేంద్రీయ ఆహార వినియోగాన్ని ప్రోత్సహించడం ద్వారా ఆరోగ్య శ్రేయస్సును పెంపొందించడం మరియు సంపూర్ణ శ్రేయస్సు కోసం పిల్లలు, తల్లిదండ్రులు మొదలైన వారికి అందుబాటులో ఉండే క్రీడా క్లబ్‌లను అందించడం.<br />
<span>లక్ష్యం 4: </span>అన్ని స్థాయిల విద్యార్థులు ప్లాన్ ఎ మరియు ప్లాన్ బి కాన్సెప్ట్‌లను గ్రహించేలా చేయడం ద్వారా విద్యను మెరుగుపరచండి.<br />
<span>లక్ష్యం 5: </span>కుటుంబ సంక్షేమం కోసం పరస్పర గౌరవాన్ని నొక్కి చెప్పడం ద్వారా లింగ సమానత్వం మరియు మహిళా సాధికారత కోసం వాదించండి.<br />
<span>లక్ష్యం 6: </span>ప్రపంచవ్యాప్తంగా పర్యావరణ ప్రభావాలను తగ్గించడానికి నీరు మరియు పారిశుద్ధ్యాన్ని నిర్ధారించడం.<br /></p> */}
</div>
</section>
      {/* <h4>Zha Sustainability Enabler Framework</h4>
      <div className="img-align-ce">
        <img src="../../img/homepa.png"></img>
      </div> */}





    

<h1 id="About-head">Our Offerings</h1>
      <h3>Contributing towards Sustainability Social Services</h3>


      <div className="listprimary">
        <div className="listprimarylist">
          <a href="">  <NavLink to="/Rural-Primary-Health-Care"><img src="../../img/PRIMARY/1.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"><img src="../../img/PRIMARY/2.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Disaster-Relief"><img src="../../img/PRIMARY/3.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Environment"><img src="../../img/PRIMARY/4.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/PRIMARY/9.webp"></img></NavLink></a>

        </div>

        <div className="listprimarylist">
          <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/PRIMARY/11.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students"> <img src="../../img/PRIMARY/6.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Pathfinder-Course">  <img src="../../img/PRIMARY/7.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/zha-farmers-transformation"> <img src="../../img/PRIMARY/8.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/PRIMARY/10.webp"></img></NavLink></a>
        </div>

      </div>

      <div className="listsecondary">
        <Carousel>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Rural-Primary-Health-Care"> <img src='../../img/PRIMARY/1.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"> <img src='../../img/PRIMARY/2.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Disaster-Relief"> <img src='../../img/PRIMARY/3.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Environment">  <img src='../../img/PRIMARY/4.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students">  <img src='../../img/PRIMARY/6.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Pathfinder-Course"> <img src='../../img/PRIMARY/7.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/zha-farmers-transformation"> <img src='../../img/PRIMARY/8.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/PRIMARY/9.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/PRIMARY/10.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/PRIMARY/11.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </div>






      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recognition & Awards</h4>
      </div>

      <div className="feedback">
        <Awards />
      </div>



      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recent Events</h4>
      </div>
      <div className="feedback">
        <Recent />
      </div>

      <div className="whoweare">
        <div className="bar"></div>
        <h4>Feedback</h4>
      </div>

      <div className="feedback">
        <Feedback />
      </div>



      <Video />


      {/* 
<section>
<div className="whoweare">
        <div className="bar"></div>
        <h4>Testimonial</h4>
    </div>
</section>

 
<div className="videoplayerr">
        <div className="ovall-container">
      <video className="ovall-video"  loop  controls>
     
        <source src={'../img/testimonial.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </div> */}


    </div>

  );
}



