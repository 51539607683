import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import firebase from '../firebaseConfig';

const AuthForm = ({ isLogin }) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleGoogleLogin = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);

      // The signed-in user info is available in the result.user object
      console.log(result.user);

      // Redirect to the homepage after successful login
      navigate('/hh');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!agreeTerms) {
        alert('Please agree to the terms and conditions.');
        return;
      }

      if (isLogin) {
        // Login logic
        const user = await firebase.auth().signInWithEmailAndPassword(email, pass);
        if (user) {
          alert('Login Successfully');
          navigate('/hh');
        }
      } else {
        // Signup logic
        const user = await firebase.auth().createUserWithEmailAndPassword(email, pass);
        if (user) {
          alert('Account Created Successfully');
          navigate('/login'); // Redirect to login after signup
        }
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <div  id='lgoform' className="bg-white p-3 rounded">
        <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
        <form>
          {!isLogin && (
            <div className="mp-3">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                value={name}
                placeholder="Enter Name"
                name="username"
                onChange={(e) => setName(e.target.value)}
                className="form-control rounded-0"
              />
            </div>
          )}
          <div className="mp-3">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              value={email}
              placeholder="Enter Email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              className="form-control rounded-0"
            />
          </div>
          <div className="mp-3">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              value={pass}
              placeholder="Enter Password"
              name="password"
              onChange={(e) => setPass(e.target.value)}
              className="form-control rounded-0"
            />
          </div>
          <div className="mp-3">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreeTerms}
              onChange={() => setAgreeTerms(!agreeTerms)}
              required
            />
            <label htmlFor="agreeTerms"> I agree to the terms and conditions</label>
          </div>
          <button type="submit" onClick={handleSubmit} className="btn btn-success w-100">
            {isLogin ? 'Login' : 'Sign Up'}
          </button>
          <button type="button" onClick={handleGoogleLogin} className="btn btn-primary w-100 mt-3">
            Login with Google
          </button>
          <p>
            {isLogin ? (
              <Link to="/signup" className="text-decoration-none">
                Create an account
              </Link>
            ) : (
              <Link to="/login" className="text-decoration-none">
                Already have an account? Log in
              </Link>
            )}
          </p>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;
