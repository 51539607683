import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Students-Sustainability-Club">English</NavLink></a>



</div>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>ఝా స్టూడెంట్స్ సస్టైనబిలిటీ క్లబ్</h1>
</div>


<div className="internship">
        <img src="../img/students.jpg"></img>
        </div>
<div className="goalpointsp">

<p> ZHA స్టూడెంట్స్ సస్టైనబిలిటీ క్లబ్ మెంబర్‌షిప్ ప్రోగ్రామ్ అనేది ZHA ఫౌండేషన్ ద్వారా స్థాపించబడిన సుస్థిరత యొక్క నాలుగు స్తంభాలను కలిగి ఉన్న ఒక సంపూర్ణ చొరవ. ఈ సమగ్ర కార్యక్రమం స్థిరత్వం యొక్క విభిన్న కోణాలను పరిష్కరించడానికి రూపొందించబడింది, పాల్గొనే విద్యార్థులకు చక్కటి మరియు ప్రభావవంతమైన అనుభవాన్ని అందిస్తుంది.

</p>

<ol className="goalpoints">
    <li><span>మానవ ఆరోగ్య స్తంభం : </span> </li>
    <ul>
        <li>ఈ కార్యక్రమం స్థిరమైన జీవన విధానాలు, పర్యావరణానికి సంబంధించి మానసిక ఆరోగ్యం మరియు పర్యావరణ స్పృహ విలువలతో వ్యక్తిగత శ్రేయస్సుపై దృష్టి సారించే విద్యా సామగ్రి మరియు వర్క్‌షాప్‌లను అందించడం ద్వారా మానవ ఆరోగ్యాన్ని ప్రోత్సహిస్తుంది.
 </li>
    </ul>

    <li><span>ఎర్త్ వెల్నెస్ పిల్లర్ :</span> </li>
    <ul>
        <li>విద్యార్థులు భూమి యొక్క శ్రేయస్సుకు దోహదపడే కార్యకలాపాలు మరియు ప్రాజెక్టులలో పాల్గొంటారు. పునరుత్పాదక శక్తి, వ్యర్థాల తగ్గింపు మరియు పర్యావరణ అనుకూల సాంకేతికతలకు సంబంధించిన కార్యక్రమాల గురించి తెలుసుకోవడం మరియు చురుకుగా పాల్గొనడం ఇందులో ఉంటుంది.
 </li>
    </ul>

    <li><span>ప్రకృతి సంరక్షణ స్తంభం : </span> </li>
    <ul>
        <li>కార్యక్రమం ప్రకృతితో అనుబంధాన్ని నొక్కి చెబుతుంది, జీవవైవిధ్యాన్ని అభినందించడానికి మరియు రక్షించడానికి విద్యార్థులను ప్రోత్సహిస్తుంది. చెట్ల పెంపకం, నివాస పునరుద్ధరణ మరియు ప్రకృతి విహారయాత్రల వంటి కార్యక్రమాల ద్వారా, విద్యార్థులు సహజ ప్రపంచాన్ని సంరక్షించడం యొక్క ప్రాముఖ్యతపై లోతైన అవగాహనను పెంపొందించుకుంటారు.
 </li>
    </ul>

    <li><span>స్థిరమైన లాభాల స్తంభం :</span> </li>
    <ul>
        <li>స్థిరమైన లాభాల సందర్భంలో స్థిరత్వం అనేది స్థిరమైన వ్యాపార పద్ధతుల గురించి విద్యార్థులను పరిజ్ఞానాన్ని సమకూర్చడం ద్వారా పరిష్కరించబడుతుంది. ఈ స్థూపంలో పర్యావరణ మరియు సామాజిక బాధ్యతలకు ప్రాధాన్యతనిస్తూనే గ్రీన్ ఎంటర్‌ప్రెన్యూర్‌షిప్, బాధ్యతాయుతమైన వినియోగం మరియు వ్యాపారాలు అభివృద్ధి చెందడానికి వ్యూహాలపై వర్క్‌షాప్‌లు ఉన్నాయి.
 </li>
    </ul>
  </ol>
<p>మొత్తంమీద, ZHA స్టూడెంట్స్ సస్టైనబిలిటీ క్లబ్ మెంబర్‌షిప్ ప్రోగ్రామ్ దాని సమగ్రత కోసం నిలుస్తుంది, ఇది సుస్థిరత స్తంభాల పూర్తి స్పెక్ట్రమ్‌ను కవర్ చేస్తుంది. స్థిరమైన లాభం కోసం పరిగణనలతో మానవుడు, భూమి మరియు ప్రకృతి సంరక్షణను ఏకీకృతం చేయడం ద్వారా, ఈ స్తంభాల పరస్పర అనుసంధానం గురించి తెలుసుకోవడమే కాకుండా సమతుల్య మరియు స్థిరమైన భవిష్యత్తుకు చురుకుగా దోహదపడే పర్యావరణ స్పృహ ఉన్న నాయకులను ప్రోగ్రామ్ ప్రోత్సహిస్తుంది.</p>

<p>పాఠశాలలు మరియు కళాశాలలు ZHA స్టూడెంట్స్ సస్టైనబిలిటీ మెంబర్‌షిప్ ప్రోగ్రామ్ సస్టైనబిలిటీ క్లబ్‌ల పరిచయం ద్వారా పాఠశాలలకు అనేక ప్రయోజనాలను అందిస్తుంది:
</p>
<ol className="goalpoints">
    <li><span>మెరుగైన విద్య : </span>పాఠశాలలు సస్టైనబిలిటీ కోచ్‌కి ప్రాప్యతను పొందుతాయి, అతను జ్ఞానాన్ని అందించడమే కాకుండా అనుభవపూర్వక అభ్యాసాన్ని సులభతరం చేస్తుంది, విద్యార్థులలో సామాజిక బాధ్యత మరియు స్థిరత్వంపై లోతైన అవగాహనను పెంపొందించుకుంటాడు.

 </li>
    <li><span>హోలిస్టిక్ డెవలప్‌మెంట్ :</span>సస్టైనబిలిటీ క్లబ్ కాన్సెప్ట్ విద్యార్థుల విద్యలో సామాజిక బాధ్యత మరియు సుస్థిరత మనస్తత్వాన్ని ఏకీకృతం చేయడం ద్వారా సంపూర్ణ అభివృద్ధిని ప్రోత్సహిస్తుంది, ఉద్దేశ్య భావం మరియు ప్రపంచ పౌరసత్వాన్ని ప్రోత్సహిస్తుంది.

 </li>
    <li><span>ప్రాక్టికల్ అప్లికేషన్ : </span>సభ్యత్వం క్లబ్ కార్యకలాపాలు, ప్రాజెక్ట్‌లు మరియు ఈవెంట్‌ల ద్వారా స్థిరత్వ సూత్రాల ఆచరణాత్మక అనువర్తనానికి వేదికను అందిస్తుంది, విద్యార్థులు సైద్ధాంతిక జ్ఞానాన్ని వాస్తవ-ప్రపంచ చర్యలలోకి అనువదించడానికి అనుమతిస్తుంది.

 </li>
    <li><span>కమ్యూనిటీ ఎంగేజ్‌మెంట్ : </span>సస్టైనబిలిటీ క్లబ్‌లు విద్యార్థులు తమ స్థానిక కమ్యూనిటీలతో చురుకుగా పాల్గొనేలా ప్రోత్సహిస్తాయి, సామాజిక కారణాలను ప్రోత్సహించడం మరియు చుట్టుపక్కల వాతావరణంలో సానుకూల ప్రభావాన్ని సృష్టించడం.

 </li>
    <li><span>నాయకత్వ అవకాశాలు : </span>సస్టైనబిలిటీ క్లబ్‌లలో చేరి ఉన్న విద్యార్థులు నాయకత్వ పాత్రలను స్వీకరించడానికి, నాయకత్వ నైపుణ్యాలను పెంపొందించడానికి మరియు తమ సహచరులను స్థిరమైన అభ్యాసాల వైపు నడిపించడంలో బాధ్యతాయుత భావాన్ని కలిగించడానికి అవకాశం ఉంది.
 </li>
    <li><span>నెట్‌వర్కింగ్ మరియు సహకారం : </span>క్లబ్ సభ్యత్వం పాఠశాలలను విద్యా సంస్థల విస్తృత నెట్‌వర్క్‌తో కలుపుతుంది, ఇది సుస్థిరత కార్యక్రమాలలో పాల్గొంటుంది, సహకారం, జ్ఞానాన్ని పంచుకోవడం మరియు ఉమ్మడి ప్రాజెక్టులకు అవకాశాలను సృష్టిస్తుంది.

 </li>
    <li><span>పాఠ్యాంశాల లక్ష్యాలతో సమలేఖనం : </span>సుస్థిరత సూత్రాల ఏకీకరణ సమకాలీన విద్యా లక్ష్యాలతో సమలేఖనం చేస్తుంది, ప్రపంచ సవాళ్లను ఎదుర్కొనేందుకు మరియు స్థిరమైన అభివృద్ధికి దోహదపడే ఫ్రేమ్‌వర్క్‌తో పాఠశాలలను అందిస్తుంది.
 </li>
    <li><span>సానుకూల పాఠశాల సంస్కృతి : </span>పర్యావరణ సారథ్యం, సామాజిక బాధ్యత మరియు నైతిక ప్రవర్తన వంటి విలువలను నొక్కిచెబుతూ, స్థిరత్వ క్లబ్ యొక్క ఉనికి సానుకూల మరియు ముందుకు ఆలోచించే పాఠశాల సంస్కృతికి దోహదపడుతుంది.

 </li>
    <li><span>గుర్తింపు మరియు అక్రిడిటేషన్ : </span>ZHA సస్టైనబిలిటీ క్లబ్‌తో అనుబంధించబడిన పాఠశాలలు స్థిరత్వం పట్ల వారి నిబద్ధతకు గుర్తింపును పొందుతాయి, ఇది స్థిరమైన విద్యా వాతావరణాన్ని ప్రోత్సహించడంలో వారి ప్రయత్నాలను హైలైట్ చేసే అక్రిడిటేషన్ లేదా అవార్డులకు దారితీసే అవకాశం ఉంది.

 </li>
    <li><span>భవిష్యత్తు కోసం సన్నద్ధత : </span>పర్యావరణ మరియు సామాజిక స్థిరత్వంపై ఎక్కువగా దృష్టి సారించిన ప్రపంచాన్ని నావిగేట్ చేయడానికి అవసరమైన జ్ఞానం, నైపుణ్యాలు మరియు విలువలతో విద్యార్థులను సన్నద్ధం చేయడం ద్వారా సుస్థిరత క్లబ్ భావన విద్యార్థులను భవిష్యత్తు కోసం సిద్ధం చేస్తుంది.

 </li>
  </ol>

  <p>సారాంశంలో, ZHA స్టూడెంట్స్ సస్టైనబిలిటీ మెంబర్‌షిప్ సుస్థిరత విద్యను సమగ్రపరచడం, సమగ్ర అభివృద్ధిని ప్రోత్సహించడం మరియు మరింత స్థిరమైన మరియు సామాజిక బాధ్యతగల భవిష్యత్తుకు చురుకుగా సహకరించడానికి విద్యార్థులను ప్రోత్సహించడం ద్వారా పాఠశాలలకు ప్రయోజనం చేకూరుస్తుంది.</p>
     
</div>
        

        </div>

    );
}

