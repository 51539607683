import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        
        <div>
        <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                    எங்கள் அறக்கட்டளை ஒரு இலாப நோக்கற்ற அமைப்பாகும், இது இந்திய விவசாயிகளுக்கு விவசாய வணிகத்தில் இலவச ஆலோசனை மூலம் உதவவும், விவசாயிகளுக்கு மதிப்பு கூட்டப்பட்ட நன்மைகளை வழங்கவும் மற்றும் கோரிக்கைகளின் அடிப்படையில் அவர்களின் பண்ணைகளை நிர்வகிக்கவும் தொடங்கப்பட்டது. எனவே எங்கள் நுகர்வோருக்கு மதிப்பை வழங்கும் ஒரு பொருளைக் கண்டுபிடிக்க வேண்டும் என்று நாங்கள் பார்த்தோம்.

                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/abou2.jpg" />
     <p>
     நாங்கள் நிலையான மற்றும் உள்ளடக்கிய வளர்ச்சியை விரைவுபடுத்துகிறோம் மற்றும் உணவு மற்றும் விவசாய நிறுவனங்கள் மற்றும் நிறுவனங்களுக்கு மாற்றியமைக்கும் தாக்கத்தை வழங்குகிறோம். எங்கள் தனியுரிம கருவிகள் மற்றும் டிஜிட்டல் மற்றும் மேம்பட்ட பகுப்பாய்வு திறன்கள், விரிவான அனுபவத்துடன் இணைந்து எங்கள் வாடிக்கையாளர்களுக்கு நாங்கள் கொண்டு வரும் மதிப்பின் மூலக்கல்லாகும்.
     </p>
 </div>
 <div className="about-card">
     <img src="../../img/abou3.jpg" />
     <p>
     ழஅறக்கட்டளை (ZHA foundation) பல துறை சார்ந்த ஆராய்ச்சி, திறன் மேம்பாடு, சுய மேம்பாடுகள் மற்றும் இளைஞர்களின் மனம் மற்றும் இதய ஆரோக்கியத்திற்கு உதவும் வகையில் பிரச்சாரங்களை மேற்கொள்கிறது. ஒரு முழு தலைமுறையும் அதிக கவலையுடனும், பலவீனமாகவும், மனச்சோர்வுடனும் இருக்கிறது. அவர்கள் ரிஸ்க் எடுப்பதில் மிகவும் குறைவான வசதியானவர்கள். இது ஒரு தலைமுறையின் உண்மையான மாற்றம். எனவே, ஆரோக்கியத்தை அளவிடுவதற்கான வழிகளை நாங்கள் உருவாக்கி வருகிறோம், அதில் பின்னடைவு, அன்பு, நம்பிக்கை, கலாச்சார அடையாளம், விமர்சன உணர்வு, சுயநிர்ணயம் மற்றும் விவசாயம் சார்ந்த முன்னேற்றங்கள் ஆகியவை அடங்கும்.
     </p>
 </div>
            </div>
            <div className="heightt">
                <h2 className="structuretit">Zha Transformation Method</h2>
                <img src="../img/height.png"></img>
            </div>
        </div>

    );
}