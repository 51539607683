import React from 'react';
 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a>
        </div> */}

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Scaling-Sustainability-Maturity-Framework">English</NavLink></a>
         
        </div>
       
        <h1 id="mhead">ಝಾ ಸ್ಕೇಲಿಂಗ್ ಸಸ್ಟೈನಬಿಲಿಟಿ ಮೆಚುರಿಟಿ ಫ್ರೇಮ್ವರ್ಕ್</h1>
   
    


     
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/scalee.webp'></img>
            </div>
            <div>
           <p>ಕೆಳಗಿನ ವಿವರಗಳೊಂದಿಗೆ ನಿರ್ಮಾಣ ಹಂತದಲ್ಲಿರುವ ಝಾ ಸ್ಕೇಲಿಂಗ್ ಸಸ್ಟೈನಬಿಲಿಟಿ ಮೆಚುರಿಟಿ ಫ್ರೇಮ್‌ವರ್ಕ್ ಇಲ್ಲಿದೆ. ಮಾನವನ ಸ್ವಾಸ್ಥ್ಯ, ಪ್ರಕೃತಿ ಕ್ಷೇಮ ಮತ್ತು ಭೂಮಿಯ ಸ್ವಾಸ್ಥ್ಯಕ್ಕಾಗಿ ನಾಲ್ಕು ಹಂತದ ಪರಿಪಕ್ವತೆಯೊಂದಿಗೆ ಸ್ಕೇಲಿಂಗ್ ಸುಸ್ಥಿರತೆಯ ಚೌಕಟ್ಟನ್ನು ರಚಿಸುವುದು ಎಚ್ಚರಿಕೆಯಿಂದ ಯೋಜನೆ ಮತ್ತು ಪರಿಗಣನೆಯನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ಅಂತಹ ಚೌಕಟ್ಟನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡುವ ಸಾಮಾನ್ಯ ಮಾರ್ಗದರ್ಶಿ ಇಲ್ಲಿದೆ:


            </p>
            <h2>ಪ್ರಬುದ್ಧತೆಯ ನಾಲ್ಕು ಹಂತಗಳನ್ನು ವಿವರಿಸಿ</h2>
           
<ol>
    <li><span>ದೀಕ್ಷೆ : </span>
    <ul>
        <li>ಸುಸ್ಥಿರತೆಯ ತತ್ವಗಳಿಗೆ ಮೂಲಭೂತ ಅರಿವು ಮತ್ತು ಬದ್ಧತೆ.</li>
        <li>ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳನ್ನು ಸಂಯೋಜಿಸಲು ಆರಂಭಿಕ ಪ್ರಯತ್ನಗಳು.</li>
        <li>ಸೀಮಿತ ವ್ಯಾಪ್ತಿ ಮತ್ತು ಪ್ರಭಾವ.</li>
    </ul>

</li>


<li><span>ಅಭಿವೃದ್ಧಿ :</span>
    <ul>
        <li>ಮಾನವ, ಪ್ರಕೃತಿ ಮತ್ತು ಭೂಮಿಯ ಸ್ವಾಸ್ಥ್ಯದ ಪರಸ್ಪರ ಸಂಬಂಧದ ಬಗ್ಗೆ ಬೆಳೆಯುತ್ತಿರುವ ತಿಳುವಳಿಕೆ.</li>
        <li>ಸಮಗ್ರ ಸುಸ್ಥಿರತೆಯ ಉಪಕ್ರಮಗಳನ್ನು ಅನುಷ್ಠಾನಗೊಳಿಸುವುದು.</li>
        <li>ವಿವಿಧ ಡೊಮೇನ್‌ಗಳಲ್ಲಿ ಪ್ರಯತ್ನಗಳನ್ನು ವಿಸ್ತರಿಸುವುದು.</li>
    </ul>

</li>


<li><span>ಆಪ್ಟಿಮೈಸೇಶನ್ : </span>
    <ul>
        <li>ಸುಸ್ಥಿರ ಅಭ್ಯಾಸಗಳ ಸುಧಾರಿತ ಏಕೀಕರಣ.</li>
        <li>ಡೇಟಾ ಮತ್ತು ಪ್ರತಿಕ್ರಿಯೆಯ ಆಧಾರದ ಮೇಲೆ ನಿರಂತರ ಸುಧಾರಣೆ.</li>
        <li>ಪಾಲುದಾರರೊಂದಿಗೆ ಸಹಯೋಗದ ಪಾಲುದಾರಿಕೆ.</li>
    </ul>

</li>
  
<li><span>ರೂಪಾಂತರ : </span>
    <ul>
        <li>ಸುಸ್ಥಿರತೆಗೆ ಸಮಗ್ರ ಮತ್ತು ಪರಿವರ್ತಕ ವಿಧಾನ.</li>
        <li>ಉದ್ಯಮದ ಮಾನದಂಡಗಳನ್ನು ಹೊಂದಿಸುವಲ್ಲಿ ನಾಯಕತ್ವ.</li>
        <li>ನಡೆಯುತ್ತಿರುವ ನಾವೀನ್ಯತೆ ಮತ್ತು ಉದಯೋನ್ಮುಖ ಸವಾಲುಗಳಿಗೆ ಹೊಂದಿಕೊಳ್ಳುವಿಕೆ.</li>
    </ul>

</li>
</ol>


<h2>ಫ್ರೇಮ್ವರ್ಕ್ ಘಟಕಗಳು</h2>
           
           <ol>
               <li><span>ಮಾನವ ಸ್ವಾಸ್ಥ್ಯ : </span> 
               <ul>
                   <li><span>ಆರೋಗ್ಯ ಮತ್ತು ಯೋಗಕ್ಷೇಮ ಕಾರ್ಯಕ್ರಮಗಳು : </span>ದೈಹಿಕ, ಮಾನಸಿಕ ಮತ್ತು ಭಾವನಾತ್ಮಕ ಯೋಗಕ್ಷೇಮವನ್ನು ಉತ್ತೇಜಿಸುವ ಉಪಕ್ರಮಗಳನ್ನು ಜಾರಿಗೊಳಿಸಿ.</li>
                   <li><span>ಕೆಲಸ-ಜೀವನ ಸಮತೋಲನ : </span>ಕೆಲಸ-ಜೀವನದ ಸಮತೋಲನ ಮತ್ತು ಉದ್ಯೋಗಿ ತೃಪ್ತಿಗೆ ಆದ್ಯತೆ ನೀಡುವ ಪರಿಸರಗಳನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಿ.</li>
                   <li><span>ಸಮುದಾಯ ತೊಡಗಿಸಿಕೊಳ್ಳುವಿಕೆ : </span>ಸಮುದಾಯ ತೊಡಗಿಸಿಕೊಳ್ಳುವಿಕೆ : ಸ್ಥಳೀಯ ಸಮುದಾಯಗಳೊಂದಿಗೆ ತೊಡಗಿಸಿಕೊಳ್ಳಲು ಮತ್ತು ಬೆಂಬಲಿಸಲು ಉದ್ಯೋಗಿಗಳನ್ನು ಪ್ರೋತ್ಸಾಹಿಸಿ.</li>
               </ul>
           
           </li>

           <li><span>ಪ್ರಕೃತಿ ಸ್ವಾಸ್ಥ್ಯ : </span>
               <ul>
                   <li><span>ಜೀವವೈವಿಧ್ಯ ಸಂರಕ್ಷಣೆ : </span>ಜೀವವೈವಿಧ್ಯತೆಯನ್ನು ಸಂರಕ್ಷಿಸಲು ಮತ್ತು ಹೆಚ್ಚಿಸಲು ಅಭ್ಯಾಸಗಳನ್ನು ಅಳವಡಿಸಿ.</li>
                   <li><span>ಪರಿಸರ ಸ್ನೇಹಿ ಕಾರ್ಯಾಚರಣೆಗಳು : </span>ಪರಿಸರದ ಪ್ರಭಾವವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಪ್ರಕ್ರಿಯೆಗಳನ್ನು ಆಪ್ಟಿಮೈಸ್ ಮಾಡಿ.</li>
                   <li><span>ಸಸ್ಟೈನಬಲ್ ಸೋರ್ಸಿಂಗ್ : </span>ಸಮರ್ಥನೀಯವಾಗಿ ಮೂಲದ ವಸ್ತುಗಳ ಬಳಕೆಗೆ ಆದ್ಯತೆ ನೀಡಿ.</li>
               </ul>
           
           </li>

           <li><span>ಭೂಮಿಯ ಸ್ವಾಸ್ಥ್ಯ : </span>
               <ul>
                   <li><span>ಕಾರ್ಬನ್ ನ್ಯೂಟ್ರಾಲಿಟಿ : </span>ನವೀಕರಿಸಬಹುದಾದ ಶಕ್ತಿ ಮತ್ತು ಕಾರ್ಬನ್ ಆಫ್‌ಸೆಟ್ ಉಪಕ್ರಮಗಳ ಮೂಲಕ ಕಾರ್ಬನ್ ನ್ಯೂಟ್ರಾಲಿಟಿಗಾಗಿ ಶ್ರಮಿಸಿ.</li>
                   <li><span>ತ್ಯಾಜ್ಯ ಕಡಿತ : </span>ತ್ಯಾಜ್ಯ ಕಡಿತ: ತ್ಯಾಜ್ಯ ಕಡಿತ ಮತ್ತು ಮರುಬಳಕೆ ಕಾರ್ಯಕ್ರಮಗಳನ್ನು ಜಾರಿಗೊಳಿಸಿ.</li>
                   <li><span>ಹವಾಮಾನ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವ : </span>ಹವಾಮಾನ ಬದಲಾವಣೆಯ ಪ್ರಭಾವಕ್ಕೆ ಹೊಂದಿಕೊಳ್ಳಲು ಮತ್ತು ತಗ್ಗಿಸಲು ತಂತ್ರಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಿ.</li>
               </ul>
           
           </li>
           </ol>


           
           <h2>ಅನುಷ್ಠಾನದ ಹಂತಗಳು</h2>
           
           <ol>
               <li><span>ಮೌಲ್ಯಮಾಪನ : </span>
               <ul>
                   <li>ಸ್ವಾಸ್ಥ್ಯದ ಪ್ರತಿಯೊಂದು ಆಯಾಮದಲ್ಲಿ ಪ್ರಸ್ತುತ ಅಭ್ಯಾಸಗಳ ಆರಂಭಿಕ ಮೌಲ್ಯಮಾಪನವನ್ನು ನಡೆಸುವುದು.</li>
                   <li>ಸಾಮರ್ಥ್ಯಗಳು, ದೌರ್ಬಲ್ಯಗಳು, ಅವಕಾಶಗಳು ಮತ್ತು ಬೆದರಿಕೆಗಳನ್ನು ಗುರುತಿಸಿ.</li>
                
               </ul>
           
           </li>
           
           
           <li><span>ಗುರಿ ನಿರ್ಧಾರ : </span>
               <ul>
                   <li>ಮಾನವ, ಪ್ರಕೃತಿ ಮತ್ತು ಭೂಮಿಯ ಕ್ಷೇಮದಲ್ಲಿ ಪ್ರತಿ ಹಂತದ ಪರಿಪಕ್ವತೆಗೆ ನಿರ್ದಿಷ್ಟ, ಅಳೆಯಬಹುದಾದ, ಸಾಧಿಸಬಹುದಾದ, ಸಂಬಂಧಿತ ಮತ್ತು ಸಮಯ-ಬೌಂಡ್ (SMART) ಗುರಿಗಳನ್ನು ಸ್ಥಾಪಿಸಿ.</li>
               </ul>
           
           </li>
           
           
           <li><span>ಅನುಷ್ಠಾನ ಯೋಜನೆಗಳು: </span>
               <ul>
                   <li>ಪ್ರಬುದ್ಧತೆಯ ಪ್ರತಿ ಹಂತಕ್ಕೆ ವಿವರವಾದ ಯೋಜನೆಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಿ, ಕ್ರಮಗಳು, ಜವಾಬ್ದಾರಿಗಳು ಮತ್ತು ಟೈಮ್‌ಲೈನ್‌ಗಳನ್ನು ನಿರ್ದಿಷ್ಟಪಡಿಸಿ.</li>
                   <li>ವಿಭಿನ್ನ ಸಂದರ್ಭಗಳಲ್ಲಿ ಸ್ಕೇಲೆಬಿಲಿಟಿ ಮತ್ತು ಪುನರಾವರ್ತನೆಯನ್ನು ಪರಿಗಣಿಸಿ.</li>
               </ul>
           
           </li>
             
           <li><span>ಮಾನಿಟರಿಂಗ್ ಮತ್ತು ಮೌಲ್ಯಮಾಪನ : </span>
               <ul>
                   <li>ದೃಢವಾದ ಮೇಲ್ವಿಚಾರಣೆ ಮತ್ತು ಮೌಲ್ಯಮಾಪನ ಕಾರ್ಯವಿಧಾನಗಳನ್ನು ಅಳವಡಿಸಿ.</li>
                   <li>ನಿಯಮಿತವಾಗಿ ಪ್ರಗತಿಯನ್ನು ನಿರ್ಣಯಿಸಿ, ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಸಂಗ್ರಹಿಸಿ ಮತ್ತು ಅದಕ್ಕೆ ತಕ್ಕಂತೆ ತಂತ್ರಗಳನ್ನು ಹೊಂದಿಸಿ.
</li>
               </ul>
           
           </li>


           <li><span>ಮಧ್ಯಸ್ಥಗಾರರ ನಿಶ್ಚಿತಾರ್ಥ : </span>
               <ul>
                   <li>ಉದ್ಯೋಗಿಗಳು, ಸಮುದಾಯಗಳು, ಪೂರೈಕೆದಾರರು ಮತ್ತು ನಿಯಂತ್ರಕ ಸಂಸ್ಥೆಗಳು ಸೇರಿದಂತೆ ಎಲ್ಲಾ ಹಂತಗಳಲ್ಲಿ ಪಾಲುದಾರರೊಂದಿಗೆ ತೊಡಗಿಸಿಕೊಳ್ಳಿ.</li>
                   <li>ಸಹಯೋಗ ಮತ್ತು ಹಂಚಿಕೆಯ ಜವಾಬ್ದಾರಿಯನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಿ.
</li>
               </ul>
           
           </li>


           <li><span>ಶಿಕ್ಷಣ ಮತ್ತು ಸಂವಹನ : </span>
               <ul>
                   <li>ಜಾಗೃತಿ ಮೂಡಿಸಲು ಮತ್ತು ಸುಸ್ಥಿರತೆಯ ಸಂಸ್ಕೃತಿಯನ್ನು ಬೆಳೆಸಲು ನಡೆಯುತ್ತಿರುವ ಶಿಕ್ಷಣ ಮತ್ತು ಸಂವಹನವನ್ನು ಒದಗಿಸಿ.</li>
                   <li>ಸಾಧನೆಗಳನ್ನು ಆಚರಿಸಿ ಮತ್ತು ಉತ್ತಮ ಅಭ್ಯಾಸಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ.
                   
</li>
               </ul>
           
           </li>

           <li><span>ನಿರಂತರ ಸುಧಾರಣೆ : </span>
               <ul>
                   <li>ನಿರಂತರ ಸುಧಾರಣೆಯ ಸಂಸ್ಕೃತಿಯನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳಿ.</li>
                   <li>ವಿಕಸನಗೊಳ್ಳುತ್ತಿರುವ ಮಾನದಂಡಗಳು ಮತ್ತು ಸವಾಲುಗಳೊಂದಿಗೆ ಹೊಂದಿಕೊಳ್ಳಲು ಸುಸ್ಥಿರತೆಯ ಚೌಕಟ್ಟನ್ನು ನಿಯಮಿತವಾಗಿ ಮರುಪರಿಶೀಲಿಸಿ ಮತ್ತು ನವೀಕರಿಸಿ.
</li>
               </ul>
           
           </li>

           </ol>
           

<p>ನಿಮ್ಮ ಸಂಸ್ಥೆ ಅಥವಾ ಉಪಕ್ರಮದ ನಿರ್ದಿಷ್ಟ ಸಂದರ್ಭದ ಆಧಾರದ ಮೇಲೆ ಚೌಕಟ್ಟನ್ನು ಕಸ್ಟಮೈಸ್ ಮಾಡಲು ಮರೆಯದಿರಿ ಮತ್ತು ಸಮರ್ಥನೀಯತೆ ಮತ್ತು ಸಂಬಂಧಿತ ಕ್ಷೇತ್ರಗಳಲ್ಲಿ ತಜ್ಞರಿಂದ ಇನ್‌ಪುಟ್ ಪಡೆಯಿರಿ.</p>
           </div>
        </div>









               
        
       

    </div>
);
}