import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
  return (
    <div>
      <div className="hmain">



      <div  id="language" >
        
        <a href=""><NavLink to="/Rural-Primary-Health-Care">English</NavLink></a>

        </div>

        
        {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <h1 id="mhead">झा ग्रामीण प्राथमिक स्वास्थ्य सेवा</h1>

        <div className='second-mcontent'>
          <img src='../../img/health.png'></img> 
        </div>


      </div>


      <section className="visionmi">

        <div className="purpose">

          <p>झा फाउंडेशन में, हम समुदायों को बदलने के लिए सुलभ स्वास्थ्य देखभाल की शक्ति में विश्वास करते हैं। हमारा मिशन विशेष रूप से ग्रामीण क्षेत्रों में स्वास्थ्य सेवाओं में अंतर को पाटना है, यह सुनिश्चित करना है कि प्रत्येक व्यक्ति को गुणवत्तापूर्ण प्राथमिक स्वास्थ्य देखभाल तक पहुंच हो।
          </p>
        </div>

        <h3>हमारी प्रतिबद्धता</h3>
        <div className="missi">
          <h4 className="toleftneed">ग्रामीण सशक्तिकरण:</h4>
          <p>झा फाउंडेशन की पहल के केंद्र में बेहतर स्वास्थ्य परिणामों के लिए आवश्यक उपकरणों और संसाधनों के साथ ग्रामीण समुदायों को सशक्त बनाने की प्रतिबद्धता निहित है। हम दूरदराज के क्षेत्रों में व्यक्तियों के सामने आने वाली अनूठी चुनौतियों को पहचानते हैं और स्थायी स्वास्थ्य देखभाल समाधान प्रदान करने के लिए समर्पित हैं।</p>

          <h4 className="toleftneed">व्यापक स्वास्थ्य सेवा:</h4>
          <p>हमारी प्राथमिक स्वास्थ्य सेवाएँ चिकित्सा, निवारक और कल्याण कार्यक्रमों की एक विस्तृत श्रृंखला को शामिल करती हैं। नियमित जांच से लेकर स्वास्थ्य शिक्षा तक, हमारा लक्ष्य एक समग्र स्वास्थ्य देखभाल पारिस्थितिकी तंत्र बनाना है जो ग्रामीण आबादी की विविध आवश्यकताओं को पूरा करता है।</p>
        </div>


        <h3>हमारा दृष्टिकोण</h3>
        <div className="missi">
          <h4 className="toleftneed">समुदाय-केंद्रित देखभाल:</h4>
          <p>झा फाउंडेशन समुदाय-केंद्रित स्वास्थ्य सेवा की ताकत में विश्वास करता है। हमारे कार्यक्रम स्थानीय समुदायों के सहयोग से, उनकी विशिष्ट आवश्यकताओं, सांस्कृतिक बारीकियों और उपलब्ध संसाधनों को ध्यान में रखते हुए डिज़ाइन किए गए हैं। निवासियों के साथ हाथ से काम करके, हम एक ऐसा स्वास्थ्य देखभाल मॉडल बनाने का प्रयास करते हैं जो समावेशी और प्रभावी दोनों हो।</p>

          <h4 className="toleftneed">कुशल स्वास्थ्य देखभाल पेशेवर:</h4>
          <p>हम समर्पित स्वास्थ्य देखभाल पेशेवरों की अपनी टीम पर गर्व करते हैं जो सकारात्मक प्रभाव डालने के लिए उत्साहित हैं। हमारे डॉक्टर, नर्स और सहायक कर्मचारी न केवल अत्यधिक कुशल हैं, बल्कि जिन समुदायों की वे सेवा करते हैं, उनके भीतर स्थायी संबंध बनाने के लिए भी प्रतिबद्ध हैं।</p>
        </div>

        <h3>हमारी सेवाएँ</h3>
        <div className="missi">
          <h4 className="toleftneed">मोबाइल क्लीनिक:</h4>
          <p>झा फाउंडेशन मोबाइल क्लीनिक संचालित करता है जो सबसे दूरदराज के कोनों तक पहुंचता है, और जरूरतमंद लोगों के दरवाजे तक सीधे स्वास्थ्य सेवा पहुंचाता है। ये क्लीनिक नवीनतम चिकित्सा सुविधाओं से सुसज्जित हैं और अनुभवी स्वास्थ्य पेशेवरों द्वारा कार्यरत हैं।</p>

          <h4 className="toleftneed">स्वास्थ्य शिक्षा कार्यक्रम:</h4>
          <p>रोकथाम अच्छे स्वास्थ्य की कुंजी है। हम व्यक्तियों को उनकी भलाई के बारे में सूचित निर्णय लेने के लिए आवश्यक ज्ञान के साथ सशक्त बनाने के लिए नियमित स्वास्थ्य शिक्षा कार्यक्रम संचालित करते हैं। स्वच्छता प्रथाओं से लेकर बीमारी की रोकथाम तक, हमारा लक्ष्य स्वस्थ जीवन के लिए नींव तैयार करना है।</p>

          <h4 className="toleftneed">आपातकालीन प्रतिक्रिया:</h4>
          <p>झा फाउंडेशन ग्रामीण क्षेत्रों में चिकित्सा आपात स्थितियों पर तेजी से प्रतिक्रिया देने के लिए तैयार है। हमारी आपातकालीन प्रतिक्रिया टीमों को विभिन्न स्थितियों से निपटने के लिए प्रशिक्षित किया जाता है, ताकि यह सुनिश्चित किया जा सके कि जब इसकी सबसे अधिक आवश्यकता हो तो मदद कभी भी दूर न हो।</p>
        </div>


      </section>
    </div>


  );
}

