import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        <a href=""><NavLink to="/Zha-Global-Community-of-Practice">English</NavLink></a>
        </div>
 

            <div className="social-ma">

<h1>ఝా గ్లోబల్ కమ్యూనిటీ ఆఫ్ ప్రాక్టీస్</h1>
</div>


<div className="internship">
        <img src="../img/practice.webp"></img>
        </div>
<div className="goalpointsp">

<p>
గ్లోబల్ సస్టైనబిలిటీ కమ్యూనిటీ ఆఫ్ ప్రాక్టీస్ (CoP)ని సృష్టించడం అనేది జ్ఞానాన్ని పంచుకోవడానికి, సహకరించడానికి మరియు సుస్థిరత పద్ధతులను ముందుకు తీసుకెళ్లడానికి నిపుణులు మరియు అభ్యాసకులను ఒకచోట చేర్చడం. మీ COP కోసం సూచించబడిన రూపురేఖలు ఇక్కడ ఉన్నాయి:


</p>


<ol className="goalpoints">
    <li><span>లక్ష్యాలు : </span> </li>
    <ul>
        <li>ప్రయోజనాన్ని నిర్వచించండి: సుస్థిరతలో ఆలోచనలు, ఉత్తమ అభ్యాసాలు మరియు ఆవిష్కరణల మార్పిడిని సులభతరం చేయండి.
 </li>
        <li>ప్రపంచ సుస్థిరత సవాళ్లపై సహకారాన్ని ప్రోత్సహించండి.
 </li>
 <li>నేర్చుకోవడం మరియు అభివృద్ధి కోసం సహాయక సంఘాన్ని ప్రోత్సహించండి.
</li>
    </ul>

    <li><span>సభ్యత్వం : </span> </li>
    <ul>
        <li>సుస్థిరత పట్ల మక్కువ ఉన్న నిపుణులు, నిపుణులు మరియు సంస్థలను ఆహ్వానించండి.
 </li>
        <li>పరిశ్రమలు, భౌగోళికాలు మరియు నైపుణ్యం ఉన్న ప్రాంతాలలో విభిన్న ప్రాతినిధ్యం.
 </li>
    </ul>

    <li><span>కమ్యూనికేషన్ :</span> </li>
    <ul>
        <li>చర్చలు, వనరుల భాగస్వామ్యం మరియు సహకారం కోసం ప్రత్యేక ఆన్‌లైన్ ప్లాట్‌ఫారమ్‌ను ఏర్పాటు చేయండి.
 </li>
        <li>నిజ-సమయ పరస్పర చర్యను సులభతరం చేయడానికి రెగ్యులర్ వర్చువల్ సమావేశాలు, వెబ్‌నార్లు లేదా వర్క్‌షాప్‌లు.
 </li>
    </ul>


    <li><span>కంటెంట్ మరియు నాలెడ్జ్ షేరింగ్ : </span> </li>
    <ul>
        <li>కేస్ స్టడీస్, సక్సెస్ స్టోరీలు మరియు నేర్చుకున్న పాఠాలను షేర్ చేయండి.
 </li>
        <li>పరిశోధన ప్రాజెక్ట్‌లు, వైట్‌పేపర్‌లు లేదా ప్రచురణలపై సహకరించండి.
 </li>
    </ul>

    <li><span>వర్కింగ్ గ్రూపులు : </span> </li>
    <ul>
        <li>నిర్దిష్ట స్థిరత్వ స్తంభాలు లేదా పరిశ్రమలను ఉద్దేశించి ఫోకస్ చేసిన ఉప సమూహాలను సృష్టించండి.
 </li>
        <li>ప్రాజెక్ట్‌లు లేదా కార్యక్రమాలకు చురుకుగా సహకరించడానికి సభ్యులను ప్రారంభించండి.
 </li>
    </ul>

    <li><span>ఈవెంట్‌లు :</span> </li>
    <ul>
        <li>ఆవిష్కరణలను ప్రదర్శించడానికి మరియు విజయాలను జరుపుకోవడానికి వార్షిక సమావేశాలు లేదా ఈవెంట్‌లను నిర్వహించండి.
 </li>
        <li>సంబంధిత సుస్థిరత అంశాలపై కీనోట్ స్పీకర్లు మరియు ప్యానెల్ చర్చలను ఫీచర్ చేయండి.
 </li>
    </ul>

    <li><span>భాగస్వామ్యాలు : </span> </li>
    <ul>
        <li>ప్రభావాన్ని పెంచడానికి విద్యా సంస్థలు, NGOలు మరియు వ్యాపారాలతో సహకరించండి.
 </li>
        <li>ఉమ్మడి కార్యక్రమాలు లేదా పరిశోధన ప్రాజెక్టుల కోసం భాగస్వామ్యాలను ఏర్పరచుకోండి.
 </li>
    </ul>

    <li><span>కొలమానాలు మరియు రిపోర్టింగ్ : </span> </li>
    <ul>
        <li>సుస్థిరతను ప్రోత్సహించడంలో COP ప్రభావాన్ని కొలవడానికి కొలమానాలను అభివృద్ధి చేయండి.
 </li>
        <li>సభ్యులు మరియు వాటాదారులకు క్రమం తప్పకుండా పురోగతి మరియు విజయాలను నివేదించండి.
 </li>
    </ul>

    <li><span>శిక్షణ మరియు అభివృద్ధి : </span> </li>
    <ul>
        <li>అభివృద్ధి చెందుతున్న స్థిరత్వ పోకడలు మరియు సాధనాలపై శిక్షణా సెషన్‌లు లేదా వర్క్‌షాప్‌లను ఆఫర్ చేయండి.
 </li>
        <li>సభ్యుల మధ్య జ్ఞాన బదిలీ కోసం మెంటర్‌షిప్ కార్యక్రమాలు.
 </li>
    </ul>

    <li><span>ఫీడ్‌బ్యాక్ మెకానిజం : </span> </li>
    <ul>
        <li>సభ్యుల ఇన్‌పుట్ ఆధారంగా CoPని నిరంతరం మెరుగుపరచడానికి ఫీడ్‌బ్యాక్ లూప్‌ను ఏర్పాటు చేయండి.
 </li>
        <li>కార్యకలాపాల యొక్క ఔచిత్యం మరియు ప్రభావాన్ని క్రమం తప్పకుండా అంచనా వేయండి.
 </li>
    </ul>

    
  </ol>
  <p>గ్లోబల్ సస్టైనబిలిటీ కమ్యూనిటీ ఆఫ్ ప్రాక్టీస్ యొక్క విజయం కోసం సభ్యులలో చేరిక, క్రియాశీల భాగస్వామ్యం మరియు భాగస్వామ్య బాధ్యత యొక్క భావాన్ని ప్రోత్సహించాలని గుర్తుంచుకోండి.</p>
</div>
        
     

        </div>

    );
}

