import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About() {
    return (

        <div>
            <div id="language" >

                <a href=""><NavLink to="/about">English</NavLink></a>

            </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                        మా ఫౌండేషన్ అనేది లాభాపేక్ష లేని సంస్థ, ఇది వ్యవసాయ వ్యాపారంలో ఉచితంగా డైనమిక్ కన్సల్టింగ్ ద్వారా భారతీయ రైతులకు సహాయం చేయడానికి, రైతులకు విలువ జోడించిన ప్రయోజనాలను అందించడానికి మరియు అభ్యర్థనల ఆధారంగా వారి పొలాలను నిర్వహించడానికి ప్రారంభించబడింది. కాబట్టి మేము మా వినియోగదారులకు విలువను అందించే ఉత్పత్తిని కనుగొనవలసి ఉందని మేము చూశాము.



                    </p>
                </div>
                <div className="about-card">
                    <img src="../../img/abou2.jpg" />
                    <p>
                        మేము సుస్థిరమైన మరియు సమ్మిళిత వృద్ధిని వేగవంతం చేస్తాము మరియు ఆహార మరియు వ్యవసాయ కంపెనీలు మరియు సంస్థలకు సామర్థ్యాలను పెంచడం, వృద్ధికి ఆజ్యం పోయడం, పరిశ్రమలను రూపొందించడం మరియు సంస్థాగత ప్రభావాన్ని పెంచడం ద్వారా పరివర్తన ప్రభావాన్ని అందిస్తాము. మా యాజమాన్య సాధనాలు మరియు డిజిటల్ మరియు అధునాతన అనలిటిక్స్ సామర్థ్యాలు, విస్తృతమైన అనుభవంతో కలిపి మేము మా క్లయింట్‌లకు అందించే విలువకు మూలస్తంభాలు.


                    </p>
                </div>
                <div className="about-card">
                    <img src="../../img/abou3.jpg" />
                    <p>
                        ఝా ఫౌండేషన్ మల్టీ-డిసిప్లినరీ రీసెర్చ్, కెపాసిటీ బిల్డింగ్, సెల్ఫ్ ఇంప్రూవ్‌మెంట్‌లలో నిమగ్నమై ఉంది మరియు యువత మనస్సు & గుండె ఆరోగ్యానికి సహాయపడటానికి లింక్ చేయబడిన ప్రచారాలను చేపట్టింది. మొత్తం తరం మరింత ఆత్రుతగా, మరింత పెళుసుగా, మరింత నిరాశకు గురవుతుంది. వారు రిస్క్ తీసుకోవడం చాలా తక్కువ సౌకర్యంగా ఉంటారు. ఇది ఒక తరంలో నిజమైన మార్పు. ” కాబట్టి, మేము స్థితిస్థాపకత, ప్రేమ, ఆశ, సాంస్కృతిక గుర్తింపు, విమర్శనాత్మక స్పృహ, స్వీయ-నిర్ణయం మరియు పని మెరుగుదలల వ్యవసాయ మార్గాలను కలిగి ఉన్న ఆరోగ్యాన్ని కొలవడానికి మార్గాలను అభివృద్ధి చేస్తున్నాము.


                    </p>
                </div>
            </div>
            <div className="heightt">
                <h2 className="structuretit">Zha Transformation Method</h2>
                <img src="../img/height.png"></img>
            </div>
        </div>

    );
}