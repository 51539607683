import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process">English</NavLink></a>

        </div>

        

<h1 id="mhead">Zha Sustainable Certification Process</h1>
<p className="freecenter">“Free Certification with Zha Compliance”</p>
   
   <div className='second-mcontent'>
           <img src='../../img/certificate.jpg'></img>
       </div>


 

<section className="visionmi">
 
<p>ಬಿಲ್ಡರ್‌ಗಳು ಮತ್ತು ರಿಯಾಲ್ಟರ್‌ಗಳಿಗೆ ಪರಿಸರ ಸ್ನೇಹಿ ಪ್ರಮಾಣೀಕರಣವನ್ನು ಪಡೆಯಲು, ಈ ಸಾಮಾನ್ಯ ಹಂತಗಳನ್ನು ಅನುಸರಿಸಿ:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>ಸಂಶೋಧನೆ ಝಾ ಸ್ವೀಕೃತಿ ಕಾರ್ಯಕ್ರಮಗಳು : </span> ನಿರ್ಮಾಣ ಉದ್ಯಮಕ್ಕೆ ಅನ್ವಯವಾಗುವ ನಮ್ಮ ಪ್ರತಿಷ್ಠಿತ ಪರಿಸರ ಸ್ನೇಹಿ ಪ್ರಮಾಣೀಕರಣ ಕಾರ್ಯಕ್ರಮಗಳು, ಪರಿಸರ ಮೌಲ್ಯಮಾಪನ ಮಾನದಂಡಗಳ ಸ್ಥಾಪನೆಯ ಸಂಶೋಧನೆ

</li>

<li><span>ಅವಶ್ಯಕತೆಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಿ : </span>  ಆಯ್ಕೆಮಾಡಿದ ಪ್ರಮಾಣೀಕರಣದ ನಿರ್ದಿಷ್ಟ ಮಾನದಂಡಗಳು ಮತ್ತು ಅವಶ್ಯಕತೆಗಳೊಂದಿಗೆ ನೀವೇ ಪರಿಚಿತರಾಗಿರಿ. ಇದು ಸುಸ್ಥಿರ ಕಟ್ಟಡ ಸಾಮಗ್ರಿಗಳು, ಶಕ್ತಿಯ ದಕ್ಷತೆ, ನೀರಿನ ಸಂರಕ್ಷಣೆ ಮತ್ತು ಒಳಾಂಗಣ ಗಾಳಿಯ ಗುಣಮಟ್ಟವನ್ನು ಒಳಗೊಂಡಿರಬಹುದು.

</li>
<li><span>ತರಬೇತಿ ಮತ್ತು ಶಿಕ್ಷಣ : </span>  ನಿಮ್ಮ ತಂಡವು ಸುಸ್ಥಿರ ನಿರ್ಮಾಣ ಅಭ್ಯಾಸಗಳ ಬಗ್ಗೆ ಶಿಕ್ಷಣ ಪಡೆದಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ. ಕೆಲವು ಪ್ರಮಾಣೀಕರಣಗಳಿಗೆ ಪ್ರಮುಖ ಸಿಬ್ಬಂದಿಗೆ ಕೆಲವು ತರಬೇತಿ ಬೇಕಾಗಬಹುದು.

</li>
<li><span>ಸುಸ್ಥಿರ ಅಭ್ಯಾಸಗಳನ್ನು ಅಳವಡಿಸಿ : </span>  ನಿಮ್ಮ ನಿರ್ಮಾಣ ಪ್ರಕ್ರಿಯೆಗಳಲ್ಲಿ ಪರಿಸರ ಸ್ನೇಹಿ ಅಭ್ಯಾಸಗಳನ್ನು ಸಂಯೋಜಿಸಿ. ಇದು ಮರುಬಳಕೆಯ ವಸ್ತುಗಳನ್ನು ಬಳಸುವುದು, ಶಕ್ತಿಯ ಬಳಕೆಯನ್ನು ಉತ್ತಮಗೊಳಿಸುವುದು ಮತ್ತು ಹಸಿರು ಕಟ್ಟಡ ತಂತ್ರಗಳನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.

</li>
<li><span>ದಾಖಲೆ : </span> ನಿಮ್ಮ ಸಮರ್ಥನೀಯ ಪ್ರಯತ್ನಗಳ ಸಂಪೂರ್ಣ ದಾಖಲೆಗಳನ್ನು ಇರಿಸಿ. ಪ್ರಮಾಣೀಕರಣಕ್ಕಾಗಿ ದಾಖಲಾತಿಯು ನಿರ್ಣಾಯಕವಾಗಿದೆ, ಸ್ಥಾಪಿತ ಮಾನದಂಡಗಳ ಅನುಸರಣೆಯನ್ನು ಪ್ರದರ್ಶಿಸುತ್ತದೆ.

</li>
<li><span>ಪಾಲುದಾರರನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಿ : </span> ಪರಿಸರ ಸ್ನೇಹಿ ಉಪಕ್ರಮಗಳಲ್ಲಿ ಗುತ್ತಿಗೆದಾರರು, ಪೂರೈಕೆದಾರರು ಮತ್ತು ಕೆಲಸಗಾರರನ್ನು ಒಳಗೊಂಡಂತೆ ಎಲ್ಲಾ ಮಧ್ಯಸ್ಥಗಾರರನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಿ. ಪ್ರತಿಯೊಬ್ಬರೂ ಪ್ರಮಾಣೀಕರಣವನ್ನು ಸಾಧಿಸುವ ಗುರಿಯೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗಬೇಕು.

</li>
<li><span>ಪೂರ್ವ-ಮೌಲ್ಯಮಾಪನ : </span> ಅಧಿಕೃತ ಪ್ರಮಾಣೀಕರಣ ಪ್ರಕ್ರಿಯೆಯ ಮೊದಲು ಸುಧಾರಣೆಯ ಅಗತ್ಯವಿರುವ ಪ್ರದೇಶಗಳನ್ನು ಗುರುತಿಸಲು ಪೂರ್ವ-ಮೌಲ್ಯಮಾಪನವನ್ನು ಪರಿಗಣಿಸಿ. ಈ ಹಂತವು ಯಾವುದೇ ನ್ಯೂನತೆಗಳನ್ನು ಪರಿಹರಿಸಲು ಮತ್ತು ಯಶಸ್ಸಿನ ಸಾಧ್ಯತೆಗಳನ್ನು ಹೆಚ್ಚಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.

</li>
<li><span>ಔಪಚಾರಿಕ ಅರ್ಜಿ : </span> ಪ್ರಮಾಣೀಕರಣ ಸಂಸ್ಥೆಗೆ ಔಪಚಾರಿಕ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಿ. ಅಗತ್ಯವಿರುವ ಎಲ್ಲಾ ದಾಖಲೆಗಳು, ಪುರಾವೆಗಳು ಮತ್ತು ಶುಲ್ಕಗಳನ್ನು ಸೇರಿಸಿ.


</li>
<li><span>ಸೈಟ್ ತಪಾಸಣೆ : </span> ಪ್ರಮಾಣೀಕರಣ ಸಂಸ್ಥೆಯ ಪ್ರತಿನಿಧಿಗಳಿಂದ ಸೈಟ್ ಪರಿಶೀಲನೆಗಾಗಿ ತಯಾರಿ. ನಿಮ್ಮ ನಿರ್ಮಾಣ ಯೋಜನೆಯು ನಿರ್ದಿಷ್ಟಪಡಿಸಿದ ಪರಿಸರ ಸ್ನೇಹಿ ಮಾನದಂಡಗಳೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತದೆಯೇ ಎಂದು ಅವರು ನಿರ್ಣಯಿಸುತ್ತಾರೆ.

</li>
<li><span>ಪ್ರಮಾಣೀಕರಣದ ನಿರ್ಧಾರ : </span> ಪ್ರಮಾಣೀಕರಣದ ನಿರ್ಧಾರಕ್ಕಾಗಿ ನಿರೀಕ್ಷಿಸಿ. ಯಶಸ್ವಿಯಾದರೆ, ನಿಮ್ಮ ಪ್ರಾಜೆಕ್ಟ್‌ನ ಪರಿಸರ ಸ್ನೇಹಿ ಸ್ಥಿತಿಯ ಔಪಚಾರಿಕ ಮನ್ನಣೆಯನ್ನು ನೀವು ಸ್ವೀಕರಿಸುತ್ತೀರಿ.

</li>

<li><span>ಪ್ರಮಾಣೀಕರಣವನ್ನು ಉತ್ತೇಜಿಸಿ : </span> ಒಮ್ಮೆ ಪ್ರಮಾಣೀಕರಿಸಿದ ನಂತರ, ನಿಮ್ಮ ನಿರ್ಮಾಣ ಯೋಜನೆಗಳನ್ನು ಉತ್ತೇಜಿಸಲು ಪರಿಸರ ಸ್ನೇಹಿ ಲೇಬಲ್ ಅನ್ನು ಬಳಸಿ. ಇದು ನಿಮ್ಮ ಕಂಪನಿಯ ಖ್ಯಾತಿಯನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ ಮತ್ತು ಪರಿಸರ ಪ್ರಜ್ಞೆಯ ಗ್ರಾಹಕರನ್ನು ಆಕರ್ಷಿಸುತ್ತದೆ.
</li>

<li><span>ಪ್ರಚಾರಗಳಲ್ಲಿ ಸೇರಿಸಬಹುದು : </span> ಒಮ್ಮೆ ನೀವು ZHA ಪರಿಸರ ಸ್ನೇಹಿ ಪ್ರಮಾಣೀಕರಣದಲ್ಲಿ ZHA ಫೌಂಡೇಶನ್‌ನಿಂದ ದೃಢೀಕರಣ ಇಮೇಲ್ ಅನ್ನು ಸ್ವೀಕರಿಸಿದರೆ, ಬಿಲ್ಡರ್ ಅದನ್ನು ಅದರ ಪ್ರಚಾರ ಮತ್ತು ಜಾಹೀರಾತುಗಳಲ್ಲಿ ಪ್ರಕಟಿಸಬಹುದು. ZHA ಫೌಂಡೇಶನ್ ವೆಬ್‌ಸೈಟ್ ZHA ಪರಿಸರ ಸ್ನೇಹಿ ಸುಸ್ಥಿರತೆ ಕನ್‌ಸ್ಟ್ರಕ್ಟರ್‌ನಲ್ಲಿ ಪ್ರಮಾಣೀಕರಿಸಲ್ಪಟ್ಟ ಬಿಲ್ಡರ್‌ಗಳನ್ನು ಸಹ ಪಟ್ಟಿ ಮಾಡುತ್ತದೆ.</li>

</ol>



<p>ನೆನಪಿಡಿ, ಆಯ್ಕೆಮಾಡಿದ ಪ್ರಮಾಣೀಕರಣ ಕಾರ್ಯಕ್ರಮವನ್ನು ಅವಲಂಬಿಸಿ ನಿರ್ದಿಷ್ಟ ಹಂತಗಳು ಬದಲಾಗಬಹುದು, ಆದ್ದರಿಂದ ಆಯ್ಕೆಮಾಡಿದ ಪ್ರಮಾಣೀಕರಣ ಸಂಸ್ಥೆಯ ಅವಶ್ಯಕತೆಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಪರಿಶೀಲಿಸುವುದು ಅತ್ಯಗತ್ಯ. </p>


</section>
 

</div>
 
 
);
}

