import React from "react";
import "./Socialservices.css"
import { NavLink } from "react-router-dom";
export default function Socialservices(){

    return(

        <div>
            <div className="social-main">
            <div  id="language" >
        
        <a href=""><NavLink to="/Socialservices">English</NavLink></a>

        </div>
            <h1>Social Service</h1>
            </div>
            <div className="social-content">

        <div className="social-box">
        <img src="../../img/sust.png"></img>
        <h2>നിലത്വം
</h2>
        <p>
                                                                                            
        ഴ അറക്കത്തളൈ ഭാവി സന്താനങ്ങൾക്ക് ഗ്രഹത്തെ സംരക്ഷിക്കുന്നതിനുള്ള നിലത്വത്തിന് മുൻഗണന നൽകുന്നു. അവരുടെ ഊർജ്ജം, കൃഷി, ഗതാഗതം, നിർമ്മാണം എന്നിവയിൽ സ്ഥിരമായ പരിഹാരങ്ങൾ പ്രവർത്തിക്കുന്നു. സ്ഥായിത്വത്തിന്റെ ഉത്തരവാദിത്തമുള്ള വോള ഉപയോഗം, പുതുക്കാവുന്ന എനർജി, മാലിന്യ കുറവ്, പ്രകൃതിയുമായി യോജിപ്പിക്കൽ എന്നിവ പ്രോത്സാഹിപ്പിക്കുന്നു. ഈ ഴ അറക്കട്ടളായത്, ജനങ്ങളും ചുറ്റുപാടുകളും സന്തുലിതാവസ്ഥയിൽ വളരുന്ന സാഹചര്യം സൗഹൃദം ലോകത്തിനെ സൃഷ്ടിക്കുന്നത് ലക്ഷ്യമാക്കുന്നു. കൂടാതെ, ഗ്രാമപ്പുര, പുറനഗർ പ്രദേശങ്ങളിൽ തൊഴിലവസരങ്ങൾ സൃഷ്ടിക്കൽ. ബാരശയ്യയില്ലാത്ത ജീവിതവും ആരോഗ്യകരമായ സമൂഹവും രൂപപ്പെടുത്തൽ.
           

        </p>
        </div>
       
        <div className="social-box">
        <img src="../../img/property.png"></img>
        <h2>ആരോഗ്യ ക്യാമ്പുകൾ
</h2>
        <p>
        പ്രൈമറി ഹെൽത്ത് കെയർ ആക്സസ് ചെയ്യാനുള്ള അധഃസ്ഥിതരുടെ വെല്ലുവിളികളെ ഴ ഫൗണ്ടേഷൻ തിരിച്ചറിയുന്നു. അതിനാൽ, ഭൂമിശാസ്ത്രപരമായി ഞങ്ങൾ സൗജന്യ മൾട്ടി സ്പെഷ്യാലിറ്റി ഹെൽത്ത് ക്യാമ്പുകൾ സംഘടിപ്പിക്കുന്നു, അതിൽ ഒരു ഡോക്ടറുടെ സമഗ്രമായ പരിശോധനയും സൗജന്യ മരുന്നുകളുടെ വിതരണവും ബിപി, ബ്ലഡ് ഷുഗർ, ഹീമോഗ്ലോബിൻ (എച്ച്ബി) നിരീക്ഷണം എന്നീ പ്രത്യേക പരിശോധനകളും ഉൾപ്പെടുന്നു. ഈ സമയോചിതമായ ഇടപെടലുകൾ രോഗനിർണയവും ചികിത്സയും പ്രദാനം ചെയ്യുന്നു, രോഗാവസ്ഥയും മരണനിരക്കും കുറയ്ക്കുന്നു. പാർശ്വവൽക്കരിക്കപ്പെട്ട സമൂഹങ്ങളുടെ അടിയന്തര ആരോഗ്യ സംരക്ഷണ ആവശ്യങ്ങൾക്കാണ് ഇത്തരം ആരോഗ്യ ക്യാമ്പുകൾ അഭിസംബോധന ചെയ്യുന്നത്
            </p>
        </div>
        <div className="social-box">
        <img src="../../img/run.png"></img>
        <h2>യുവാക്കളുടെ ആരോഗ്യത്തിനായി കായിക വിനോദങ്ങൾ സുഗമമാക്കുക
 </h2>

        <p>
        സ്‌പോർട്‌സ് എല്ലാവരേയും, പ്രത്യേകിച്ച് കുട്ടികളും യുവാക്കളും ആരോഗ്യമുള്ളവരാകാനും കഴിവുകൾ വികസിപ്പിക്കാനും സുഹൃത്തുക്കളെ ഉണ്ടാക്കാനും വൈകാരിക ഉയർച്ച താഴ്ചകൾ നിയന്ത്രിക്കാനും സഹായിക്കുന്നു. ഒരു നല്ല മാതൃക, നല്ല മനോഭാവം, താൽപ്പര്യം എന്നിവ പ്രോത്സാഹിപ്പിക്കുന്നതിലൂടെ നിങ്ങളുടെ കുട്ടിയെ ഒരു നല്ല കായിക വിനോദമാക്കാൻ സഹായിക്കുക. ജയത്തിലും തോൽവിയിലും അല്ലാതെ പരിശ്രമം, പങ്കാളിത്തം, വിനോദം എന്നിവയിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുക. കുട്ടികൾക്ക് സ്പോർട്സ് കളിക്കാൻ താൽപ്പര്യമില്ലെങ്കിൽ, അവർക്ക് ശാരീരിക പ്രവർത്തനങ്ങൾക്കായി മറ്റ് നിരവധി ഓപ്ഷനുകൾ ഉണ്ട്. സബർബൻ, റൂറൽ സർക്കാർ സ്‌കൂൾ കുട്ടികളെ കായിക മത്സരങ്ങൾ നടത്തുന്നതിന് എല്ലാവരെയും സഹായിക്കുന്നതിന് ഴ വിവിധ മത്സരങ്ങൾ നടത്തും.
            </p>
        </div>
            </div>
            <div className="goals">
                <h1 >ഞങ്ങളുടെ  <sapn className="goalsspan">സുസ്ഥിര ലക്ഷ്യങ്ങൾ</sapn></h1>
                <div className="susgoal">
                 <div>
                    <img src="../../img/Midnight.png"></img>
                    </div>   
                    <p>
                <ul>
        <li> <span>ദാരിദ്ര്യമില്ല:</span> ദാരിദ്ര്യം അതിന്റെ എല്ലാ രൂപത്തിലും അവസാനിപ്പിക്കുകയും ദരിദ്രർക്കും ദുർബലർക്കും സാമൂഹിക സംരക്ഷണം ഉറപ്പാക്കുകയും ചെയ്യുക.</li>
        <li><span>സീറോ ഹംഗർ:</span>  ഭക്ഷ്യസുരക്ഷ കൈവരിക്കുക, പോഷകാഹാരം മെച്ചപ്പെടുത്തുക, സുസ്ഥിര കൃഷി പ്രോത്സാഹിപ്പിക്കുക.</li>
        <li><span>നല്ല ആരോഗ്യവും ക്ഷേമവും: </span> ആരോഗ്യകരമായ ജീവിതം ഉറപ്പാക്കുകയും എല്ലാ പ്രായത്തിലുമുള്ള എല്ലാവർക്കും ക്ഷേമം പ്രോത്സാഹിപ്പിക്കുകയും ചെയ്യുക..</li>
        <li> <span>ഗുണനിലവാരമുള്ള വിദ്യാഭ്യാസം:</span> എല്ലാവരേയും ഉൾക്കൊള്ളുന്നതും തുല്യവുമായ ഗുണനിലവാരമുള്ള വിദ്യാഭ്യാസം ഉറപ്പാക്കുകയും എല്ലാവർക്കും ആജീവനാന്ത പഠന അവസരങ്ങൾ പ്രോത്സാഹിപ്പിക്കുകയും ചെയ്യുക.</li>
        <li> <span>ലിംഗസമത്വം: </span> ലിംഗസമത്വം കൈവരിക്കുകയും എല്ലാ സ്ത്രീകളെയും പെൺകുട്ടികളെയും ശാക്തീകരിക്കുകയും ചെയ്യുക.
</li>
            </ul>
                </p>

                </div>
            </div>
        </div>
    
    );
}