import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability">English</NavLink></a>



</div>

            <div className="social-ma">

<h1>ஏன் நிலைத்தன்மை?</h1>
</div>


<div className="internship">
        <img src="../img/360.jpg"></img>
        </div>
<div className="goalpointsp">

<p>ழ அறக்கட்டளை போன்ற ஒரு நிலைத்தன்மை மேம்பாட்டுக் கழகத்தை அனைத்து நாடுகளுக்கும் விரிவுபடுத்துவது பல்வேறு சுற்றுச்சூழல் மற்றும் சமூக சவால்களை எதிர்கொள்வதன் மூலம் உலகளாவிய தாக்கத்தை அனுமதிக்கிறது. இது வளங்கள், அறிவு மற்றும் சிறந்த நடைமுறைகளைப் பகிர்வதற்கு உதவுகிறது, பரந்த அளவில் நிலையான வளர்ச்சியை நோக்கிய கூட்டு அணுகுமுறையை வளர்க்கிறது. கூடுதலாக, உலகளாவிய சிக்கல்களின் ஒன்றோடொன்று இணைக்கப்பட்ட தன்மை, மிகவும் நிலையான மற்றும் மீள்வகுப்பு உலகத்தை உருவாக்க பரவலான பங்கேற்பின் அவசியத்தை வலியுறுத்துகிறது.கீழே குறிப்பிடப்பட்டுள்ளபடி நிலைக்க முடியாத மனித வாழ்க்கைக்கான காரணங்கள். மனிதர்களுக்கான நிலையற்ற வாழ்க்கை முறைகள் பல ஒன்றோடொன்று இணைக்கப்பட்ட காரணிகளால் கூறப்படலாம்:
</p>


<ol className="goalpoints">
    <li><span>அதிகப்படியான மொபைல் போன் பயன்பாடு : </span>குழந்தைகள் மற்றும் பெற்றோர்கள் அதிகளவில் மொபைல் போன்களில் கணிசமான நேரத்தை செலவிடுகின்றனர், இது உடல் செயல்பாடு, வாசிப்பு பழக்கம் மற்றும் சமூக தொடர்பு ஆகியவற்றில் வீழ்ச்சிக்கு வழிவகுத்தது.</li>
    <li><span>வீடியோ கேம் அதிகமாகப் பயன்படுத்துதல் : </span>குறிப்பாக, குழந்தைகள் அதிக நேரத்தை வீடியோ கேம்களுக்குச் செலவிடுகிறார்கள், பெரும்பாலும் விளையாட்டுகளில் பங்கேற்பதற்கும், வாசிப்பதில் ஆர்வத்தை வளர்ப்பதற்கும் ஆகும்.</li>
    <li><span>சமூக நம்பிக்கை : </span>டிஜிட்டல் கவனச்சிதறல்கள் காரணமாக மக்களிடையே நேருக்கு நேர் தொடர்புகள் குறைவது நம்பிக்கை மற்றும் தனிப்பட்ட உறவுகளில் சரிவுக்கு பங்களித்து, நமது சமூக தொடர்புகளின் தரம் குறித்த கவலைகளை எழுப்புகிறது.</li>


    <li><span>அதிகப்படியான நுகர்வு மற்றும் பொருள்முதல்வாதம் : </span>வசதியான மற்றும் நிறைவான வாழ்க்கைக்கு தேவையானதை விட அதிகமான பொருள் உடைமைகள் மற்றும் வளங்களின் நுகர்வு ஆகியவை நிலையான வாழ்க்கை முறைகளுக்கு வழிவகுக்கும்.

</li>
    <li><span>வளம் குறைதல் : </span>புதைபடிவ எரிபொருள்கள், கனிமங்கள் மற்றும் நன்னீர் உள்ளிட்ட இயற்கை வளங்களை அதிகமாக சுரண்டுவது, அவற்றின் சிதைவுக்கு வழிவகுக்கும் மற்றும் சுற்றுச்சூழல் சீரழிவுக்கு பங்களிக்கும்.


</li>
    <li><span>கழிவு உருவாக்கம் : </span>திறமையற்ற வள பயன்பாடு மற்றும் முறையற்ற கழிவுகளை அகற்றுவது மாசுபாடு, நிலப்பரப்பு குவிப்பு மற்றும் சுற்றுச்சூழலுக்கு தீங்கு விளைவிக்கும் பொருட்களை வெளியிடுவதற்கு பங்களிக்கிறது.


</li>
    <li><span>அதிக ஆற்றல் நுகர்வு : </span>அதிக மின்சாரம், வெப்பமாக்கல் மற்றும் போக்குவரத்து போன்ற ஆற்றல்-தீவிர நடைமுறைகளை நம்பியிருப்பது, பசுமை இல்ல வாயு உமிழ்வு மற்றும் சுற்றுச்சூழல் அழுத்தத்தை அதிகரிக்க வழிவகுக்கும்.


</li>
    <li><span>போக்குவரத்து மற்றும் பயணப் பழக்கம் : </span>போக்குவரத்துக்கான தனிப்பட்ட வாகனங்களை நம்பியிருப்பது, குறிப்பாக திறமையான பொது போக்குவரத்து இல்லாத பகுதிகளில், காற்று மாசுபாடு, நெரிசல் மற்றும் புதைபடிவ எரிபொருள் நுகர்வுக்கு பங்களிக்கும்.
</li>
    <li><span>உணவு உற்பத்தி மற்றும் நுகர்வு : </span>ஒற்றைப்பயிர் விவசாயம், அதிகப்படியான இரசாயன உள்ளீடுகள் மற்றும் நீண்ட தூர உணவுப் போக்குவரத்து உள்ளிட்ட நிலையற்ற விவசாய நடைமுறைகள் சுற்றுச்சூழல் சீர்கேடு மற்றும் உணவுப் பாதுகாப்பு சவால்களுக்கு வழிவகுக்கும்.
</li>
    <li><span>காடழிப்பு மற்றும் வாழ்விட அழிவு : </span>விவசாயம், நகரமயமாக்கல் மற்றும் உள்கட்டமைப்பு மேம்பாட்டிற்காக நிலத்தை சுத்தம் செய்வது பல்லுயிர் இழப்பு, சுற்றுச்சூழல் அமைப்புகளின் சீர்குலைவு மற்றும் குறைக்கப்பட்ட கார்பன் சுரப்பு ஆகியவற்றிற்கு வழிவகுக்கும்.
</li>
<li><span>வாழ்க்கையின் வெற்றிகளைக் கொண்டாடுவது : </span>பிறர் உயிருடன் இருக்கும் போது அவர்களின் சாதனைகளைக் கொண்டாடுவதற்கு நாம் நேரம் ஒதுக்குவதில்லை.
</li>
<li><span>அனுதாபத்தை புறக்கணித்தல் : </span>பிறர் இழப்பு போன்றவற்றிற்காக நமது இரங்கலைப் பகிர்ந்து கொள்வதற்கு நேரம் ஒதுக்குவதில்லை</li>
    <li><span>அதிகப்படியான மீன்பிடித்தல் மற்றும் கடல்சார் சீரழிவு : </span>மிதமிஞ்சிய மீன்பிடித்தல் மற்றும் அழிவுகரமான மீன்பிடி முறைகள் உட்பட, நீடித்த மீன்பிடி முறைகள், மீன் வளங்களைக் குறைத்து, கடல் சுற்றுச்சூழல் அமைப்புகளுக்கு தீங்கு விளைவிக்கும்.

</li> 

<li><span>நிலையான உள்கட்டமைப்பு இல்லாமை : </span>போதுமான நகர்ப்புற திட்டமிடல், திறமையற்ற கட்டிட வடிவமைப்பு மற்றும் காலாவதியான உள்கட்டமைப்பு ஆகியவை வளங்களின் திறமையின்மை மற்றும் சுற்றுச்சூழல் திரிபுக்கு பங்களிக்கும்.
</li> 

<li><span>நுகர்வோர் நடத்தை மற்றும் தேர்வுகள் : </span>நிலையான அல்லாத பொருட்களை வாங்குவது போன்ற நுகர்வோர் செய்யும் தேர்வுகள், வளம் மிகுந்த மற்றும் சுற்றுச்சூழலுக்கு தீங்கு விளைவிக்கும் பொருட்கள் மற்றும் சேவைகளுக்கான தேவையை அதிகரிக்கும்.

</li> 

<li><span>சுற்றுச்சூழல் விழிப்புணர்வு மற்றும் கல்வி இல்லாமை : </span>சில நடத்தைகளால் சுற்றுச்சூழல் பாதிப்புகள் பற்றிய போதிய அறிவு மற்றும் புரிதல் தற்செயலாக நீடிக்க முடியாத நடைமுறைகளுக்கு வழிவகுக்கும்.
</li> 

<li><span>கொள்கை மற்றும் ஒழுங்குமுறை கட்டமைப்புகள் : </span>பலவீனமான அல்லது போதிய சுற்றுச்சூழல் கொள்கைகள், அமலாக்கமின்மை மற்றும் நீடிக்க முடியாத நடைமுறைகளை ஊக்குவிக்கும் மானியங்கள் ஆகியவை நீடித்த வாழ்க்கை முறைகளுக்கு பங்களிக்கும்.
</li> 
</ol>



<p>நிலையற்ற வாழ்க்கை முறைகளை நிவர்த்தி செய்வதற்கு, கல்வி, கொள்கை மாற்றங்கள், தொழில்நுட்ப கண்டுபிடிப்புகள் மற்றும் கலாச்சார மற்றும் சமூக நெறிமுறைகளில் மாற்றங்கள் உட்பட பன்முக அணுகுமுறை தேவைப்படுகிறது. நிலையான நடைமுறைகளை ஊக்குவித்தல், பொறுப்பான நுகர்வை ஊக்குவித்தல் மற்றும் சுற்றுச்சூழல் நட்பு தொழில்நுட்பங்களை ஏற்றுக்கொள்வது ஆகியவை மிகவும் நிலையான எதிர்காலத்தை நோக்கிய முக்கியமான படிகள்.</p>



</div>
        
     

        </div>

    );
}

