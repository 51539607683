import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability">English</NavLink></a>



</div>
            <div className="social-ma">

<h1>എന്തുകൊണ്ടാണ് സുസ്ഥിരത കാരണം?</h1>
</div>


<div className="internship">
        <img src="../img/360.jpg"></img>
        </div>
<div className="goalpointsp">

<p>ഴ ഫൗണ്ടേഷൻ പോലെയുള്ള ഒരു സുസ്ഥിര വികസന ക്ലബ്ബ് എല്ലാ രാജ്യങ്ങളിലേക്കും വ്യാപിപ്പിക്കുന്നത് വൈവിധ്യമാർന്ന പാരിസ്ഥിതികവും സാമൂഹികവുമായ വെല്ലുവിളികളെ അഭിസംബോധന ചെയ്യുന്നതിലൂടെ ആഗോള സ്വാധീനം സാധ്യമാക്കുന്നു. ഇത് വിഭവങ്ങൾ, അറിവ്, മികച്ച സമ്പ്രദായങ്ങൾ എന്നിവയുടെ പങ്കിടൽ പ്രാപ്തമാക്കുന്നു, വിശാലമായ തോതിലുള്ള സുസ്ഥിര വികസനത്തിന് ഒരു സഹകരണ സമീപനം പ്രോത്സാഹിപ്പിക്കുന്നു. കൂടാതെ, ആഗോള പ്രശ്‌നങ്ങളുടെ പരസ്പരബന്ധിതമായ സ്വഭാവം കൂടുതൽ സുസ്ഥിരവും പ്രതിരോധശേഷിയുള്ളതുമായ ഒരു ലോകം സൃഷ്ടിക്കുന്നതിന് വ്യാപകമായ പങ്കാളിത്തത്തിന്റെ ആവശ്യകതയെ ഊന്നിപ്പറയുന്നു.സുസ്ഥിരമല്ലാത്ത മനുഷ്യജീവിതത്തിന്റെ കാരണങ്ങൾ താഴെ സൂചിപ്പിച്ചിരിക്കുന്നു. മനുഷ്യർക്ക് സുസ്ഥിരമല്ലാത്ത ജീവിതശൈലി പരസ്പരബന്ധിതമായ നിരവധി ഘടകങ്ങളാൽ ആരോപിക്കപ്പെടാം:
</p>


<ol className="goalpoints">
    <li><span>അമിതമായ മൊബൈൽ ഫോൺ ഉപയോഗം : </span>കുട്ടികളും രക്ഷിതാക്കളും ഗണ്യമായ സമയം മൊബൈൽ ഫോണുകളിൽ ചെലവഴിക്കുന്നു, ഇത് ശാരീരിക പ്രവർത്തനങ്ങൾ, വായനാശീലം, സാമൂഹിക ഇടപെടലുകൾ എന്നിവയിൽ കുറവുണ്ടാക്കുന്നു.</li>
    <li><span>വീഡിയോ ഗെയിം അമിതോപയോഗം : </span>കുട്ടികൾ, പ്രത്യേകിച്ച്, സ്പോർട്സിൽ പങ്കെടുക്കുന്നതിനും വായനയോടുള്ള ഇഷ്ടം വളർത്തുന്നതിനുമുള്ള ചെലവിൽ, വീഡിയോ ഗെയിമുകൾക്കായി അമിതമായ മണിക്കൂറുകൾ ചെലവഴിക്കുന്നു.</li>
    <li><span>സാമൂഹിക വിശ്വാസത്തിന്റെ ശോഷണം : </span>ഡിജിറ്റൽ ശ്രദ്ധാകേന്ദ്രങ്ങൾ കാരണം ആളുകൾ തമ്മിലുള്ള മുഖാമുഖ ഇടപെടലുകൾ കുറയുന്നത് വിശ്വാസത്തിലും വ്യക്തിബന്ധങ്ങളിലും കുറവുണ്ടാക്കുകയും നമ്മുടെ സാമൂഹിക ബന്ധങ്ങളുടെ ഗുണനിലവാരത്തെക്കുറിച്ചുള്ള ആശങ്കകൾ ഉയർത്തുകയും ചെയ്യുന്നു.</li>


    <li><span>അമിതമായ ഉപഭോഗവും ഭൗതികവാദവും : </span>സുഖകരവും സംതൃപ്തവുമായ ജീവിതത്തിന് ആവശ്യമായതിലും കവിഞ്ഞ അമിതമായ ഭൗതിക സ്വത്തുക്കൾ പിന്തുടരുന്നതും വിഭവങ്ങളുടെ ഉപഭോഗവും സുസ്ഥിരമല്ലാത്ത ജീവിതശൈലികളിലേക്ക് നയിച്ചേക്കാം.
</li>
    <li><span>വിഭവശോഷണം : </span>ഫോസിൽ ഇന്ധനങ്ങൾ, ധാതുക്കൾ, ശുദ്ധജലം എന്നിവയുൾപ്പെടെയുള്ള പ്രകൃതിവിഭവങ്ങളുടെ അമിതമായ ചൂഷണം അവയുടെ ശോഷണത്തിനും പാരിസ്ഥിതിക തകർച്ചയ്ക്കും കാരണമാകും.


</li>
    <li><span>മാലിന്യ ഉൽപ്പാദനം : </span>കാര്യക്ഷമമല്ലാത്ത വിഭവങ്ങളുടെ ഉപയോഗവും അനുചിതമായ മാലിന്യ നിർമാർജനവും മലിനീകരണം, മാലിന്യക്കൂമ്പാരം, പരിസ്ഥിതിയിലേക്ക് ദോഷകരമായ വസ്തുക്കൾ പുറത്തുവിടൽ എന്നിവയ്ക്ക് കാരണമാകുന്നു.


</li>
    <li><span>ഉയർന്ന ഊർജ്ജ ഉപഭോഗം : </span>വൈദ്യുതിയുടെ അമിതമായ ഉപയോഗം, ചൂടാക്കൽ, ഗതാഗതം എന്നിവ പോലുള്ള ഊർജ്ജ-തീവ്രമായ സമ്പ്രദായങ്ങളെ ആശ്രയിക്കുന്നത് ഹരിതഗൃഹ വാതക ഉദ്‌വമനം വർദ്ധിപ്പിക്കുന്നതിനും പാരിസ്ഥിതിക സമ്മർദ്ദത്തിനും ഇടയാക്കും.


</li>
    <li><span>ഗതാഗതവും യാത്രാ ശീലങ്ങളും : </span>ഗതാഗതത്തിനായി വ്യക്തിഗത വാഹനങ്ങളെ ആശ്രയിക്കുന്നത്, പ്രത്യേകിച്ച് കാര്യക്ഷമമായ പൊതുഗതാഗതം ഇല്ലാത്ത പ്രദേശങ്ങളിൽ, വായു മലിനീകരണം, തിരക്ക്, ഫോസിൽ ഇന്ധന ഉപഭോഗം എന്നിവയ്ക്ക് കാരണമാകും.
</li>
    <li><span>ഭക്ഷ്യോൽപ്പാദനവും ഉപഭോഗവും : </span>ഏകവിള കൃഷി, രാസവസ്തുക്കളുടെ അമിതമായ ഉപയോഗം, ഭക്ഷ്യവസ്തുക്കളുടെ ദീർഘദൂര ഗതാഗതം എന്നിവ ഉൾപ്പെടെയുള്ള സുസ്ഥിരമല്ലാത്ത കാർഷിക രീതികൾ പരിസ്ഥിതി നശീകരണത്തിനും ഭക്ഷ്യസുരക്ഷാ വെല്ലുവിളികൾക്കും ഇടയാക്കും.
</li>
    <li><span>വനനശീകരണവും ആവാസവ്യവസ്ഥയുടെ നാശവും : </span>കൃഷി, നഗരവൽക്കരണം, അടിസ്ഥാന സൗകര്യ വികസനം എന്നിവയ്ക്കായി ഭൂമി വൃത്തിയാക്കുന്നത് ജൈവവൈവിധ്യത്തിന്റെ നഷ്ടത്തിനും ആവാസവ്യവസ്ഥയുടെ തകർച്ചയ്ക്കും കാർബൺ വേർതിരിക്കൽ കുറയുന്നതിനും ഇടയാക്കും.

</li>
<li><span>ജീവിത വിജയങ്ങൾ ആഘോഷിക്കുന്നു : </span>മറ്റുള്ളവർ ജീവിച്ചിരിക്കുമ്പോൾ അവരുടെ നേട്ടങ്ങൾ ആഘോഷിക്കാൻ ഞങ്ങൾ സമയം കണ്ടെത്താറില്ല.
</li>
<li><span>സഹതാപം അവഗണിക്കൽ : </span>മറ്റുള്ളവരുടെ നഷ്ടം സംബന്ധിച്ച ഞങ്ങളുടെ അനുശോചനം പങ്കിടാൻ ഞങ്ങൾ സമയം കണ്ടെത്തുന്നില്ല</li>
    <li><span>അമിത മത്സ്യബന്ധനവും കടൽ നശീകരണവും : </span>അമിത മത്സ്യബന്ധനവും വിനാശകരമായ മത്സ്യബന്ധന രീതികളും ഉൾപ്പെടെയുള്ള സുസ്ഥിരമല്ലാത്ത മത്സ്യബന്ധന രീതികൾ മത്സ്യസമ്പത്തിനെ ഇല്ലാതാക്കുകയും സമുദ്ര ആവാസവ്യവസ്ഥയെ ദോഷകരമായി ബാധിക്കുകയും ചെയ്യും.
</li> 

<li><span>സുസ്ഥിര അടിസ്ഥാന സൗകര്യങ്ങളുടെ അഭാവം : </span>അപര്യാപ്തമായ നഗര ആസൂത്രണം, കാര്യക്ഷമമല്ലാത്ത കെട്ടിട രൂപകല്പനകൾ, കാലഹരണപ്പെട്ട അടിസ്ഥാന സൗകര്യങ്ങൾ എന്നിവ വിഭവങ്ങളുടെ കാര്യക്ഷമതയില്ലായ്മയ്ക്കും പാരിസ്ഥിതിക സമ്മർദ്ദത്തിനും കാരണമാകും.
</li> 

<li><span>ഉപഭോക്തൃ പെരുമാറ്റവും തിരഞ്ഞെടുപ്പുകളും : </span>സുസ്ഥിരമല്ലാത്ത ഉൽപ്പന്നങ്ങൾ വാങ്ങുന്നത് പോലെയുള്ള ഉപഭോക്താക്കൾ നടത്തുന്ന തിരഞ്ഞെടുപ്പുകൾ, വിഭവസാന്ദ്രതയുള്ളതും പരിസ്ഥിതിക്ക് ഹാനികരവുമായ ചരക്കുകളുടെയും സേവനങ്ങളുടെയും ആവശ്യം വർദ്ധിപ്പിക്കും.

</li> 

<li><span>പാരിസ്ഥിതിക അവബോധത്തിന്റെയും വിദ്യാഭ്യാസത്തിന്റെയും അഭാവം : </span>ചില സ്വഭാവങ്ങളുടെ പാരിസ്ഥിതിക ആഘാതങ്ങളെക്കുറിച്ചുള്ള അപര്യാപ്തമായ അറിവും ധാരണയും മനഃപൂർവമല്ലാത്ത സുസ്ഥിരമല്ലാത്ത സമ്പ്രദായങ്ങളിലേക്ക് നയിച്ചേക്കാം.
</li> 

<li><span>നയവും നിയന്ത്രണ ചട്ടക്കൂടുകളും : </span>ദുർബലമായ അല്ലെങ്കിൽ അപര്യാപ്തമായ പാരിസ്ഥിതിക നയങ്ങൾ, നടപ്പാക്കലിന്റെ അഭാവം, സുസ്ഥിരമല്ലാത്ത സമ്പ്രദായങ്ങളെ പ്രോത്സാഹിപ്പിക്കുന്ന സബ്‌സിഡികൾ എന്നിവ സുസ്ഥിരമല്ലാത്ത ജീവിതശൈലിക്ക് കാരണമാകും.
</li> 
</ol>



<p>സുസ്ഥിരമല്ലാത്ത ജീവിതരീതികളെ അഭിസംബോധന ചെയ്യുന്നതിന് വിദ്യാഭ്യാസം, നയ മാറ്റങ്ങൾ, സാങ്കേതിക നവീകരണം, സാംസ്കാരികവും സാമൂഹികവുമായ മാനദണ്ഡങ്ങളിലെ വ്യതിയാനങ്ങൾ എന്നിവയുൾപ്പെടെ ഒരു ബഹുമുഖ സമീപനം ആവശ്യമാണ്. സുസ്ഥിരമായ സമ്പ്രദായങ്ങൾ പ്രോത്സാഹിപ്പിക്കുക, ഉത്തരവാദിത്ത ഉപഭോഗം പ്രോത്സാഹിപ്പിക്കുക, പരിസ്ഥിതി സൗഹൃദ സാങ്കേതികവിദ്യകൾ സ്വീകരിക്കുക എന്നിവയെല്ലാം കൂടുതൽ സുസ്ഥിരമായ ഭാവിയിലേക്കുള്ള പ്രധാന ചുവടുകളാണ്.</p>



</div>
        
     

        </div>

    );
}

