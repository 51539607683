import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Summit.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Summit-2024">English</NavLink></a>
         
        </div>
             {/* <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>உலகளாவிய நிலைத்தன்மை உச்சிமாநாடு: "மனிதகுலம் மற்றும் கிரக பூமிக்கான நல்லிணக்கம்"
</h1>


<div className="internship">
        <img src="../img/summit2.jpg"></img>
        </div>
        
<p>உலகளாவிய நிலைத்தன்மை உச்சி மாநாடு மனித ஆரோக்கியம், பூமி ஆரோக்கியம், இயற்கை ஆரோக்கியம் மற்றும் அதிகரிக்கும் லாபத் தூண் ஆகியவற்றின் நிலைத்தன்மைத் தூண்களை நிவர்த்தி செய்ய உலகெங்கிலும் உள்ள சிந்தனைத் தலைவர்கள், நிபுணர்கள் மற்றும் ஆர்வலர்களை ஒன்றிணைக்கும். இந்த உச்சிமாநாடு மனிதகுலம் மற்றும் கிரகம் ஆகிய இரண்டிற்கும் நிலையான மற்றும் இணக்கமான எதிர்காலத்தை உருவாக்க ஒத்துழைப்பு மற்றும் அறிவு-பகிர்வு ஆகியவற்றை வளர்ப்பதை நோக்கமாகக் கொண்டுள்ளது.
</p>
</div>


<div className="goalpointsp">

<h4>மையக்கருத்து :
</h4>


<ol className="goalpoints">
    <li><span>மனித ஆரோக்கிய தூண் : </span>
    <ul>
        <li>தனிநபர்களுக்கான முழுமையான ஆரோக்கியம் மற்றும் நல்வாழ்வை மேம்படுத்துதல், உடல், மன மற்றும் உணர்ச்சி அம்சங்களை உள்ளடக்கியது.
    </li>

    <li>சுத்தமான நீர், சத்தான உணவு, சுகாதாரம், கல்வி மற்றும் அனைவருக்கும் சமமான வாய்ப்புகள் ஆகியவற்றை அணுகுதல்.
    </li>
    </ul>
    </li>

    <li><span>பூமி ஆரோக்கிய தூண் : </span>
    <ul>
        <li>சுற்றுச்சூழலைப் பாதுகாப்பதற்கும் மீட்டெடுப்பதற்கும் நிலையான நடைமுறைகளில் கவனம் செலுத்துதல், காலநிலை மாற்றத்தை எதிர்ப்பதற்கான முயற்சிகள், இயற்கை வளங்களைப் பாதுகாத்தல் மற்றும் சுத்தமான எரிசக்தி தீர்வுகளை மேம்படுத்துதல்.

    </li>

    <li>பசுமை தொழில்நுட்பம் மற்றும் நிலையான உள்கட்டமைப்பில் புதுமைகளை முன்னிலைப்படுத்துதல்.
    </li>
    </ul>
    </li>


    <li><span>இயற்கை ஆரோக்கிய தூண் :
 : </span>
    <ul>
        <li>பல்லுயிர் பாதுகாப்பு, சுற்றுச்சூழல் பாதுகாப்பு மற்றும் இயற்கை வாழ்விடங்களை மீட்டெடுப்பதற்காக வாதிடுகிறது.

    </li>

    <li>மனித நல்வாழ்விற்கும் இயற்கை உலகின் ஆரோக்கியத்திற்கும் உள்ள உள்ளார்ந்த தொடர்பை ஆராய்தல்.
    </li>
    </ul>
    </li>

    <li><span>அதிகரிக்கும் லாபத் தூண் : </span>
    <ul>
        <li>உற்பத்தியாளர்கள், உற்பத்தியாளர்கள் மற்றும் நுகர்வோருக்கு லாபத்தைத் தக்கவைக்க, பின்வரும் உத்திகளைச் செயல்படுத்துவதைக் கவனியுங்கள்:
    </li>
    </ul>
    </li>


    <ol className="goalpoints">

<li><span>செலவு திறன் : </span>  
<ul>
    <li>உற்பத்தியாளர்கள் மற்றும் உற்பத்தியாளர்கள் செலவு குறைந்த மற்றும் நிலையான நடைமுறைகளை பின்பற்ற ஊக்குவிக்கவும்.
</li>
    <li> உற்பத்தி செயல்முறைகளில் செயல்திறனை மேம்படுத்தும் தொழில்நுட்பங்களில் முதலீடு செய்யுங்கள்.
</li>
</ul>
</li> 

<li><span>சப்ளை செயின் ஆப்டிமைசேஷன் : </span>  
<ul>
    <li>செலவினங்களைக் குறைப்பதற்கும் கழிவுகளைக் குறைப்பதற்கும் விநியோகச் சங்கிலிகளை ஒழுங்குபடுத்துங்கள்.
</li>
    <li>நிலையான மற்றும் நெறிமுறை ஆதார நடைமுறைகளை செயல்படுத்தவும்.

</li>
</ul>
</li> 

<li><span>சந்தை பல்வகைப்படுத்தல் : </span>  
<ul>
    <li>ஒரே சந்தையை சார்ந்திருப்பதை குறைக்க புதிய சந்தைகள் மற்றும் விநியோக சேனல்களை ஆராயுங்கள்.

</li>
    <li> சந்தை அணுகலை அதிகரிக்க கூட்டாண்மை மற்றும் ஒத்துழைப்புகளை வளர்க்கவும்.
</li>
</ul>
</li> 

<li><span>தயாரிப்பு புதுமை : </span>  
<ul>
    <li>நுகர்வோர் தேவைகளைப் பூர்த்தி செய்யும் புதுமையான தயாரிப்புகளுக்கான ஆராய்ச்சி மற்றும் மேம்பாட்டில் முதலீடு செய்யுங்கள்.
</li>
    <li>நிலையான மற்றும் சூழல் நட்பு தயாரிப்பு மேம்பாட்டை வலியுறுத்துங்கள்.

</li>
</ul>
</li> 

<li><span>நுகர்வோர் கல்வி : </span>  
<ul>
    <li>நிலையான பொருட்களின் மதிப்பு மற்றும் நன்மைகள் பற்றி நுகர்வோருக்குக் கற்பித்தல்.

</li>
    <li>தரம் மற்றும் நிலைத்தன்மையை மதிப்பிடும் நுகர்வோர் மனநிலையை வளர்க்கவும்.


</li>
</ul>
</li> 


<li><span>நியாயமான விலை : </span>  
<ul>
    <li>விநியோகச் சங்கிலியில் நியாயமான விலை நிர்ணய நடைமுறைகளை உறுதிசெய்து, உற்பத்தியாளர்களுக்கும் நுகர்வோருக்கும் பயனளிக்கும்.
</li>
    <li>நுகர்வோருடன் நம்பிக்கையை வளர்க்க விலை நிர்ணயத்தில் வெளிப்படைத்தன்மையை ஊக்குவிக்கவும்.

</li>
</ul>
</li> 


<li><span>தொழில்நுட்ப ஒருங்கிணைப்பு : </span>  
<ul>
    <li>உற்பத்தியை மேம்படுத்தவும், கழிவுகளை குறைக்கவும், ஒட்டுமொத்த செயல்திறனை அதிகரிக்கவும் தொழில்நுட்பத்தை தழுவுங்கள்.
</li>
    <li>பரந்த நுகர்வோர் தளத்தை அடைய இ-காமர்ஸ் தீர்வுகளை செயல்படுத்தவும்.
</li>
</ul>
</li> 


<li><span>தர உத்தரவாதம் : </span>  
<ul>
    <li>நிலையான மற்றும் நம்பகமான தயாரிப்புகளை வழங்க, தரக் கட்டுப்பாட்டு நடவடிக்கைகளுக்கு முன்னுரிமை கொடுங்கள்.
</li>
    <li>உயர்தர மற்றும் நிலையான தயாரிப்புகளுக்கு நற்பெயரை உருவாக்குங்கள்.

</li>
</ul>
</li> 

<li><span>வாடிக்கையாளரை ஈடுபடுத்துதல் : </span>  
<ul>
    <li>நுகர்வோரின் தேவைகள் மற்றும் விருப்பங்களைப் புரிந்துகொள்வதற்காக நேரடி தொடர்பு சேனல்களை நிறுவுதல்.
</li>
    <li> தயாரிப்புகளை தொடர்ந்து மேம்படுத்த வாடிக்கையாளர் கருத்து வழிமுறைகளை செயல்படுத்தவும்.
</li>
</ul>
</li> 


<li><span>ஒழுங்குமுறை இணக்கம் : </span>  
<ul>
    <li>நிலைத்தன்மை மற்றும் தயாரிப்பு தரநிலைகள் தொடர்பான வளர்ச்சியடைந்து வரும் ஒழுங்குமுறைகளுக்கு தகவல் மற்றும் இணக்கத்துடன் இருங்கள்.
</li>
    <li>நியாயமான மற்றும் ஆதரவான கொள்கைகளின் வளர்ச்சிக்கு பங்களிக்க கட்டுப்பாட்டாளர்களுடன் முன்கூட்டியே ஈடுபடுங்கள்.
</li>
</ul>
</li> 

</ol>
   
   </ol>

   <h4>முக்கிய அமர்வுகள் : </h4>

    <ol className="goalpoints">
   
    <li><span>முழுமையான ஆரோக்கியம் மற்றும் நல்வாழ்வு:</span> ஊட்டச்சத்து, மன ஆரோக்கியம் மற்றும் சமூக ஈடுபாடு பற்றிய விவாதங்கள் உட்பட உடல், மன மற்றும் உணர்ச்சி ஆரோக்கியத்தை மேம்படுத்துவதற்கான அணுகுமுறைகளை ஆராய்தல்.
</li>
    <li><span>காலநிலை நடவடிக்கை மற்றும் சுற்றுச்சூழல் பொறுப்புணர்வு:</span> காலநிலை மாற்றத்தை எதிர்த்துப் போராடுவதற்கும், மாசுபாட்டைக் குறைப்பதற்கும் மற்றும் நிலையான வள மேலாண்மையை மேம்படுத்துவதற்கும் உத்திகளைக் கையாளுதல்.
</li>


    <li><span>பல்லுயிர் பாதுகாப்பு மற்றும் சூழலியல் மறுசீரமைப்பு : </span>இயற்கை சுற்றுச்சூழல் அமைப்புகளைப் பாதுகாப்பதற்கும் மீட்டெடுப்பதற்கும் முயற்சிகளை ஆய்வு செய்தல் மற்றும் ஒரு சமநிலையான கிரகத்திற்கான பல்லுயிர்களைப் பாதுகாப்பதன் முக்கியத்துவம்.

</li>
    <li><span>நிலையான வளர்ச்சி மற்றும் சமத்துவ அணுகல் : </span>அனைத்து சமூகங்களும் அடிப்படைத் தேவைகள், கல்வி மற்றும் வளர்ச்சிக்கான வாய்ப்புகளை அணுகுவதை உறுதி செய்வதற்கான கொள்கைகள் மற்றும் முன்முயற்சிகளைப் பற்றி விவாதித்தல்.


</li>
    <li><span>பசுமையான எதிர்காலத்திற்கான புதுமை : </span>புதுப்பிக்கத்தக்க எரிசக்தி, நிலையான விவசாயம் மற்றும் சுற்றுச்சூழல் நட்பு உள்கட்டமைப்பு ஆகியவற்றில் புதிய தொழில்நுட்பங்கள் மற்றும் கண்டுபிடிப்புகளை காட்சிப்படுத்துதல்.


</li>
    <li><span>மாற்றத்திற்கான வக்கீல் மற்றும் செயல்பாடு : </span>நிலையான நடைமுறைகள் மற்றும் நேர்மறை மாற்றத்தின் முகவர்களாக மாறுவதற்கு தனிநபர்கள் மற்றும் சமூகங்களுக்கு அதிகாரமளித்தல்.

</li>
</ol>


<h4>முடிவுகள் : </h4>

    <ol className="goalpoints">

    <li><span>கூட்டுத் தீர்வுகள்: </span>உலகளாவிய நிலைத்தன்மை சவால்களை எதிர்கொள்ள சர்வதேச கூட்டாண்மை மற்றும் ஒத்துழைப்புகளை வளர்ப்பது.

</li>
    <li><span>கொள்கைப் பரிந்துரைகள் : </span>உள்ளூர், தேசிய மற்றும் உலக அளவில் நிலையான நடைமுறைகளை மேம்படுத்துவதற்கு நடவடிக்கை எடுக்கக் கூடிய கொள்கைப் பரிந்துரைகளை உருவாக்கி பகிர்ந்து கொள்ளுங்கள்.

</li>
    <li><span>செயலை ஊக்குவிக்கவும் : </span>மிகவும் நிலையான மற்றும் சமநிலையான எதிர்காலத்தை அடைவதற்கு உறுதியான நடவடிக்கைகளை எடுக்க தனிநபர்கள், வணிகங்கள் மற்றும் அரசாங்கங்களை ஊக்குவிக்கவும்.
</li>




</ol>






<p>இந்த மூன்று தூண்களில் கவனம் செலுத்துவதன் மூலம், உலகளாவிய நிலைத்தன்மை உச்சிமாநாடு அர்த்தமுள்ள உரையாடல், அறிவு பரிமாற்றம் மற்றும் அனைத்து உயிரினங்களுக்கும் மிகவும் நிலையான மற்றும் இணக்கமான உலகத்தை நோக்கி கூட்டு நடவடிக்கைக்கான தளத்தை உருவாக்குவதை நோக்கமாகக் கொண்டுள்ளது.</p>




{/*        

     
<h4> Increasing Profit Pillar

 </h4>

        <p>To sustain profitability for producers, manufacturers, and consumers, consider implementing the following strategies:

</p>
 
     


<ol className="goalpoints">

<li><span>Cost Efficiency : </span>  
<ul>
    <li>Encourage producers and manufacturers to adopt cost-effective and sustainable practices.
</li>
    <li> Invest in technologies that enhance efficiency in production processes.
</li>
</ul>
</li> 

<li><span>Supply Chain Optimization : </span>  
<ul>
    <li>Streamline supply chains to reduce costs and minimize waste.

</li>
    <li>Implement sustainable and ethical sourcing practices.

</li>
</ul>
</li> 

<li><span>Market Diversification : </span>  
<ul>
    <li>Explore new markets and distribution channels to reduce dependence on a single market.

</li>
    <li> Foster partnerships and collaborations to enhance market reach.

</li>
</ul>
</li> 

<li><span>Product Innovation : </span>  
<ul>
    <li>Invest in research and development for innovative products that meet consumer demands.

</li>
    <li>Emphasize sustainable and eco-friendly product development.

</li>
</ul>
</li> 

<li><span>Consumer Education : </span>  
<ul>
    <li>Educate consumers about the value and benefits of sustainable products.

</li>
    <li>Foster a consumer mindset that values quality and sustainability.


</li>
</ul>
</li> 


<li><span>Fair Pricing : </span>  
<ul>
    <li>Ensure fair pricing practices along the supply chain, benefiting both producers and consumers.

</li>
    <li>Promote transparency in pricing to build trust with consumers.

</li>
</ul>
</li> 


<li><span>Technology Integration : </span>  
<ul>
    <li>Embrace technology to optimize production, reduce waste, and enhance overall efficiency.

</li>
    <li>Implement e-commerce solutions to reach a broader consumer base.

</li>
</ul>
</li> 


<li><span>Quality Assurance : </span>  
<ul>
    <li>Prioritize quality control measures to deliver consistent and reliable products.

</li>
    <li>Build a reputation for high-quality and sustainable products.

</li>
</ul>
</li> 

<li><span>Customer Engagement : </span>  
<ul>
    <li>Establish direct communication channels with consumers to understand their needs and preferences.

</li>
    <li> Implement customer feedback mechanisms to continuously improve products.
</li>
</ul>
</li> 


<li><span>Regulatory Compliance : </span>  
<ul>
    <li>Stay informed and compliant with evolving regulations related to sustainability and product standards.

</li>
    <li>Proactively engage with regulators to contribute to the development of fair and supportive policies.

</li>
</ul>
</li> 

</ol>

<p>By integrating these pillars, you can create a holistic approach to sustaining profitability across the entire value chain, ensuring that producers, manufacturers, and consumers benefit from economically viable and environmentally conscious practices.
</p>

 
       
       
        */}
       
        </div>


        




        </div>
        


    );
}

