import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">




            <div  id="language" >
        
        <a href=""><NavLink to="/Environment">English</NavLink></a>

        </div>

        
                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">ಝಾ ಫೌಂಡೇಶನ್: ನರ್ಚುರಿಂಗ್ ಎ ಗ್ರೀನರ್ ಟುಮಾರೊ</h1>

                <div className='second-mcontent'>
                    <img src='../../img/Zhavent/3.jpg'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>ಝಾ ಫೌಂಡೇಶನ್‌ಗೆ ಸುಸ್ವಾಗತ, ಅಲ್ಲಿ ನಾವು ಪ್ರಸ್ತುತ ಮತ್ತು ಭವಿಷ್ಯದ ಪೀಳಿಗೆಗೆ ಪರಿಸರವನ್ನು ರಕ್ಷಿಸಲು ಉತ್ಸುಕರಾಗಿದ್ದೇವೆ. ಪರಿಸರ ಸಂರಕ್ಷಣೆಗೆ ನಮ್ಮ ಬದ್ಧತೆಯು ನಾವು ಮನೆ ಎಂದು ಕರೆಯುವ ಗ್ರಹವನ್ನು ಸಂರಕ್ಷಿಸುವಲ್ಲಿ ಪ್ರತಿಯೊಬ್ಬ ವ್ಯಕ್ತಿ ಮತ್ತು ಸಮುದಾಯವು ನಿರ್ಣಾಯಕ ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತದೆ ಎಂಬ ನಂಬಿಕೆಯಿಂದ ನಡೆಸಲ್ಪಡುತ್ತದೆ.
                    </p>
                </div>
                
                <h3>ನಮ್ಮ ದೃಷ್ಟಿ</h3>
                <div className="missi">
                    <h4 className="toleftneed">ಸುಸ್ಥಿರ ಭವಿಷ್ಯ:</h4>
                    <p>ಝಾ ಫೌಂಡೇಶನ್‌ನಲ್ಲಿ, ಮಾನವೀಯತೆಯು ಪ್ರಕೃತಿಯೊಂದಿಗೆ ಸಾಮರಸ್ಯದಿಂದ ಸಹಬಾಳ್ವೆ ನಡೆಸುವ ಜಗತ್ತನ್ನು ನಾವು ರೂಪಿಸುತ್ತೇವೆ. ನಮ್ಮ ದೃಷ್ಟಿ ಸುಸ್ಥಿರತೆಯಲ್ಲಿ ಬೇರೂರಿದೆ, ಅಲ್ಲಿ ಪರಿಸರ ವ್ಯವಸ್ಥೆಗಳು ಅಭಿವೃದ್ಧಿ ಹೊಂದುತ್ತವೆ, ಜೀವವೈವಿಧ್ಯವನ್ನು ರಕ್ಷಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಪ್ರತಿ ಕ್ರಿಯೆಯು ಆರೋಗ್ಯಕರ ಗ್ರಹಕ್ಕೆ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ.</p>


                </div>


                <h3>ನಮ್ಮ ಮಿಷನ್</h3>
                <div className="missi">
                    <h4 className="toleftneed">ಸಬಲೀಕರಣ ಬದಲಾವಣೆ:</h4>
                    <p>ಪರಿಸರದ ಮೇಲ್ವಿಚಾರಕರಾಗಲು ವ್ಯಕ್ತಿಗಳು ಮತ್ತು ಸಮುದಾಯಗಳಿಗೆ ಅಧಿಕಾರ ನೀಡುವುದು ನಮ್ಮ ಧ್ಯೇಯವಾಗಿದೆ. ಶಿಕ್ಷಣ, ವಕಾಲತ್ತು ಮತ್ತು ಪ್ರಾಯೋಗಿಕ ಉಪಕ್ರಮಗಳ ಮೂಲಕ, ನಾವು ಜವಾಬ್ದಾರಿಯುತ ಮತ್ತು ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳಿಗೆ ಸಾಮೂಹಿಕ ಬದ್ಧತೆಯನ್ನು ಬೆಳೆಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿದ್ದೇವೆ.</p>
 
                </div>
                
                <h3>ನಮ್ಮ ಉಪಕ್ರಮಗಳು</h3>
                <div className="missi">
                    <h4 className="toleftneed">ಸಂರಕ್ಷಣೆ ಮತ್ತು ಪುನಃಸ್ಥಾಪನೆ:</h4>
                    <p>ಝಾ ಫೌಂಡೇಶನ್ ಸಂರಕ್ಷಣೆ ಮತ್ತು ಪುನಃಸ್ಥಾಪನೆ ಯೋಜನೆಗಳಲ್ಲಿ ಸಕ್ರಿಯವಾಗಿ ತೊಡಗಿಸಿಕೊಂಡಿದೆ. ಅರಣ್ಯೀಕರಣದ ಪ್ರಯತ್ನಗಳಿಂದ ಅಳಿವಿನಂಚಿನಲ್ಲಿರುವ ಪ್ರಭೇದಗಳನ್ನು ರಕ್ಷಿಸುವವರೆಗೆ, ನಮ್ಮ ಉಪಕ್ರಮಗಳು ಭೂಮಿಯ ಮೇಲಿನ ಜೀವವನ್ನು ಉಳಿಸಿಕೊಳ್ಳುವ ನೈಸರ್ಗಿಕ ಆವಾಸಸ್ಥಾನಗಳನ್ನು ಸಂರಕ್ಷಿಸುವ ಮತ್ತು ಮರುಸ್ಥಾಪಿಸುವತ್ತ ಗಮನಹರಿಸುತ್ತವೆ.</p>

                    <h4 className="toleftneed">ತ್ಯಾಜ್ಯ ಕಡಿತ ಕಾರ್ಯಕ್ರಮಗಳು:</h4>
                    <p>ನಮ್ಮ ಪರಿಸರ ವಿಜ್ಞಾನದ ಹೆಜ್ಜೆಗುರುತನ್ನು ಕಡಿಮೆ ಮಾಡುವ ಪ್ರಾಮುಖ್ಯತೆಯನ್ನು ನಾವು ನಂಬುತ್ತೇವೆ. ನಮ್ಮ ತ್ಯಾಜ್ಯ ಕಡಿತ ಕಾರ್ಯಕ್ರಮಗಳು ಜವಾಬ್ದಾರಿಯುತ ತ್ಯಾಜ್ಯ ನಿರ್ವಹಣೆ, ಮರುಬಳಕೆ ಮತ್ತು ಪರಿಸರದ ಮೇಲೆ ಮಾನವ ಚಟುವಟಿಕೆಗಳ ಪ್ರಭಾವವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಅಪ್ಸೈಕ್ಲಿಂಗ್ ಅನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.</p>

                    <h4 className="toleftneed">ಗ್ರೀನ್ ಎನರ್ಜಿ ವಕಾಲತ್ತು:</h4>
                    <p>ನವೀಕರಿಸಬಹುದಾದ ಶಕ್ತಿಗೆ ಪರಿವರ್ತನೆಯು ಹವಾಮಾನ ಬದಲಾವಣೆಯನ್ನು ತಗ್ಗಿಸುವ ಪ್ರಮುಖ ಅಂಶವಾಗಿದೆ. ಝಾ ಫೌಂಡೇಶನ್ ಹಸಿರು ಶಕ್ತಿ ಪರಿಹಾರಗಳ ಅಳವಡಿಕೆಗೆ ಪ್ರತಿಪಾದಿಸುತ್ತದೆ ಮತ್ತು ಬೆಂಬಲಿಸುತ್ತದೆ, ಹೆಚ್ಚು ಸಮರ್ಥನೀಯ ಮತ್ತು ಶುದ್ಧ ಶಕ್ತಿಯ ಭೂದೃಶ್ಯಕ್ಕೆ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ.</p>
                </div>


                <h3>ನಮ್ಮ ವಿಧಾನ</h3>
                <div className="missi">
                    <h4 className="toleftneed">ಶಿಕ್ಷಣ ಮತ್ತು ಅರಿವು:</h4>
                    <p>ಜ್ಞಾನವು ಬದಲಾವಣೆಗೆ ಪ್ರಬಲ ವೇಗವರ್ಧಕವಾಗಿದೆ. ಪರಿಸರ ಸಮಸ್ಯೆಗಳು, ಹವಾಮಾನ ಬದಲಾವಣೆ ಮತ್ತು ಸುಸ್ಥಿರ ಜೀವನ ಕುರಿತು ಜಾಗೃತಿ ಮೂಡಿಸುವ ಶೈಕ್ಷಣಿಕ ಕಾರ್ಯಕ್ರಮಗಳಲ್ಲಿ ನಾವು ಹೂಡಿಕೆ ಮಾಡುತ್ತೇವೆ. ಮಾಹಿತಿಯೊಂದಿಗೆ ವ್ಯಕ್ತಿಗಳಿಗೆ ಅಧಿಕಾರ ನೀಡುವ ಮೂಲಕ, ನಾವು ಅರ್ಥಪೂರ್ಣ ಕ್ರಿಯೆಯನ್ನು ಪ್ರೇರೇಪಿಸಬಹುದು ಎಂದು ನಾವು ನಂಬುತ್ತೇವೆ.</p>

                    <h4 className="toleftneed">ಸಮುದಾಯ ತೊಡಗಿಸಿಕೊಳ್ಳುವಿಕೆ:</h4>
                    <p>ಪರಿಸರ ಸಂರಕ್ಷಣೆ ಒಂದು ಸಾಮೂಹಿಕ ಪ್ರಯತ್ನ. ನಾವು ಸ್ಥಳೀಯ ಸಮುದಾಯಗಳೊಂದಿಗೆ ತೊಡಗಿಸಿಕೊಳ್ಳುತ್ತೇವೆ, ಪರಿಸರ ಉಪಕ್ರಮಗಳಲ್ಲಿ ಸಕ್ರಿಯ ಭಾಗವಹಿಸುವಿಕೆಯನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುತ್ತೇವೆ. ಸಮುದಾಯ-ನೇತೃತ್ವದ ಯೋಜನೆಗಳು ಮತ್ತು ಈವೆಂಟ್‌ಗಳ ಮೂಲಕ, ನಾವು ಪರಿಸರ ಚಾಂಪಿಯನ್‌ಗಳ ಜಾಲವನ್ನು ನಿರ್ಮಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿದ್ದೇವೆ.</p>

                     
                </div>

            </section>
        </div>


    );
}

