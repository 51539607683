import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification">English</NavLink></a>

        </div>


<h1 id="mhead">Zha Eco-Friendly Certification</h1>
   
   <div className='second-mcontent'>
           <img src='../../img/eco.jpg'></img>
       </div>


 

<section className="visionmi">
 
	<div className="purpose">
	<h4>ദർശനം</h4>
	<p>"റിയൽ എസ്റ്റേറ്റ് ബിസിനസ് രീതികളിൽ സുസ്ഥിരതാ മനോഭാവം വികസിപ്പിക്കുന്നതിനുള്ള Zha അടിത്തറ"
</p>
	</div>

<div className="missi">
  <h4>ഴ ഫൗണ്ടേഷന്റെ ആശയം:
</h4>
<p> 
സുസ്ഥിര അടിസ്ഥാന സൗകര്യങ്ങൾ ഉൾക്കൊള്ളുന്ന നിർമ്മാതാക്കൾ പരിസ്ഥിതി സൗഹൃദ നിർമ്മാണ രീതികൾ, ഊർജ്ജ-കാര്യക്ഷമമായ ഡിസൈനുകൾ, പരിസ്ഥിതി ഉത്തരവാദിത്തമുള്ള വീടുകൾ സൃഷ്ടിക്കുന്നതിന് സുസ്ഥിര വസ്തുക്കളുടെ ഉപയോഗം എന്നിവയിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്നു. നിർമ്മാണത്തിന്റെ പാരിസ്ഥിതിക ആഘാതം കുറയ്ക്കുന്നതിനും വീടുകളുടെ ദീർഘകാല സുസ്ഥിരത വർദ്ധിപ്പിക്കുന്നതിനും ഈ രീതികൾ ലക്ഷ്യമിടുന്നു.

</p>

<h4>ഴ മൂല്യ സൃഷ്ടി ഫലം:
</h4>
<p> 
സുസ്ഥിര റിയൽ എസ്റ്റേറ്റ് ആശയങ്ങൾ സാക്ഷ്യപ്പെടുത്തുന്നതിൽ, നിർമ്മാണം ഉറപ്പാക്കുന്നതിനുള്ള സർട്ടിഫിക്കേഷനുകൾ നേടുന്നത് ഉൾപ്പെടുന്നു, ഇത് ഴ അസെസ്‌മെന്റ് രീതിയെ അടിസ്ഥാനമാക്കിയുള്ളതാണ്. ഞങ്ങളുടെ ZHA ഫൗണ്ടേഷൻ സർട്ടിഫിക്കേഷനുകൾ ഒരു റിയൽ എസ്റ്റേറ്റ് പ്രോജക്റ്റ് നിർദ്ദിഷ്ട പരിസ്ഥിതി സൗഹൃദവും സുസ്ഥിരവുമായ മാനദണ്ഡങ്ങൾ പാലിക്കുന്നുണ്ടെന്ന് അംഗീകരിക്കുകയും സ്ഥിരീകരിക്കുകയും ചെയ്യുന്നു, ഇത് കെട്ടിടങ്ങളുടെ നിർമ്മാണത്തിലും പ്രവർത്തനത്തിലും സുതാര്യതയും ഉത്തരവാദിത്തവും പ്രോത്സാഹിപ്പിക്കുന്നു. അതിനാൽ നിർമ്മാതാക്കൾക്ക് അവരുടെ വിപണനത്തിലോ പ്രോജക്റ്റിന്റെ വശങ്ങൾ പ്രോത്സാഹിപ്പിക്കുന്നതിനോ ഞങ്ങളുടെ ലോഗോ ഉപയോഗിക്കാം.

</p>
 

</div>
<p>പരിസ്ഥിതി സൗഹൃദ കെട്ടിട നിർമ്മാണം പരിസ്ഥിതി ആഘാതം കുറയ്ക്കുന്നതിനും സുസ്ഥിരത പ്രോത്സാഹിപ്പിക്കുന്നതിനുമായി വിവിധ സവിശേഷതകൾ ഉൾക്കൊള്ളുന്നു. ചില പ്രധാന സവിശേഷതകൾ ഉൾപ്പെടുന്നു:
</p>
 
<ol className="goalpoints">

	
   
   <li><span>എനർജി എഫിഷ്യൻസി : </span> മൊത്തത്തിലുള്ള ഊർജ്ജ ഉപഭോഗം കുറയ്ക്കുന്നതിന് ഊർജ്ജ-കാര്യക്ഷമമായ സാങ്കേതികവിദ്യകൾ, വീട്ടുപകരണങ്ങൾ, ലൈറ്റിംഗ് എന്നിവയുടെ സംയോജനം.
</li>

<li><span>പുനരുൽപ്പാദിപ്പിക്കാവുന്ന ഊർജ്ജ സ്രോതസ്സുകൾ : </span>  സോളാർ പാനലുകൾ, കാറ്റാടി ടർബൈനുകൾ, അല്ലെങ്കിൽ ജിയോതെർമൽ സിസ്റ്റങ്ങൾ എന്നിവ പോലെയുള്ള പുനരുപയോഗ ഊർജ്ജ സംവിധാനങ്ങളുടെ സംയോജനം, സൈറ്റിൽ ശുദ്ധമായ ഊർജ്ജം ഉൽപ്പാദിപ്പിക്കുന്നതിന്.
</li>
<li><span>സുസ്ഥിര സാമഗ്രികൾ : </span> വിഭവശോഷണം കുറയ്ക്കുന്നതിനും മാലിന്യ ഉത്പാദനം കുറയ്ക്കുന്നതിനും പരിസ്ഥിതി സൗഹൃദവും പുനരുപയോഗം ചെയ്തതുമായ വസ്തുക്കളുടെ ഉപയോഗം.
</li>
<li><span>ജലസംരക്ഷണം : </span> ജലസേചന ഉപകരണങ്ങൾ, മഴവെള്ള സംഭരണ സംവിധാനങ്ങൾ, ജല ഉപഭോഗം കുറയ്ക്കുന്നതിനുള്ള കാര്യക്ഷമമായ ജലസേചന രീതികൾ എന്നിവ നടപ്പിലാക്കുക.
</li>
<li><span>ഗ്രീൻ റൂഫും ഭിത്തികളും : </span> മെച്ചപ്പെട്ട ഇൻസുലേഷനായി പച്ച മേൽക്കൂരകളും ഭിത്തികളും സ്ഥാപിക്കൽ, ചൂട് ആഗിരണം കുറയ്ക്കൽ, ജൈവവൈവിധ്യം മെച്ചപ്പെടുത്തൽ.
</li>
<li><span>പ്രകൃതിദത്ത വെന്റിലേഷൻ : </span> മെക്കാനിക്കൽ കൂളിംഗ് സിസ്റ്റങ്ങളുടെ ആവശ്യകത കുറയ്ക്കുന്നതിനും ഇൻഡോർ വായുവിന്റെ ഗുണനിലവാരം വർദ്ധിപ്പിക്കുന്നതിനും പ്രകൃതിദത്ത വായുസഞ്ചാരത്തിനായി രൂപകൽപ്പന ചെയ്യുന്നു.
</li>
<li><span>മാലിന്യം കുറയ്ക്കലും പുനരുപയോഗവും : </span> പുനരുപയോഗത്തിലൂടെയും ഉത്തരവാദിത്തമുള്ള സംസ്കരണ രീതികളിലൂടെയും നിർമ്മാണ മാലിന്യങ്ങൾ കുറയ്ക്കുന്നതിനുള്ള തന്ത്രങ്ങൾ.
</li>
<li><span>കുറഞ്ഞ-ഇംപാക്ട് സൈറ്റ് ഡെവലപ്‌മെന്റ് : </span> പ്രകൃതിദൃശ്യങ്ങളുടെ സംരക്ഷണം, ഉത്തരവാദിത്തമുള്ള കൊടുങ്കാറ്റ് ജലപരിപാലനം, നിർമ്മാണ വേളയിൽ പ്രാദേശിക ആവാസവ്യവസ്ഥകൾക്കുള്ള തടസ്സം കുറയ്ക്കൽ.

</li>
<li><span>സ്‌മാർട്ട് ബിൽഡിംഗ് സിസ്റ്റംസ് : </span> ഊർജ്ജ മാനേജ്‌മെന്റ്, കാര്യക്ഷമമായ ലൈറ്റിംഗ്, വിഭവ ഉപയോഗം ഒപ്റ്റിമൈസ് ചെയ്യുന്നതിനുള്ള താപനില നിയന്ത്രണം എന്നിവയ്‌ക്കായുള്ള സ്മാർട്ട് സാങ്കേതികവിദ്യകളുടെ സംയോജനം.
</li>
<li><span>അംഗീകാരം : </span> അവരുടെ കെട്ടിടത്തിന്റെ സുസ്ഥിര തന്ത്രങ്ങളുമായി പ്ലാൻ ചെയ്യുന്ന ബിൽഡേഴ്‌സ് അസോസിയേഷനെ ഔദ്യോഗികമായി അംഗീകരിക്കുന്ന Zha ഫൗണ്ടേഷൻ പോലുള്ള ഒരു ഓർഗനൈസേഷനിൽ നിന്ന് അംഗീകാരം തേടുന്നു.
</li>

</ol>



<p>പാരിസ്ഥിതിക ഉത്തരവാദിത്തമുള്ള കെട്ടിടങ്ങൾ സൃഷ്ടിക്കുന്നതിന് ഈ സവിശേഷതകൾ കൂട്ടായി സംഭാവന ചെയ്യുന്നു, അത് അവരുടെ ജീവിതചക്രത്തിലുടനീളം പാരിസ്ഥിതിക കാൽപ്പാടുകൾ കുറയ്ക്കാൻ ലക്ഷ്യമിടുന്നു. </p>


</section>
 

</div>
 
 
);
}

