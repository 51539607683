import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        
        <div>
        <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                    हमारा फाउंडेशन एक गैर-लाभकारी संगठन है जिसे कृषि व्यवसाय में मुफ्त में गतिशील परामर्श के माध्यम से भारतीय किसानों की मदद करने, किसानों के लिए मूल्य वर्धित लाभ प्राप्त करने और अनुरोधों के आधार पर उनके खेतों का प्रबंधन करने के लिए शुरू किया गया था। इसलिए हमने देखा कि हमें एक ऐसे उत्पाद का आविष्कार करने की ज़रूरत है जो हमारे उपभोक्ताओं को मूल्य प्रदान करे।



                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/abou2.jpg" />
     <p>
     हम सतत और समावेशी विकास में तेजी लाते हैं और खाद्य और कृषि कंपनियों और संगठनों के लिए कार्यकुशलता बढ़ाकर, विकास को बढ़ावा देकर, उद्योगों को आकार देकर और संगठनात्मक प्रभावशीलता बढ़ाकर परिवर्तनकारी प्रभाव डालते हैं। हमारे मालिकाना उपकरण और डिजिटल और उन्नत विश्लेषण क्षमताएं, व्यापक अनुभव के साथ मिलकर हमारे ग्राहकों के लिए लाए गए मूल्य की आधारशिला हैं।


     </p>
 </div>
 <div className="about-card">
     <img src="../../img/abou3.jpg" />
     <p>
     झा फाउंडेशन बहु-विषयक अनुसंधान, क्षमता निर्माण, आत्म सुधार में संलग्न है और युवाओं के दिमाग और हृदय स्वास्थ्य की मदद से जुड़े अभियान चलाता है। एक पूरी पीढ़ी अधिक चिंतित, अधिक नाजुक, अधिक उदास है। वे जोखिम लेने में बहुत कम सहज होते हैं। यह एक पीढ़ी में वास्तविक परिवर्तन है।'' इसलिए, हम कल्याण को मापने के तरीके विकसित कर रहे हैं जिसमें लचीलापन, प्रेम, आशा, सांस्कृतिक पहचान, महत्वपूर्ण चेतना, आत्मनिर्णय और कृषि कार्य सुधार के तरीके शामिल हैं।


     </p>
 </div>
            </div>
            <div className="heightt">
                <h2 className="structuretit">Zha Transformation Method</h2>
                <img src="../img/height.png"></img>
            </div>
        </div>

    );
}