import react from "react";
import React, { useState } from 'react';
import Video from './Videocarousel';
import Carousel from 'react-bootstrap/Carousel';
import "./Home.css";
import Testimonal from './Testimonal';
import Recue from './Recue';
import Card from 'react-bootstrap/Card';
import './Feedback.css';
import Recent from './Recent';
import Feedback from './Feedback';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Awards from './Feedback copy';

export default function Home() {
    return (
        <div>
<div className="hmain">
<div  id="language" >
        
        <a href=""><NavLink to="/">English</NavLink></a>

        </div>
    <div className="main-content">
        <h1>
            ZHA Foundation Charitable Trust
        </h1>
        <p>"स्थिरता मानसिकता वाले पेशेवर"</p>
    
        {/* <p>
        हमारा झा फाउंडेशन एक गैर-लाभकारी संगठन है जिसकी स्थापना 2017 में भारतीय किसानों को मुफ्त में इनोवेटिव टेक कंसल्टिंग के माध्यम से मदद करने के लिए की गई थी, जो किसानों को उनकी प्रथाओं को बढ़ाने में मूल्य वर्धित लाभ प्रदान करता है जो किसानों के स्थायी जीवन को बढ़ावा देता है, युवाओं की रुचि को सक्षम बनाता है। कृषि गतिविधियाँ, इस प्रकार दुनिया में खाद्य स्थिरता पैदा करती हैं।</p> */}
    </div>
    <div className="img-align">
    <img src="../../img/hero.webp"></img>
    </div>
    </div> 
    {/* <div className="whoweare">
        <div className="bar"></div>
        <h4>हम कौन हैं?</h4>
    </div> */}
    
    <div className="unique">
  <p>80G Unique Registration Number of Zha Foundation - AAATZ1755HF20231
</p>
  <p>12A Unique Registration Number of Zha Foundation - AAATZ1755HE20231</p>
</div>

    <section className="visionmi">


<div className="purpose">
<h4>नज़रिया</h4>
<p>अगली पीढ़ियों के लिए बेहतर भविष्य बनाने के लिए, ZHA फाउंडेशन एक स्थायी मानसिकता के पोषण और हमारे ग्रह के प्राकृतिक संसाधनों के संरक्षण के दोहरे उद्देश्य के लिए समर्पित है।</p>
</div>

<div className="missi">
  <h4>उद्देश्य</h4>
<p>2017 में स्थापित झा फाउंडेशन एक स्थायी मानसिकता को बढ़ावा देने के लिए प्रतिबद्ध वैश्विक समुदाय को तैयार करने का प्रयास करता है। हमारा लक्ष्य अनियंत्रित लालच और ईर्ष्या के नकारात्मक प्रभावों को पार करना, राष्ट्रों और ग्रह के नाजुक संतुलन को उनके संभावित नुकसान को पहचानना है। पेशेवरों और व्यापारिक नेताओं के साथ सहयोग करते हुए, हमारा फाउंडेशन हमारे झा सदस्यों के लिए तीन मार्गदर्शक सिद्धांतों के माध्यम से एक स्थायी भविष्य, संघर्षों से दूर रहने और एकता को बढ़ावा देने की वकालत करता है: कोई राजनीति-आधारित चर्चा नहीं, कोई धर्म या जाति पर आधारित कोई चर्चा नहीं, और परहेज करने की प्रतिबद्धता साथी सदस्यों के बारे में नकारात्मक बोलने से।
</p>
 
<p>
भविष्य की पीढ़ियों की भलाई को खतरे में डाले बिना वर्तमान जरूरतों को संतुलित करते हुए, बेहतर कल के लिए सतत विकास अनिवार्य है। हमारे समाज और साझा ग्रह का अस्तित्व एक स्थायी मानसिकता को बढ़ावा देने पर निर्भर है। सतत विकास लक्ष्य (एसडीजी), जिसमें सत्रह परस्पर जुड़े उद्देश्य शामिल हैं, "अभी और भविष्य में लोगों और ग्रह के लिए शांति और समृद्धि के लिए साझा ब्लूप्रिंट" के रूप में कार्य करते हैं। जिसमें हम निम्नलिखित पर ध्यान केंद्रित कर रहे हैं
</p>
{/*  
<p className="goalpoints">
<span>लक्ष्य 1: </span>साझाकरण और जीवनयापन मॉडल अवधारणाओं को बढ़ावा देकर सभी प्रकार की गरीबी को समाप्त करना।<br />
<span>लक्ष्य 2: </span>खाद्य स्थिरता को बढ़ावा देकर शून्य भूख हासिल करना।<br />
<span>लक्ष्य 3: </span>जैविक खाद्य उपभोग को बढ़ावा देने के माध्यम से स्वास्थ्य समृद्धि को बढ़ावा देना और समग्र कल्याण के लिए बच्चों, माता-पिता आदि के लिए खेल क्लबों को सुलभ बनाना।<br />
<span>लक्ष्य 4: </span>सभी स्तरों के छात्रों को प्लान ए और प्लान बी दोनों अवधारणाओं को समझने को सुनिश्चित करके शिक्षा को बढ़ाना।<br />
<span>लक्ष्य 5: </span>पारिवारिक कल्याण के लिए पारस्परिक सम्मान पर जोर देकर लैंगिक समानता और महिला सशक्तिकरण की वकालत करना।<br />
<span>लक्ष्य 6: </span>विश्व स्तर पर पर्यावरणीय प्रभावों को कम करने के लिए जल और स्वच्छता सुनिश्चित करें।<br /></p> */}
</div>
</section>
    




      <h1 id="About-head">Our Offerings</h1>
      <h3>Contributing towards Sustainability Social Services</h3>


      <div className="listprimary">
        <div className="listprimarylist">
          <a href="">  <NavLink to="/Rural-Primary-Health-Care"><img src="../../img/primary/1.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"><img src="../../img/primary/2.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Disaster-Relief"><img src="../../img/primary/3.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Environment"><img src="../../img/primary/4.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/primary/9.png"></img></NavLink></a>

        </div>

        <div className="listprimarylist">
          <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/primary/11.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students"> <img src="../../img/primary/6.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Pathfinder-Course">  <img src="../../img/primary/7.png"></img></NavLink></a>
          <a href="">  <NavLink to="/zha-farmers-transformation"> <img src="../../img/primary/8.png"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/primary/10.png"></img></NavLink></a>
        </div>

      </div>

      <div className="listsecondary">
        <Carousel>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Rural-Primary-Health-Care"> <img src='../../img/primary/1.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"> <img src='../../img/primary/2.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Disaster-Relief"> <img src='../../img/primary/3.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Environment">  <img src='../../img/primary/4.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students">  <img src='../../img/primary/6.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Pathfinder-Course"> <img src='../../img/primary/7.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/zha-farmers-transformation"> <img src='../../img/primary/8.png' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/primary/9.png"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/primary/10.png"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/primary/11.png"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </div>




      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recognition & Awards</h4>
      </div>

      <div className="feedback">
        <Awards />
      </div>




      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recent Events</h4>
      </div>
      <div className="feedback">
        <Recent />
      </div>

      <div className="whoweare">
        <div className="bar"></div>
        <h4>Feedback</h4>
      </div>

      <div className="feedback">
        <Feedback />
      </div>



      <Video />


      {/* 
<section>
<div className="whoweare">
        <div className="bar"></div>
        <h4>Testimonial</h4>
    </div>
</section>

 
<div className="videoplayerr">
        <div className="ovall-container">
      <video className="ovall-video"  loop  controls>
     
        <source src={'../img/testimonial.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </div> */}


    </div>

  );
}



