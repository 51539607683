import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Feedback.css';

const Feedback = (props) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            swipeable={false}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}


            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
         
        <div className='recentc'> <img className='recentcaro' src='../../img/RECUE/1.webp' text="Second slide" /></div>
        <div className='recentc'><img className='recentcaro' src='../../img/RECUE/2.webp' text="Second slide" /></div>
        <div className='recentc'><img className='recentcaro' src='../../img/RECUE/3.webp' text="Second slide" /></div>
        <div className='recentc'><img className='recentcaro' src='../../img/RECUE/4.webp' text="Second slide" /></div>

        <div className='recentc'> <img className='recentcaro' src='../../img/RECUE/5.webp' text="Second slide" /></div>
        <div className='recentc'> <img className='recentcaro' src='../../img/RECUE/6.webp' text="Second slide" /></div>
         <div className='recentc'><img className='recentcaro' src='../../img/RECUE/7.webp' text="Second slide" /></div>
         <div className='recentc'> <img className='recentcaro' src='../../img/RECUE/8.webp' text="Second slide" /></div>
         <div className='recentc'> <img className='recentcaro' src='../../img/RECUE/9.webp' text="Second slide" /></div>
             
        </Carousel>
    );
};

export default Feedback;


