import React from 'react';
 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a>
        </div> */}

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Scaling-Sustainability-Maturity-Framework">English</NavLink></a>
         
        </div>

       
        <h1 id="mhead">Zha സ്കെയിലിംഗ് സുസ്ഥിരത മെച്യൂരിറ്റി ഫ്രെയിംവർക്ക്</h1>
   
    


     
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/scalee.webp'></img>
            </div>
            <div>
           <p>ചുവടെയുള്ള വിശദാംശങ്ങൾ ഫ്രെയിം ചെയ്‌തുകൊണ്ട് നിർമ്മാണത്തിലിരിക്കുന്ന Zha സ്‌കെയിലിംഗ് സുസ്ഥിരത മെച്യൂരിറ്റി ഫ്രെയിംവർക്ക് ഇതാ. മനുഷ്യന്റെ ആരോഗ്യം, പ്രകൃതി ക്ഷേമം, ഭൂമിയുടെ ക്ഷേമം എന്നിവയ്‌ക്കായി നാല് തലത്തിലുള്ള പക്വതയുള്ള ഒരു സ്കെയിലിംഗ് സുസ്ഥിരത ചട്ടക്കൂട് സൃഷ്‌ടിക്കുന്നത് ശ്രദ്ധാപൂർവ്വം ആസൂത്രണവും പരിഗണനയും ഉൾക്കൊള്ളുന്നു. അത്തരമൊരു ചട്ടക്കൂട് വികസിപ്പിക്കാൻ നിങ്ങളെ സഹായിക്കുന്നതിനുള്ള ഒരു പൊതു ഗൈഡ് ഇതാ:


            </p>
            <h2>പക്വതയുടെ നാല് തലങ്ങൾ നിർവചിക്കുക</h2>
           
<ol>
    <li><span>തുടക്കം : </span>
    <ul>
        <li>സുസ്ഥിരതാ തത്വങ്ങളോടുള്ള അടിസ്ഥാന അവബോധവും പ്രതിബദ്ധതയും.</li>
        <li>Initial efforts to incorporate sustainable practices.
</li>
        <li>പരിമിതമായ വ്യാപ്തിയും സ്വാധീനവും.</li>
    </ul>

</li>


<li><span>വികസനം : </span>
    <ul>
        <li>മനുഷ്യൻ, പ്രകൃതി, ഭൂമി എന്നിവയുടെ ക്ഷേമത്തിന്റെ പരസ്പര ബന്ധത്തെക്കുറിച്ചുള്ള ധാരണ വളരുന്നു.</li>
        <li>സമഗ്രമായ സുസ്ഥിര സംരംഭങ്ങൾ നടപ്പിലാക്കുന്നു.</li>
        <li>വിവിധ ഡൊമെയ്‌നുകളിലുടനീളം ശ്രമങ്ങൾ വിപുലീകരിക്കുന്നു.</li>
    </ul>

</li>


<li><span>ഒപ്റ്റിമൈസേഷൻ: </span>
    <ul>
        <li>സുസ്ഥിര സമ്പ്രദായങ്ങളുടെ വിപുലമായ സംയോജനം.</li>
        <li>ഡാറ്റയും ഫീഡ്‌ബാക്കും അടിസ്ഥാനമാക്കിയുള്ള തുടർച്ചയായ മെച്ചപ്പെടുത്തൽ.</li>
        <li>പങ്കാളികളുമായി സഹകരിച്ചുള്ള പങ്കാളിത്തം.</li>
    </ul>

</li>
  
<li><span>രൂപാന്തരം : </span>
    <ul>
        <li>സുസ്ഥിരതയിലേക്കുള്ള സമഗ്രവും പരിവർത്തനാത്മകവുമായ സമീപനം.</li>
        <li>വ്യവസായ മാനദണ്ഡങ്ങൾ നിശ്ചയിക്കുന്നതിൽ നേതൃത്വം.</li>
        <li>നിലവിലുള്ള നവീകരണവും ഉയർന്നുവരുന്ന വെല്ലുവിളികളോട് പൊരുത്തപ്പെടുത്തലും.</li>
    </ul>

</li>
</ol>


<h2>ഫ്രെയിംവർക്ക് ഘടകങ്ങൾ</h2>
           
           <ol>
               <li><span>മനുഷ്യ ആരോഗ്യം : </span>
               <ul>
                   <li><span>ആരോഗ്യവും ക്ഷേമ പരിപാടികളും : </span>ശാരീരികവും മാനസികവും വൈകാരികവുമായ ക്ഷേമം പ്രോത്സാഹിപ്പിക്കുന്ന സംരംഭങ്ങൾ നടപ്പിലാക്കുക.</li>
                   <li><span>വർക്ക്-ലൈഫ് ബാലൻസ് : </span>തൊഴിൽ-ജീവിത ബാലൻസ്, ജീവനക്കാരുടെ സംതൃപ്തി എന്നിവയ്ക്ക് മുൻഗണന നൽകുന്ന പരിതസ്ഥിതികൾ വളർത്തുക.</li>
                   <li><span>കമ്മ്യൂണിറ്റി ഇടപഴകൽ : </span>പ്രാദേശിക കമ്മ്യൂണിറ്റികളുമായി ഇടപഴകാനും പിന്തുണയ്ക്കാനും ജീവനക്കാരെ പ്രോത്സാഹിപ്പിക്കുക.</li>
               </ul>
           
           </li>

           <li><span>പ്രകൃതി ക്ഷേമം : </span>
               <ul>
                   <li><span>ജൈവവൈവിധ്യ സംരക്ഷണം : </span>ജൈവവൈവിധ്യം സംരക്ഷിക്കുന്നതിനും മെച്ചപ്പെടുത്തുന്നതിനുമുള്ള സമ്പ്രദായങ്ങൾ നടപ്പിലാക്കുക.</li>
                   <li><span>പരിസ്ഥിതി സൗഹൃദ പ്രവർത്തനങ്ങൾ : </span>പരിസ്ഥിതി ആഘാതം കുറയ്ക്കുന്നതിന് പ്രക്രിയകൾ ഒപ്റ്റിമൈസ് ചെയ്യുക.</li>
                   <li><span>സുസ്ഥിര ഉറവിടം : </span>സുസ്ഥിരമായ ഉറവിട വസ്തുക്കളുടെ ഉപയോഗത്തിന് മുൻഗണന നൽകുക.</li>
               </ul>
           
           </li>

           <li><span>ഭൂമിയുടെ ആരോഗ്യം : </span>
               <ul>
                   <li><span>കാർബൺ ന്യൂട്രാലിറ്റി : </span>പുനരുപയോഗ ഊർജത്തിലൂടെയും കാർബൺ ഓഫ്‌സെറ്റ് സംരംഭങ്ങളിലൂടെയും കാർബൺ ന്യൂട്രാലിറ്റിക്കായി പരിശ്രമിക്കുക.</li>
                   <li><span>വേസ്റ്റ് റിഡക്ഷൻ : </span>മാലിന്യം കുറയ്ക്കൽ, പുനരുപയോഗ പരിപാടികൾ നടപ്പിലാക്കുക.</li>
                   <li><span>കാലാവസ്ഥാ പ്രതിരോധം : </span>കാലാവസ്ഥാ വ്യതിയാനത്തിന്റെ ആഘാതം ലഘൂകരിക്കാനും പൊരുത്തപ്പെടുത്താനുമുള്ള തന്ത്രങ്ങൾ വികസിപ്പിക്കുക.</li>
               </ul>
           
           </li>
           </ol>



           <h2>നടപ്പാക്കൽ ഘട്ടങ്ങൾ</h2>
           
           <ol>
               <li><span>വിലയിരുത്തൽ : </span>
               <ul>
                   <li>ആരോഗ്യത്തിന്റെ ഓരോ തലത്തിലും നിലവിലുള്ള രീതികളുടെ പ്രാഥമിക വിലയിരുത്തൽ നടത്തുക.</li>
                   <li>ശക്തികൾ, ബലഹീനതകൾ, അവസരങ്ങൾ, ഭീഷണികൾ എന്നിവ തിരിച്ചറിയുക.</li>
                
               </ul>
           
           </li>
           
           
           <li><span>ലക്ഷ്യം ക്രമീകരണം : </span>
               <ul>
                   <li>മനുഷ്യൻ, പ്രകൃതി, ഭൂമി ക്ഷേമം എന്നിവയിലെ പക്വതയുടെ ഓരോ തലത്തിലും നിർദ്ദിഷ്ടവും അളക്കാവുന്നതും കൈവരിക്കാവുന്നതും പ്രസക്തവും സമയബന്ധിതവുമായ (SMART) ലക്ഷ്യങ്ങൾ സ്ഥാപിക്കുക.</li>
               </ul>
           
           </li>
           
           
           <li><span>നടപ്പാക്കൽ പദ്ധതികൾ : </span>
               <ul>
                   <li>മെച്യൂരിറ്റിയുടെ ഓരോ ലെവലിനുമുള്ള വിശദമായ പ്ലാനുകൾ വികസിപ്പിക്കുക, പ്രവർത്തനങ്ങൾ, ഉത്തരവാദിത്തങ്ങൾ, ടൈംലൈനുകൾ എന്നിവ വ്യക്തമാക്കുക.</li>
                   <li>വ്യത്യസ്ത സന്ദർഭങ്ങളിൽ സ്കേലബിളിറ്റിയും റെപ്ലിക്കബിലിറ്റിയും പരിഗണിക്കുക.</li>
               </ul>
           
           </li>
             
           <li><span>നിരീക്ഷണവും വിലയിരുത്തലും : </span>
               <ul>
                   <li>ശക്തമായ നിരീക്ഷണ, വിലയിരുത്തൽ സംവിധാനങ്ങൾ നടപ്പിലാക്കുക.</li>
                   <li>പതിവായി പുരോഗതി വിലയിരുത്തുക, ഫീഡ്‌ബാക്ക് ശേഖരിക്കുക, അതിനനുസരിച്ച് തന്ത്രങ്ങൾ ക്രമീകരിക്കുക.</li>
               </ul>
           
           </li>


           <li><span>ഓഹരി ഉടമകളുടെ ഇടപെടൽ : </span>
               <ul>
                   <li>ജീവനക്കാർ, കമ്മ്യൂണിറ്റികൾ, വിതരണക്കാർ, നിയന്ത്രണ സ്ഥാപനങ്ങൾ എന്നിവയുൾപ്പെടെ എല്ലാ തലങ്ങളിലുമുള്ള പങ്കാളികളുമായി ഇടപഴകുക.</li>
                   <li>സഹകരണവും പങ്കിട്ട ഉത്തരവാദിത്തവും വളർത്തുക.

</li>
               </ul>
           
           </li>


           <li><span>വിദ്യാഭ്യാസവും ആശയവിനിമയവും : </span>
               <ul>
                   <li>അവബോധം വളർത്തുന്നതിനും സുസ്ഥിരതയുടെ ഒരു സംസ്കാരം വളർത്തിയെടുക്കുന്നതിനുമായി തുടർച്ചയായ വിദ്യാഭ്യാസവും ആശയവിനിമയവും നൽകുക.</li>
                   <li>നേട്ടങ്ങൾ ആഘോഷിക്കുകയും മികച്ച രീതികൾ പങ്കിടുകയും ചെയ്യുക.

</li>
               </ul>
           
           </li>

           <li><span>തുടർച്ചയായ മെച്ചപ്പെടുത്തൽ : </span>
               <ul>
                   <li>തുടർച്ചയായ പുരോഗതിയുടെ ഒരു സംസ്കാരം സ്വീകരിക്കുക.</li>
                   <li>വികസിച്ചുകൊണ്ടിരിക്കുന്ന മാനദണ്ഡങ്ങൾക്കും വെല്ലുവിളികൾക്കും അനുസൃതമായി സുസ്ഥിരത ചട്ടക്കൂട് പതിവായി പുനരവലോകനം ചെയ്യുകയും അപ്‌ഡേറ്റ് ചെയ്യുകയും ചെയ്യുക.

</li>
               </ul>
           
           </li>

           </ol>
           

<p>നിങ്ങളുടെ ഓർഗനൈസേഷന്റെയോ മുൻകൈയുടെയോ നിർദ്ദിഷ്ട സന്ദർഭത്തെ അടിസ്ഥാനമാക്കി ചട്ടക്കൂട് ഇഷ്ടാനുസൃതമാക്കാനും സുസ്ഥിരതയിലും അനുബന്ധ മേഖലകളിലുമുള്ള വിദഗ്ധരിൽ നിന്ന് ഇൻപുട്ട് തേടാനും ഓർക്കുക.</p>
           </div>
        </div>









               
        
 

    </div>
);
}