import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
export default function About(){
    return(
        
        <div>
        <div  id="language" >
        
        <a href=""><NavLink to="/about">English</NavLink></a>

        </div>
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/abou1.jpg" />
                    <p>
                    കാർഷിക ബിസിനസിൽ സൗജന്യമായി ഡൈനാമിക് കൺസൾട്ടിങ്ങിലൂടെ ഇന്ത്യൻ കർഷകരെ സഹായിക്കാനും കർഷകർക്ക് മൂല്യവർധിത നേട്ടങ്ങൾ നൽകാനും അഭ്യർത്ഥനകളെ അടിസ്ഥാനമാക്കി അവരുടെ ഫാമുകൾ കൈകാര്യം ചെയ്യാനും ആരംഭിച്ച ഒരു ലാഭേച്ഛയില്ലാത്ത സ്ഥാപനമാണ് ഞങ്ങളുടെ ഫൗണ്ടേഷൻ. അതിനാൽ ഞങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് മൂല്യം നൽകുന്ന ഒരു ഉൽപ്പന്നം കണ്ടുപിടിക്കേണ്ടതുണ്ടെന്ന് ഞങ്ങൾ കണ്ടു.



                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/abou2.jpg" />
     <p>
     ഞങ്ങൾ സുസ്ഥിരവും ഉൾക്കൊള്ളുന്നതുമായ വളർച്ച ത്വരിതപ്പെടുത്തുകയും കാര്യക്ഷമത വർദ്ധിപ്പിക്കുകയും വളർച്ചയ്ക്ക് ഇന്ധനം നൽകുകയും വ്യവസായങ്ങളെ രൂപപ്പെടുത്തുകയും ഓർഗനൈസേഷണൽ ഫലപ്രാപ്തി വർദ്ധിപ്പിക്കുകയും ചെയ്തുകൊണ്ട് ഭക്ഷ്യ-കാർഷിക കമ്പനികൾക്കും ഓർഗനൈസേഷനുകൾക്കും പരിവർത്തനപരമായ സ്വാധീനം നൽകുന്നു. ഞങ്ങളുടെ പ്രൊപ്രൈറ്ററി ടൂളുകളും ഡിജിറ്റൽ, അഡ്വാൻസ്ഡ് അനലിറ്റിക്സ് കഴിവുകളും, വിപുലമായ അനുഭവവും കൂടിച്ചേർന്നതാണ് ഞങ്ങളുടെ ക്ലയന്റുകൾക്ക് ഞങ്ങൾ നൽകുന്ന മൂല്യത്തിന്റെ അടിസ്ഥാന ശിലകൾ.


     </p>
 </div>
 <div className="about-card">
     <img src="../../img/abou3.jpg" />
     <p>
     ഴ ഫൗണ്ടേഷൻ മൾട്ടി-ഡിസിപ്ലിനറി റിസർച്ച്, കപ്പാസിറ്റി ബിൽഡിംഗ്, സ്വയം മെച്ചപ്പെടുത്തൽ എന്നിവയിൽ ഏർപ്പെടുന്നു, കൂടാതെ യുവാക്കളുടെ മനസ്സിനെയും ഹൃദയാരോഗ്യത്തെയും സഹായിക്കുന്നതിനായി ബന്ധപ്പെട്ട കാമ്പെയ്‌നുകൾ ഏറ്റെടുക്കുന്നു. ഒരു തലമുറ മുഴുവൻ കൂടുതൽ ഉത്കണ്ഠയുള്ളതും കൂടുതൽ ദുർബലവും കൂടുതൽ വിഷാദവുമാണ്. റിസ്‌ക് എടുക്കുന്നതിൽ അവർക്ക് സുഖം കുറവാണ്. ഇത് ഒരു തലമുറയിലെ യഥാർത്ഥ മാറ്റമാണ്. അതിനാൽ, പ്രതിരോധശേഷി, സ്നേഹം, പ്രത്യാശ, സാംസ്കാരിക ഐഡന്റിറ്റി, വിമർശനാത്മക ബോധം, സ്വയം നിർണ്ണയം, കാർഷിക രീതികൾ എന്നിവ ഉൾപ്പെടുന്ന ആരോഗ്യം അളക്കുന്നതിനുള്ള വഴികൾ ഞങ്ങൾ വികസിപ്പിക്കുകയാണ്.


     </p>
 </div>
            </div>
            <div className="heightt">
                <h2 className="structuretit">Zha Transformation Method</h2>
                <img src="../img/height.png"></img>
            </div>
        </div>

    );
}