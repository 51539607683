import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability">English</NavLink></a>
</div>

            <div className="social-ma">

<h1>Why Sustainability Cause?</h1>
</div>


<div className="internship">
        <img src="../img/360.jpg"></img>
        </div>
<div className="goalpointsp">

<p>
ಝಾ ಫೌಂಡೇಶನ್‌ನಂತಹ ಸುಸ್ಥಿರತೆಯ ಅಭಿವೃದ್ಧಿ ಕ್ಲಬ್ ಅನ್ನು ಎಲ್ಲಾ ದೇಶಗಳಿಗೆ ವಿಸ್ತರಿಸುವುದು ವೈವಿಧ್ಯಮಯ ಪರಿಸರ ಮತ್ತು ಸಾಮಾಜಿಕ ಸವಾಲುಗಳನ್ನು ಪರಿಹರಿಸುವ ಮೂಲಕ ಜಾಗತಿಕ ಪ್ರಭಾವಕ್ಕೆ ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ. ಇದು ಸಂಪನ್ಮೂಲಗಳು, ಜ್ಞಾನ ಮತ್ತು ಉತ್ತಮ ಅಭ್ಯಾಸಗಳ ಹಂಚಿಕೆಯನ್ನು ಶಕ್ತಗೊಳಿಸುತ್ತದೆ, ವಿಶಾಲ ಪ್ರಮಾಣದಲ್ಲಿ ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿಯ ಕಡೆಗೆ ಸಹಯೋಗದ ವಿಧಾನವನ್ನು ಪೋಷಿಸುತ್ತದೆ. ಹೆಚ್ಚುವರಿಯಾಗಿ, ಜಾಗತಿಕ ಸಮಸ್ಯೆಗಳ ಅಂತರ್ಸಂಪರ್ಕಿತ ಸ್ವಭಾವವು ಹೆಚ್ಚು ಸಮರ್ಥನೀಯ ಮತ್ತು ಸ್ಥಿತಿಸ್ಥಾಪಕ ಜಗತ್ತನ್ನು ರಚಿಸಲು ವ್ಯಾಪಕ ಭಾಗವಹಿಸುವಿಕೆಯ ಅಗತ್ಯವನ್ನು ಒತ್ತಿಹೇಳುತ್ತದೆ.ಕೆಳಗೆ ತಿಳಿಸಿರುವಂತೆ ಸಮರ್ಥನೀಯವಲ್ಲದ ಮಾನವ ಜೀವನದ ಕಾರಣಗಳು. ಮಾನವರಿಗೆ ಸಮರ್ಥನೀಯವಲ್ಲದ ಜೀವನಶೈಲಿಯು ಹಲವಾರು ಅಂತರ್ಸಂಪರ್ಕಿತ ಅಂಶಗಳಿಗೆ ಕಾರಣವಾಗಿದೆ:
</p>


<ol className="goalpoints">
    <li><span>ಅತಿಯಾದ ಮೊಬೈಲ್ ಫೋನ್ ಬಳಕೆ : </span>ಮಕ್ಕಳು ಮತ್ತು ಪೋಷಕರು ಮೊಬೈಲ್ ಫೋನ್‌ಗಳಲ್ಲಿ ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ಕಳೆಯುತ್ತಿದ್ದಾರೆ, ಇದು ದೈಹಿಕ ಚಟುವಟಿಕೆ, ಓದುವ ಹವ್ಯಾಸಗಳು ಮತ್ತು ಸಾಮಾಜಿಕ ಸಂವಹನದಲ್ಲಿ ಕುಸಿತಕ್ಕೆ ಕಾರಣವಾಗಿದೆ.</li>
    <li><span>ವೀಡಿಯೋ ಗೇಮ್ ಅತಿಯಾದ ಬಳಕೆ : </span>ವಿಶೇಷವಾಗಿ ಮಕ್ಕಳು ಹೆಚ್ಚಿನ ಸಮಯವನ್ನು ವಿಡಿಯೋ ಗೇಮ್‌ಗಳಿಗೆ ಮೀಸಲಿಡುತ್ತಾರೆ, ಆಗಾಗ್ಗೆ ಕ್ರೀಡೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸುವ ಮತ್ತು ಓದುವ ಪ್ರೀತಿಯನ್ನು ಬೆಳೆಸುವ ವೆಚ್ಚದಲ್ಲಿ.</li>
    <li><span>ಸಾಮಾಜಿಕ ನಂಬಿಕೆಯ ಸವೆತ : </span>ಡಿಜಿಟಲ್ ವ್ಯಾಕುಲತೆಯಿಂದಾಗಿ ಜನರ ನಡುವಿನ ಮುಖಾಮುಖಿ ಸಂವಹನಗಳು ನಂಬಿಕೆ ಮತ್ತು ಪರಸ್ಪರ ಸಂಬಂಧಗಳ ಕುಸಿತಕ್ಕೆ ಕಾರಣವಾಗುತ್ತವೆ, ನಮ್ಮ ಸಾಮಾಜಿಕ ಸಂಪರ್ಕಗಳ ಗುಣಮಟ್ಟದ ಬಗ್ಗೆ ಕಳವಳವನ್ನು ಹೆಚ್ಚಿಸುತ್ತವೆ.</li>


    <li><span>ಮಿತಿಮೀರಿದ ಬಳಕೆ ಮತ್ತು ಭೌತಿಕತೆ : </span>ಆರಾಮದಾಯಕ ಮತ್ತು ತೃಪ್ತಿಕರ ಜೀವನಕ್ಕೆ ಅಗತ್ಯಕ್ಕಿಂತ ಹೆಚ್ಚಿನ ಭೌತಿಕ ಆಸ್ತಿ ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳ ಬಳಕೆಯ ಅನ್ವೇಷಣೆಯು ಸಮರ್ಥನೀಯವಲ್ಲದ ಜೀವನಶೈಲಿಗೆ ಕಾರಣವಾಗಬಹುದು.

</li>
    <li><span>ಸಂಪನ್ಮೂಲ ಸವಕಳಿ : </span>ಪಳೆಯುಳಿಕೆ ಇಂಧನಗಳು, ಖನಿಜಗಳು ಮತ್ತು ತಾಜಾ ನೀರು ಸೇರಿದಂತೆ ನೈಸರ್ಗಿಕ ಸಂಪನ್ಮೂಲಗಳ ಅತಿಯಾದ ಶೋಷಣೆಯು ಅವುಗಳ ಸವಕಳಿಗೆ ಕಾರಣವಾಗಬಹುದು ಮತ್ತು ಪರಿಸರ ಅವನತಿಗೆ ಕಾರಣವಾಗಬಹುದು.

</li>
    <li><span>ತ್ಯಾಜ್ಯ ಉತ್ಪಾದನೆ : </span>ಅಸಮರ್ಥ ಸಂಪನ್ಮೂಲ ಬಳಕೆ ಮತ್ತು ಅಸಮರ್ಪಕ ತ್ಯಾಜ್ಯ ವಿಲೇವಾರಿ ಮಾಲಿನ್ಯ, ಭೂಕುಸಿತ ಸಂಗ್ರಹಣೆ ಮತ್ತು ಪರಿಸರಕ್ಕೆ ಹಾನಿಕಾರಕ ಪದಾರ್ಥಗಳ ಬಿಡುಗಡೆಗೆ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ.


</li>
    <li><span>ಹೆಚ್ಚಿನ ಶಕ್ತಿಯ ಬಳಕೆ : </span>ವಿದ್ಯುತ್, ತಾಪನ ಮತ್ತು ಸಾರಿಗೆಯಂತಹ ಅತಿಯಾದ ಬಳಕೆ, ಶಕ್ತಿ-ತೀವ್ರ ಅಭ್ಯಾಸಗಳ ಮೇಲೆ ಅವಲಂಬನೆಯು ಹೆಚ್ಚಿದ ಹಸಿರುಮನೆ ಅನಿಲ ಹೊರಸೂಸುವಿಕೆ ಮತ್ತು ಪರಿಸರದ ಒತ್ತಡಕ್ಕೆ ಕಾರಣವಾಗಬಹುದು.


</li>
    <li><span>ಸಾರಿಗೆ ಮತ್ತು ಪ್ರಯಾಣದ ಅಭ್ಯಾಸಗಳು : </span>ಸಾರಿಗೆಗಾಗಿ ವೈಯಕ್ತಿಕ ವಾಹನಗಳ ಮೇಲೆ ಅವಲಂಬನೆ, ವಿಶೇಷವಾಗಿ ದಕ್ಷ ಸಾರ್ವಜನಿಕ ಸಾರಿಗೆ ಕೊರತೆಯಿರುವ ಪ್ರದೇಶಗಳಲ್ಲಿ, ವಾಯು ಮಾಲಿನ್ಯ, ದಟ್ಟಣೆ ಮತ್ತು ಪಳೆಯುಳಿಕೆ ಇಂಧನ ಬಳಕೆಗೆ ಕೊಡುಗೆ ನೀಡಬಹುದು.
</li>
    <li><span>ಆಹಾರ ಉತ್ಪಾದನೆ ಮತ್ತು ಬಳಕೆ : </span>ಏಕಬೆಳೆ ಕೃಷಿ, ರಾಸಾಯನಿಕ ಒಳಹರಿವಿನ ಮಿತಿಮೀರಿದ ಬಳಕೆ ಮತ್ತು ಆಹಾರದ ದೂರದ ಸಾಗಣೆ ಸೇರಿದಂತೆ ಸಮರ್ಥನೀಯವಲ್ಲದ ಕೃಷಿ ಪದ್ಧತಿಗಳು ಪರಿಸರದ ಅವನತಿ ಮತ್ತು ಆಹಾರ ಭದ್ರತೆ ಸವಾಲುಗಳಿಗೆ ಕಾರಣವಾಗಬಹುದು.
</li>
    <li><span>ಅರಣ್ಯನಾಶ ಮತ್ತು ಆವಾಸಸ್ಥಾನ ವಿನಾಶ : </span>ಕೃಷಿ, ನಗರೀಕರಣ ಮತ್ತು ಮೂಲಸೌಕರ್ಯ ಅಭಿವೃದ್ಧಿಗಾಗಿ ಭೂಮಿಯನ್ನು ತೆರವುಗೊಳಿಸುವುದು ಜೀವವೈವಿಧ್ಯತೆಯ ನಷ್ಟಕ್ಕೆ ಕಾರಣವಾಗಬಹುದು, ಪರಿಸರ ವ್ಯವಸ್ಥೆಗಳ ಅಡ್ಡಿ, ಮತ್ತು ಕಡಿಮೆ ಇಂಗಾಲದ ಪ್ರತ್ಯೇಕತೆ.

</li>
<li><span>ಜೀವನದ ವಿಜಯಗಳನ್ನು ಆಚರಿಸುವುದು : </span>ಇತರರ ಸಾಧನೆಗಳನ್ನು ಅವರು ಬದುಕಿರುವಾಗ ಆಚರಿಸಲು ನಾವು ಸಮಯವನ್ನು ನೀಡುವುದಿಲ್ಲ.
</li>
<li><span>ಸಹಾನುಭೂತಿಯನ್ನು ನಿರ್ಲಕ್ಷಿಸುವುದು : </span>ಇತರರ ನಷ್ಟಕ್ಕಾಗಿ ನಮ್ಮ ಸಂತಾಪವನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ನಾವು ಸಮಯವನ್ನು ನೀಡುವುದಿಲ್ಲ</li>
    <li><span>ಮಿತಿಮೀರಿದ ಮೀನುಗಾರಿಕೆ ಮತ್ತು ಸಮುದ್ರದ ಅವನತಿ : </span>ಅತಿಯಾದ ಮೀನುಗಾರಿಕೆ ಮತ್ತು ವಿನಾಶಕಾರಿ ಮೀನುಗಾರಿಕೆ ವಿಧಾನಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಸಮರ್ಥನೀಯವಲ್ಲದ ಮೀನುಗಾರಿಕೆ ಅಭ್ಯಾಸಗಳು ಮೀನಿನ ಸ್ಟಾಕ್ಗಳನ್ನು ಖಾಲಿ ಮಾಡಬಹುದು ಮತ್ತು ಸಮುದ್ರ ಪರಿಸರ ವ್ಯವಸ್ಥೆಗಳಿಗೆ ಹಾನಿ ಮಾಡಬಹುದು.
</li> 

<li><span>ಸುಸ್ಥಿರ ಮೂಲಸೌಕರ್ಯದ ಕೊರತೆ : </span>ಅಸಮರ್ಪಕ ನಗರ ಯೋಜನೆ, ಅಸಮರ್ಥ ಕಟ್ಟಡ ವಿನ್ಯಾಸಗಳು ಮತ್ತು ಹಳೆಯ ಮೂಲಸೌಕರ್ಯಗಳು ಸಂಪನ್ಮೂಲ ಅಸಮರ್ಥತೆ ಮತ್ತು ಪರಿಸರದ ಒತ್ತಡಕ್ಕೆ ಕಾರಣವಾಗಬಹುದು.

</li> 

<li><span>ಗ್ರಾಹಕ ನಡವಳಿಕೆ ಮತ್ತು ಆಯ್ಕೆಗಳು : </span>ಸಮರ್ಥನೀಯವಲ್ಲದ ಉತ್ಪನ್ನಗಳನ್ನು ಖರೀದಿಸುವಂತಹ ಗ್ರಾಹಕರು ಮಾಡಿದ ಆಯ್ಕೆಗಳು ಸಂಪನ್ಮೂಲ-ತೀವ್ರ ಮತ್ತು ಪರಿಸರಕ್ಕೆ ಹಾನಿಕಾರಕ ಸರಕುಗಳು ಮತ್ತು ಸೇವೆಗಳಿಗೆ ಬೇಡಿಕೆಯನ್ನು ಹೆಚ್ಚಿಸಬಹುದು.

</li> 

<li><span>ಪರಿಸರದ ಅರಿವು ಮತ್ತು ಶಿಕ್ಷಣದ ಕೊರತೆ : </span>ಕೆಲವು ನಡವಳಿಕೆಗಳ ಪರಿಸರದ ಪರಿಣಾಮಗಳ ಬಗ್ಗೆ ಸಾಕಷ್ಟು ಜ್ಞಾನ ಮತ್ತು ತಿಳುವಳಿಕೆಯು ಉದ್ದೇಶಪೂರ್ವಕವಲ್ಲದ ಸಮರ್ಥನೀಯವಲ್ಲದ ಅಭ್ಯಾಸಗಳಿಗೆ ಕಾರಣವಾಗಬಹುದು.
</li> 

<li><span>ನೀತಿ ಮತ್ತು ನಿಯಂತ್ರಕ ಚೌಕಟ್ಟುಗಳು : </span>ದುರ್ಬಲ ಅಥವಾ ಅಸಮರ್ಪಕ ಪರಿಸರ ನೀತಿಗಳು, ಜಾರಿಯ ಕೊರತೆ, ಮತ್ತು ಸಮರ್ಥನೀಯವಲ್ಲದ ಅಭ್ಯಾಸಗಳನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುವ ಸಬ್ಸಿಡಿಗಳು ಸಮರ್ಥನೀಯವಲ್ಲದ ಜೀವನಶೈಲಿಗೆ ಕೊಡುಗೆ ನೀಡಬಹುದು.
</li> 
</ol>



<p>ಸಮರ್ಥನೀಯವಲ್ಲದ ಜೀವನಶೈಲಿಯನ್ನು ಪರಿಹರಿಸಲು ಶಿಕ್ಷಣ, ನೀತಿ ಬದಲಾವಣೆಗಳು, ತಾಂತ್ರಿಕ ಆವಿಷ್ಕಾರಗಳು ಮತ್ತು ಸಾಂಸ್ಕೃತಿಕ ಮತ್ತು ಸಾಮಾಜಿಕ ರೂಢಿಗಳಲ್ಲಿನ ಬದಲಾವಣೆಗಳು ಸೇರಿದಂತೆ ಬಹು-ಮುಖದ ವಿಧಾನದ ಅಗತ್ಯವಿದೆ. ಸುಸ್ಥಿರ ಅಭ್ಯಾಸಗಳನ್ನು ಉತ್ತೇಜಿಸುವುದು, ಜವಾಬ್ದಾರಿಯುತ ಬಳಕೆಯನ್ನು ಉತ್ತೇಜಿಸುವುದು ಮತ್ತು ಪರಿಸರ ಸ್ನೇಹಿ ತಂತ್ರಜ್ಞಾನಗಳನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳುವುದು ಹೆಚ್ಚು ಸಮರ್ಥನೀಯ ಭವಿಷ್ಯದ ಕಡೆಗೆ ಎಲ್ಲಾ ಪ್ರಮುಖ ಹಂತಗಳಾಗಿವೆ.</p>



</div>
        
     

        </div>

    );
}

