import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification">English</NavLink></a>

        </div>



<h1 id="mhead">Zha Eco-Friendly Certification</h1>
   
   <div className='second-mcontent'>
           <img src='../../img/eco.jpg'></img>
       </div>


 

<section className="visionmi">
 
	<div className="purpose">
	<h4>ದೃಷ್ಟಿ</h4>
	<p>"ರಿಯಲ್ ಎಸ್ಟೇಟ್ ವ್ಯವಹಾರ ವಿಧಾನಗಳಲ್ಲಿ ಸುಸ್ಥಿರತೆಯ ಮನಸ್ಥಿತಿಯನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಝಾ ಅಡಿಪಾಯ"
</p>
	</div>

<div className="missi">
  <h4>ಝಾ ಅಡಿಪಾಯದ ಪರಿಕಲ್ಪನೆ :
</h4>
<p> 
ಸುಸ್ಥಿರತೆಯ ಮೂಲಸೌಕರ್ಯಗಳನ್ನು ಸಂಯೋಜಿಸುವ ಬಿಲ್ಡರ್‌ಗಳು ಪರಿಸರ ಸ್ನೇಹಿ ನಿರ್ಮಾಣ ವಿಧಾನಗಳು, ಶಕ್ತಿ-ಸಮರ್ಥ ವಿನ್ಯಾಸಗಳು ಮತ್ತು ಪರಿಸರ ಜವಾಬ್ದಾರಿಯುತ ಮನೆಗಳನ್ನು ರಚಿಸಲು ಸಮರ್ಥನೀಯ ವಸ್ತುಗಳ ಬಳಕೆಯನ್ನು ಕೇಂದ್ರೀಕರಿಸುತ್ತಾರೆ. ಈ ಅಭ್ಯಾಸಗಳು ನಿರ್ಮಾಣದ ಪರಿಸರ ಪ್ರಭಾವವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಮತ್ತು ಮನೆಗಳ ದೀರ್ಘಾವಧಿಯ ಸಮರ್ಥನೀಯತೆಯನ್ನು ಹೆಚ್ಚಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿವೆ.
</p>

<h4>ಝಾ ಮೌಲ್ಯ ರಚನೆಯ ಫಲಿತಾಂಶ :
</h4>
<p> 
ಸುಸ್ಥಿರ ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಪರಿಕಲ್ಪನೆಗಳನ್ನು ಪ್ರಮಾಣೀಕರಿಸುವುದು ನಿರ್ಮಾಣವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವ ರೀತಿಯಲ್ಲಿ ಪ್ರಮಾಣೀಕರಣಗಳನ್ನು ಪಡೆಯುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ, ಇದು ಝಾ ಮೌಲ್ಯಮಾಪಕರಿಂದ ಮಾಡಲ್ಪಟ್ಟ ಪರಿಸರ ಮೌಲ್ಯಮಾಪನ ವಿಧಾನವನ್ನು ಆಧರಿಸಿದೆ. ನಮ್ಮ ZHA ಫೌಂಡೇಶನ್ ಪ್ರಮಾಣೀಕರಣಗಳು ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಯೋಜನೆಯು ನಿರ್ದಿಷ್ಟ ಪರಿಸರ ಸ್ನೇಹಿ ಮತ್ತು ಸುಸ್ಥಿರ ಮಾನದಂಡಗಳಿಗೆ ಬದ್ಧವಾಗಿದೆ ಎಂದು ಅಂಗೀಕರಿಸುತ್ತದೆ ಮತ್ತು ಪರಿಶೀಲಿಸುತ್ತದೆ, ಕಟ್ಟಡಗಳ ನಿರ್ಮಾಣ ಮತ್ತು ಕಾರ್ಯಾಚರಣೆಯಲ್ಲಿ ಪಾರದರ್ಶಕತೆ ಮತ್ತು ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ. ಆದ್ದರಿಂದ ಬಿಲ್ಡರ್‌ಗಳು ತಮ್ಮ ಮಾರ್ಕೆಟಿಂಗ್ ಅಥವಾ ಪ್ರಾಜೆಕ್ಟ್‌ನ ಪ್ರಚಾರದ ಅಂಶಗಳಲ್ಲಿ ನಮ್ಮ ಲೋಗೋವನ್ನು ಬಳಸಬಹುದು.

</p>
 

</div>
<p>ಪರಿಸರ ಸ್ನೇಹಿ ಕಟ್ಟಡ ನಿರ್ಮಾಣವು ಪರಿಸರ ಪ್ರಭಾವವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಮತ್ತು ಸುಸ್ಥಿರತೆಯನ್ನು ಉತ್ತೇಜಿಸಲು ವಿವಿಧ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಒಳಗೊಂಡಿದೆ. ಕೆಲವು ಪ್ರಮುಖ ವೈಶಿಷ್ಟ್ಯಗಳು ಸೇರಿವೆ:
</p>
 
<ol className="goalpoints">

	
   
   <li><span>ಶಕ್ತಿ ದಕ್ಷತೆ : </span> ಒಟ್ಟಾರೆ ಶಕ್ತಿಯ ಬಳಕೆಯನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಶಕ್ತಿ-ಸಮರ್ಥ ತಂತ್ರಜ್ಞಾನಗಳು, ಉಪಕರಣಗಳು ಮತ್ತು ಬೆಳಕಿನ ಏಕೀಕರಣ.
</li>

<li><span>ನವೀಕರಿಸಬಹುದಾದ ಶಕ್ತಿಯ ಮೂಲಗಳು : </span>  ಸೌರ ಫಲಕಗಳು, ಗಾಳಿ ಟರ್ಬೈನ್‌ಗಳು ಅಥವಾ ಭೂಶಾಖದ ವ್ಯವಸ್ಥೆಗಳಂತಹ ನವೀಕರಿಸಬಹುದಾದ ಇಂಧನ ವ್ಯವಸ್ಥೆಗಳ ಸಂಯೋಜನೆಯು ಸ್ಥಳದಲ್ಲಿ ಶುದ್ಧ ಶಕ್ತಿಯನ್ನು ಉತ್ಪಾದಿಸಲು.
</li>
<li><span>ಸಮರ್ಥನೀಯ ವಸ್ತುಗಳು : </span> ಸಂಪನ್ಮೂಲ ಸವಕಳಿಯನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಮತ್ತು ತ್ಯಾಜ್ಯ ಉತ್ಪಾದನೆಯನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಪರಿಸರ ಸ್ನೇಹಿ ಮತ್ತು ಮರುಬಳಕೆಯ ವಸ್ತುಗಳ ಬಳಕೆ.
</li>
<li><span>ನೀರಿನ ಸಂರಕ್ಷಣೆ : </span> ನೀರಿನ ಬಳಕೆಯನ್ನು ಕಡಿಮೆ ಮಾಡಲು ನೀರು ಉಳಿಸುವ ಸಾಧನಗಳು, ಮಳೆನೀರು ಕೊಯ್ಲು ವ್ಯವಸ್ಥೆಗಳು ಮತ್ತು ಸಮರ್ಥ ನೀರಾವರಿ ಪದ್ಧತಿಗಳ ಅನುಷ್ಠಾನ.
</li>
<li><span>ಹಸಿರು ಛಾವಣಿ ಮತ್ತು ಗೋಡೆಗಳು : </span> ಸುಧಾರಿತ ನಿರೋಧನ, ಕಡಿಮೆ ಶಾಖ ಹೀರಿಕೊಳ್ಳುವಿಕೆ ಮತ್ತು ವರ್ಧಿತ ಜೀವವೈವಿಧ್ಯಕ್ಕಾಗಿ ಹಸಿರು ಛಾವಣಿಗಳು ಮತ್ತು ಗೋಡೆಗಳ ಸ್ಥಾಪನೆ.
</li>
<li><span>ನೈಸರ್ಗಿಕ ವಾತಾಯನ : </span> ಯಾಂತ್ರಿಕ ತಂಪಾಗಿಸುವ ವ್ಯವಸ್ಥೆಗಳ ಅಗತ್ಯವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಮತ್ತು ಒಳಾಂಗಣ ಗಾಳಿಯ ಗುಣಮಟ್ಟವನ್ನು ಹೆಚ್ಚಿಸಲು ನೈಸರ್ಗಿಕ ವಾತಾಯನವನ್ನು ವಿನ್ಯಾಸಗೊಳಿಸುವುದು.
</li>
<li><span>ತ್ಯಾಜ್ಯ ಕಡಿತ ಮತ್ತು ಮರುಬಳಕೆ : </span> ಮರುಬಳಕೆ ಮತ್ತು ಜವಾಬ್ದಾರಿಯುತ ವಿಲೇವಾರಿ ಅಭ್ಯಾಸಗಳ ಮೂಲಕ ನಿರ್ಮಾಣ ತ್ಯಾಜ್ಯವನ್ನು ಕಡಿಮೆ ಮಾಡುವ ತಂತ್ರಗಳು.
</li>
<li><span>ಕಡಿಮೆ-ಪರಿಣಾಮದ ಸೈಟ್ ಅಭಿವೃದ್ಧಿ : </span> ನೈಸರ್ಗಿಕ ಭೂದೃಶ್ಯಗಳ ಸಂರಕ್ಷಣೆ, ಜವಾಬ್ದಾರಿಯುತ ಮಳೆನೀರಿನ ನಿರ್ವಹಣೆ ಮತ್ತು ನಿರ್ಮಾಣದ ಸಮಯದಲ್ಲಿ ಸ್ಥಳೀಯ ಪರಿಸರ ವ್ಯವಸ್ಥೆಗಳಿಗೆ ಅಡ್ಡಿಪಡಿಸುವಿಕೆಯನ್ನು ಕಡಿಮೆ ಮಾಡುವುದು.

</li>
<li><span>ಸ್ಮಾರ್ಟ್ ಬಿಲ್ಡಿಂಗ್ ಸಿಸ್ಟಮ್ಸ್ : </span> ಶಕ್ತಿ ನಿರ್ವಹಣೆ, ಸಮರ್ಥ ಬೆಳಕು ಮತ್ತು ತಾಪಮಾನ ನಿಯಂತ್ರಣಕ್ಕಾಗಿ ಸಂಪನ್ಮೂಲಗಳ ಬಳಕೆಯನ್ನು ಅತ್ಯುತ್ತಮವಾಗಿಸಲು ಸ್ಮಾರ್ಟ್ ತಂತ್ರಜ್ಞಾನಗಳ ಏಕೀಕರಣ.
</li>
<li><span>ಸ್ವೀಕೃತಿ : </span> ಝಾ ಫೌಂಡೇಶನ್‌ನಂತಹ ಸಂಸ್ಥೆಯಿಂದ ಸ್ವೀಕೃತಿಯನ್ನು ಪಡೆಯುವುದು, ಅದು ತಮ್ಮ ಕಟ್ಟಡಕ್ಕಾಗಿ ಸಮರ್ಥನೀಯ ತಂತ್ರಗಳೊಂದಿಗೆ ಯೋಜನೆಯನ್ನು ಹೊಂದಿರುವ ಬಿಲ್ಡರ್‌ಗಳ ಸಂಘವನ್ನು ಅಧಿಕೃತವಾಗಿ ಅಂಗೀಕರಿಸುತ್ತದೆ.
</li>

</ol>



<p>ಈ ವೈಶಿಷ್ಟ್ಯಗಳು ಒಟ್ಟಾರೆಯಾಗಿ ಪರಿಸರ ಜವಾಬ್ದಾರಿಯುತ ಕಟ್ಟಡಗಳನ್ನು ರಚಿಸಲು ಕೊಡುಗೆ ನೀಡುತ್ತವೆ, ಅದು ಅವರ ಜೀವನಚಕ್ರದ ಉದ್ದಕ್ಕೂ ಅವರ ಪರಿಸರ ಹೆಜ್ಜೆಗುರುತನ್ನು ಕಡಿಮೆ ಮಾಡುವ ಗುರಿಯನ್ನು ಹೊಂದಿದೆ.</p>


</section>
 

</div>
 
 
);
}

