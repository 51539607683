import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification">English</NavLink></a>

        </div>


<h1 id="mhead">Zha Eco-Friendly Certification</h1>
   
   <div className='second-mcontent'>
           <img src='../../img/eco.jpg'></img>
       </div>


 

<section className="visionmi">
 
	<div className="purpose">
	<h4>பார்வை</h4>
	<p>"ரியல் எஸ்டேட் வணிக முறைகளில் நிலைத்தன்மை மனப்பான்மையை வளர்ப்பதற்கான ழ அடித்தளம்"
</p>
	</div>

<div className="missi">
  <h4>ழ அடித்தளத்தின் கருத்து:
</h4>
<p> 
நிலைத்தன்மை உள்கட்டமைப்புகளை உள்ளடக்கிய பில்டர்கள் சுற்றுச்சூழலுக்கு உகந்த கட்டுமான முறைகள், ஆற்றல்-திறனுள்ள வடிவமைப்புகள் மற்றும் சுற்றுச்சூழலுக்கு பொறுப்பான வீடுகளை உருவாக்க நிலையான பொருட்களைப் பயன்படுத்துவதில் கவனம் செலுத்துகின்றனர். இந்த நடைமுறைகள் கட்டுமானத்தின் சுற்றுச்சூழல் தாக்கத்தை குறைத்து, வீடுகளின் நீண்டகால நிலைத்தன்மையை மேம்படுத்துவதை நோக்கமாகக் கொண்டுள்ளன.
</p>

<h4>ழ மதிப்பு உருவாக்கம் விளைவு:
</h4>
<p> 
நிலையான ரியல் எஸ்டேட் கருத்தாக்கங்களைச் சான்றளிப்பது, ழ மதிப்பீட்டாளர்களால் செய்யப்படும் சுற்றுச்சூழல் மதிப்பீட்டு முறையின் அடிப்படையில் கட்டுமானத்தை உறுதிசெய்யும் விதத்தில் சான்றிதழ்களைப் பெறுவதை உள்ளடக்குகிறது. எங்கள் ழ அறக்கட்டளை சான்றிதழ்கள், ரியல் எஸ்டேட் திட்டம் குறிப்பிட்ட சூழல் நட்பு மற்றும் நிலையான தரநிலைகளை கடைபிடிக்கிறது என்பதை ஒப்புக்கொண்டு சரிபார்க்கிறது, கட்டிடங்களின் கட்டுமானம் மற்றும் செயல்பாட்டில் வெளிப்படைத்தன்மை மற்றும் பொறுப்புணர்வை ஊக்குவிக்கிறது. எனவே பில்டர்கள் எங்கள் லோகோவை தங்கள் சந்தைப்படுத்தல் அல்லது திட்டத்தின் அம்சங்களை மேம்படுத்துவதில் பயன்படுத்தலாம்.

</p>
 

</div>
<p>சுற்றுச்சூழல் பாதிப்பைக் குறைப்பதற்கும் நிலைத்தன்மையை மேம்படுத்துவதற்கும் சுற்றுச்சூழல் நட்பு கட்டிடக் கட்டுமானம் பல்வேறு அம்சங்களை உள்ளடக்கியது. சில முக்கிய அம்சங்கள் பின்வருமாறு:
</p>
 
<ol className="goalpoints">

	
   
   <li><span>ஆற்றல் திறன் : </span> ஒட்டுமொத்த ஆற்றல் நுகர்வைக் குறைக்க ஆற்றல்-திறனுள்ள தொழில்நுட்பங்கள், உபகரணங்கள் மற்றும் விளக்குகளின் ஒருங்கிணைப்பு.
</li>

<li><span>புதுப்பிக்கத்தக்க எரிசக்தி ஆதாரங்கள் : </span>  சோலார் பேனல்கள், காற்றாலை விசையாழிகள் அல்லது புவிவெப்ப அமைப்புகள் போன்ற புதுப்பிக்கத்தக்க ஆற்றல் அமைப்புகளை இணைத்து சுத்தமான ஆற்றலை தளத்தில் உருவாக்குதல்.
</li>
<li><span>நிலையான பொருட்கள் : </span> சுற்றுச்சூழல் நட்பு மற்றும் மறுசுழற்சி செய்யப்பட்ட பொருட்களின் பயன்பாடு வள குறைபாட்டை குறைக்க மற்றும் கழிவு உற்பத்தியை குறைக்க.
</li>
<li><span>நீர் பாதுகாப்பு : </span> நீர் சேமிப்பு சாதனங்கள், மழைநீர் சேகரிப்பு அமைப்புகள் மற்றும் நீர் நுகர்வு குறைக்க திறமையான நீர்ப்பாசன நடைமுறைகளை செயல்படுத்துதல்.
</li>
<li><span>பசுமை கூரை மற்றும் சுவர்கள் : </span> மேம்படுத்தப்பட்ட காப்பு, குறைக்கப்பட்ட வெப்ப உறிஞ்சுதல் மற்றும் மேம்பட்ட பல்லுயிர் பெருக்கத்திற்காக பச்சை கூரைகள் மற்றும் சுவர்களை நிறுவுதல்.
</li>
<li><span>இயற்கை காற்றோட்டம் : </span>இயந்திர குளிரூட்டும் அமைப்புகளின் தேவையை குறைக்க மற்றும் உட்புற காற்றின் தரத்தை மேம்படுத்த இயற்கை காற்றோட்டத்தை வடிவமைத்தல்.
</li>
<li><span>கழிவு குறைப்பு மற்றும் மறுசுழற்சி : </span> மறுசுழற்சி மற்றும் பொறுப்பான அகற்றல் நடைமுறைகள் மூலம் கட்டுமான கழிவுகளை குறைப்பதற்கான உத்திகள்.
</li>
<li><span>குறைந்த தாக்கம் கொண்ட தள மேம்பாடு : </span> இயற்கை நிலப்பரப்புகளைப் பாதுகாத்தல், பொறுப்பான புயல் நீர் மேலாண்மை மற்றும் கட்டுமானத்தின் போது உள்ளூர் சுற்றுச்சூழல் அமைப்புகளுக்கு ஏற்படும் இடையூறுகளைக் குறைத்தல்.

</li>
<li><span>ஸ்மார்ட் பில்டிங் சிஸ்டம்ஸ் : </span> ஆற்றல் மேலாண்மை, திறமையான விளக்குகள் மற்றும் வெப்பநிலை கட்டுப்பாடு ஆகியவற்றிற்கான ஸ்மார்ட் தொழில்நுட்பங்களின் ஒருங்கிணைப்பு வள பயன்பாட்டை மேம்படுத்த.
</li>
<li><span>ஒப்புகை : </span> ழ அறக்கட்டளை போன்ற ஒரு நிறுவனத்திடமிருந்து ஒப்புதலைப் பெறுதல், இது அவர்களின் கட்டிடத்திற்கான நிலையான உத்திகளுடன் திட்டத்தைக் கொண்டிருக்கும் பில்டர்கள் சங்கத்தை அதிகாரப்பூர்வமாக அங்கீகரிக்கிறது.
</li>

</ol>



<p>இந்த அம்சங்கள் கூட்டாக சுற்றுச்சூழலுக்கு பொறுப்பான கட்டிடங்களை உருவாக்குவதற்கு பங்களிக்கின்றன, அவை அவற்றின் வாழ்நாள் முழுவதும் சுற்றுச்சூழல் தடயத்தை குறைக்கும் நோக்கத்தில் உள்ளன. </p>


</section>
 

</div>
 
 
);
}

