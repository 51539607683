import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">
            <div  id="language" >
        
        <a href=""><NavLink to="/Disaster-Relief">English</NavLink></a>

        </div>

                {/* <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item>         <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item>   <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item> <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

                <h1 id="mhead">ழ
 அறக்கட்டளையின் பேரிடர் நிவாரண முயற்சிகள்</h1>

                <div className='second-mcontent'>
                    <img src='../../img/recue/5.png'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>பூகம்பங்கள், புயல்கள், வெள்ளம் மற்றும் பல போன்ற பேரிடர்களால் பாதிக்கப்பட்டவர்களுக்கு ஆதரவளிக்கும் கலங்கரை விளக்கமாக நாங்கள் நிற்கும் Zha அறக்கட்டளைக்கு வரவேற்கிறோம். விரைவான மற்றும் பயனுள்ள பேரிடர் நிவாரணத்தை வழங்குவதற்கான எங்கள் அர்ப்பணிப்பு, நெருக்கடியின் பின்விளைவுகளை யாரும் தனியாக எதிர்கொள்ளக்கூடாது என்ற நம்பிக்கையில் வேரூன்றியுள்ளது.
                    </p>
                </div>

                <h3>எங்கள் நோக்கம்</h3>
                <div className="missi">
                    <h4 className="toleftneed">விரைவான பதில், நீடித்த தாக்கம்:</h4>
                    <p>ழ அறக்கட்டளையில், பேரிடர் சூழ்நிலைகளின் அவசரம் மற்றும் ஈர்ப்புத்தன்மையை நாங்கள் புரிந்துகொள்கிறோம். இயற்கை பேரழிவுகளால் பாதிக்கப்பட்ட சமூகங்களுக்கு விரைவான பதிலையும் ஆதரவையும் வழங்குவதும், உடனடி நிவாரணத்தை வழங்குவதும், நீண்ட கால மீட்பு மற்றும் பின்னடைவை நோக்கி செயல்படுவதும் எங்கள் நோக்கம்.</p>


                </div>


                <h3>எங்கள் அணுகுமுறை</h3>
                <div className="missi">
                    <h4 className="toleftneed">விரிவான பேரிடர் நிவாரணம்:</h4>
                    <p>ழ அறக்கட்டளை பேரிடர் நிவாரணத்திற்கு ஒரு விரிவான அணுகுமுறையை எடுக்கிறது. நாங்கள் அவசரகால தங்குமிடம், உணவு மற்றும் மருத்துவ உதவிகளை வழங்கும் ஆரம்பப் பதிலளிப்புக் கட்டத்தில் இருந்து, மீட்புக் கட்டம் வரை, சமூகங்களை மீண்டும் கட்டியெழுப்புதல் மற்றும் வாழ்வாதாரத்தை மீட்டெடுப்பதில் கவனம் செலுத்துவது வரை, பாதிக்கப்பட்ட நபர்களுக்குத் தேவையான ஆதரவை ஒவ்வொரு நிலையிலும் பெறுவதை உறுதி செய்வதில் எங்கள் குழுக்கள் அர்ப்பணித்துள்ளன.</p>

                    <h4 className="toleftneed">சமூகம் சார்ந்த தீர்வுகள்:</h4>
                    <p>சமூகங்களின் பலத்தை நாங்கள் நம்புகிறோம். எங்கள் பேரிடர் நிவாரண முயற்சிகள் உள்ளூர்வாசிகளுடன் இணைந்து, அவர்களின் தனித்துவமான தேவைகள், கலாச்சார சூழல் மற்றும் நிலப்பரப்பு பற்றிய அறிவு ஆகியவற்றைக் கருத்தில் கொண்டு வடிவமைக்கப்பட்டுள்ளன. சமூகங்களுடன் நெருக்கமாக பணியாற்றுவதன் மூலம், தனிநபர்கள் தங்கள் வாழ்க்கையை மீண்டும் கட்டியெழுப்புவதற்கு அதிகாரம் அளிக்கும் நிலையான தீர்வுகளை உருவாக்குவதை நோக்கமாகக் கொண்டுள்ளோம்.</p>
                </div>

                <h3>எங்கள் முன்முயற்சிகள்</h3>
                <div className="missi">
                    <h4 className="toleftneed">அவசர தங்குமிடம் மற்றும் உதவி:</h4>
                    <p>பேரிடர்களால் பாதிக்கப்பட்டவர்களுக்கு அவசரகால தங்குமிடம், உணவு, சுத்தமான நீர் மற்றும் மருத்துவ உதவிகளை வழங்க ஜா அறக்கட்டளை விரைவாக அணிதிரட்டுகிறது. நெருக்கடிக்குப் பின் உடனடியாக அடிப்படைத் தேவைகள் பூர்த்தி செய்யப்படுவதை உறுதிசெய்ய எங்கள் குழுக்கள் 24 மணி நேரமும் உழைக்கின்றன.</p>

                    <h4 className="toleftneed">வாழ்க்கையை மீண்டும் கட்டியெழுப்புதல்:</h4>
                    <p>ஆரம்ப பதிலுக்கு அப்பால், வாழ்க்கையை மீண்டும் கட்டியெழுப்புவதில் கவனம் செலுத்துகிறோம். இதில் புனரமைப்பு முயற்சிகள், வாழ்வாதார மறுசீரமைப்புக்கான ஆதாரங்களை வழங்குதல் மற்றும் சமூகங்கள் இயல்புநிலையை மீட்டெடுக்க உதவும் கல்வி மற்றும் சுகாதார சேவைகளை ஆதரித்தல் ஆகியவை அடங்கும்.</p>

                    <h4 className="toleftneed">சமூக மீள்திறன் திட்டங்கள்:</h4>
                    <p>Zha அறக்கட்டளை சமூகத்தின் பின்னடைவை மேம்படுத்தும் திட்டங்களில் முதலீடு செய்கிறது. இது உள்ளூர்வாசிகளுக்கு பேரிடர் தயார்நிலை, முன்கூட்டிய எச்சரிக்கை அமைப்புகள் மற்றும் எதிர்கால பேரழிவுகளின் தாக்கத்தைத் தணிக்க நிலையான நடைமுறைகள் ஆகியவற்றில் பயிற்சி அளிப்பதை உள்ளடக்கியது.</p>
                </div>


            </section>
        </div>


    );
}

