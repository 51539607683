import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
   <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Family-Wellness-Program">English</NavLink></a>

        </div>
         
 
            <div className="social-ma">

{/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
<h1>ഴ ഫാമിലി വെൽനസ് പ്രോഗ്രാം: യോജിപ്പുള്ള ജീവിതത്തിനായുള്ള സജീവമായ കൗൺസിലിംഗ്</h1>

<div className='second-mcontent'>
                    <img src='../../img/family.png'></img>
                </div>
 
 


 
<h3>ലക്ഷ്യം:</h3>
<p>ഴ ഫാമിലി വെൽനസ് പ്രോഗ്രാം സമ്മർദ്ദം, ഉത്കണ്ഠ, വാദപ്രതിവാദങ്ങൾ എന്നിവ തടയുന്നതിന് സജീവമായ കൗൺസിലിംഗ് വാഗ്ദാനം ചെയ്തുകൊണ്ട് യോജിപ്പുള്ള ജീവിതം പ്രോത്സാഹിപ്പിക്കുന്നതിന് രൂപകൽപ്പന ചെയ്ത ഒരു സമഗ്ര സംരംഭമാണ്. ഈ പരിപാടി ഭർത്താക്കന്മാരുടെയും ഭാര്യമാരുടെയും കുട്ടികളുടെയും വൈകാരിക ക്ഷേമം ശക്തിപ്പെടുത്തുന്നതിനും വെല്ലുവിളികളെ ഒരുമിച്ച് തരണം ചെയ്യാൻ പ്രാപ്തരായ ഒരു കുടുംബ യൂണിറ്റിനെ പരിപോഷിപ്പിക്കുന്നതിനുമാണ്.
</p>

 

<ol className="goalpoints">
    <h3>പ്രോഗ്രാം ഘടകങ്ങൾ:</h3>

<li><span>സജീവമായ ഫാമിലി കൗൺസിലിംഗ് സെഷനുകൾ: </span>  
<ul>
    <li>വ്യക്തിപരവും കൂട്ടായതുമായ സമ്മർദ്ദങ്ങൾ പരിഹരിക്കുന്നതിന് ഭർത്താക്കന്മാർക്കും ഭാര്യമാർക്കും കുട്ടികൾക്കുമായി പ്രത്യേക കൗൺസിലിംഗ് സെഷനുകൾ.



</li>
    <li>ഫലപ്രദമായ ആശയവിനിമയം കെട്ടിപ്പടുക്കുന്നതിനും പരസ്പരം കാഴ്ചപ്പാടുകൾ മനസ്സിലാക്കുന്നതിനും കുടുംബത്തിന്റെ ചലനാത്മകത മെച്ചപ്പെടുത്തുന്നതിനും ഊന്നൽ നൽകുന്നു.


</li>
</ul>
</li> 

<li><span>ഹോളിസ്റ്റിക് വെൽനസ് വർക്ക്ഷോപ്പുകൾ: </span>  
<ul>
    <li>സ്ട്രെസ് മാനേജ്മെന്റ്, വൈരുദ്ധ്യ പരിഹാരം, വൈകാരിക പ്രതിരോധം വളർത്തൽ എന്നിവയുൾപ്പെടെ നിരവധി വിഷയങ്ങൾ ഉൾക്കൊള്ളുന്ന അനുയോജ്യമായ വർക്ക്ഷോപ്പുകൾ.




</li>
    <li>മുഴുവൻ കുടുംബത്തിനും പങ്കെടുക്കാനും ഒരുമിച്ച് പഠിക്കാനുമുള്ള സംവേദനാത്മക സെഷനുകൾ.





</li>
</ul>
</li> 

<li><span>കുടുംബ ബന്ധത്തിന്റെ പ്രവർത്തനങ്ങൾ: </span>  
<ul>
    <li>കുടുംബബന്ധവും കൂട്ടായ പ്രവർത്തനവും പ്രോത്സാഹിപ്പിക്കുന്ന പ്രവർത്തനങ്ങൾ സംയോജിപ്പിക്കുക, ഐക്യബോധം ശക്തിപ്പെടുത്തുക.


</li>
    <li>ഔട്ട്‌ഡോർ ഇവന്റുകൾ, ക്രിയേറ്റീവ് വർക്ക്‌ഷോപ്പുകൾ, കുടുംബ ബന്ധങ്ങൾ ശക്തിപ്പെടുത്തുന്നതിനുള്ള സഹകരണ പദ്ധതികൾ.




</li>
</ul>
</li> 

<li><span>വ്യക്തിഗത ആരോഗ്യ പദ്ധതികൾ: </span>  
<ul>
    <li>ഓരോ കുടുംബാംഗത്തിനും അവരുടെ തനതായ ആവശ്യങ്ങളും വെല്ലുവിളികളും അടിസ്ഥാനമാക്കി വ്യക്തിഗതമാക്കിയ വെൽനെസ് പ്ലാനുകൾ വികസിപ്പിച്ചെടുത്തു.
</li>
    <li>സാഹചര്യങ്ങൾ വികസിക്കുമ്പോൾ ഈ പദ്ധതികൾ നടപ്പിലാക്കുന്നതിനും ക്രമീകരിക്കുന്നതിനുമുള്ള നിരന്തരമായ പിന്തുണ.
</li>
</ul>
</li> 

<li><span>രക്ഷാകർതൃ പിന്തുണയും വിദ്യാഭ്യാസവും:</span>  
<ul>
    <li>രക്ഷിതാക്കൾക്ക് അവരുടെ രക്ഷാകർതൃ കഴിവുകൾ വർദ്ധിപ്പിക്കുന്നതിനായി പ്രത്യേകം രൂപകൽപ്പന ചെയ്ത ശിൽപശാലകളും വിഭവങ്ങളും.
</li>
    <li>കുട്ടികളുമായുള്ള ഫലപ്രദമായ ആശയവിനിമയം, നല്ല അച്ചടക്ക തന്ത്രങ്ങൾ, പരിപോഷിപ്പിക്കുന്ന ഒരു വീട്ടുപരിസരം വളർത്തിയെടുക്കൽ എന്നിവയെക്കുറിച്ചുള്ള മാർഗ്ഗനിർദ്ദേശം.
</li>
</ul>
</li> 
<li><span>യുവ ശാക്തീകരണ സെഷനുകൾ:</span>  
<ul>
    <li>കുട്ടികളും കൗമാരക്കാരും അഭിമുഖീകരിക്കുന്ന സവിശേഷമായ വെല്ലുവിളികളെ അഭിസംബോധന ചെയ്യുന്നതിനായി പ്രത്യേക കൗൺസിലിംഗ് സെഷനുകളും വർക്ക് ഷോപ്പുകളും.
</li>
    <li>തുറന്ന സംഭാഷണത്തിന്റെ പ്രോത്സാഹനവും നേരിടാനുള്ള സംവിധാനങ്ങളുടെ വികസനവും.
</li>
</ul>
</li> 

</ol>

 
 

 

 

 

 
<h3>പ്രയോജനങ്ങൾ:</h3>
<ul className="goalpoints">
  <li>ഫലപ്രദമായ ആശയവിനിമയത്തിലൂടെയും ധാരണയിലൂടെയും കുടുംബബന്ധങ്ങൾ ശക്തിപ്പെടുത്തി.</li>  
  <li>കുടുംബത്തിനുള്ളിലെ സമ്മർദ്ദം, ഉത്കണ്ഠ, തർക്കങ്ങൾ എന്നിവ കുറയുന്നു.</li>  
  <li>മാതാപിതാക്കൾക്കും കുട്ടികൾക്കും വൈകാരിക പ്രതിരോധശേഷി വർദ്ധിപ്പിക്കുന്നു.</li>  
  <li>കുടുംബ യൂണിറ്റിനുള്ളിൽ ടീം വർക്കിന്റെയും പ്രശ്‌നപരിഹാര കഴിവുകളുടെയും വികസനം.</li>  
  <li>പോസിറ്റീവും അനുകൂലവുമായ ഒരു ഹോം പരിതസ്ഥിതിയുടെ പ്രമോഷൻ.
</li>  
 
</ul>


<h3>എങ്ങനെ പങ്കെടുക്കാം:</h3>
<ul className="goalpoints">
  <li>Zha ഫാമിലി വെൽനസ് പ്രോഗ്രാമിൽ താൽപ്പര്യമുള്ള കുടുംബങ്ങൾക്ക് അവരുടെ സമർപ്പിത കൗൺസിലിംഗ് സേവന ഹോട്ട്‌ലൈൻ അല്ലെങ്കിൽ വെബ്‌സൈറ്റ് വഴി Zha ഫൗണ്ടേഷനുമായി ബന്ധപ്പെട്ട് എൻറോൾ ചെയ്യാം.</li>  
  <li>പങ്കാളിത്തം സ്വമേധയാ ഉള്ളതും രഹസ്യാത്മകവും സമഗ്രമായ ക്ഷേമത്തിനായി സജീവമായ പിന്തുണ തേടുന്ന കുടുംബങ്ങൾക്ക് തുറന്നതുമാണ്.</li>  
 
 
</ul>
     
       
       <p>ഴ ഫാമിലി വെൽനസ് പ്രോഗ്രാം, എല്ലാ അംഗങ്ങൾക്കും ആരോഗ്യകരവും പിന്തുണ നൽകുന്നതുമായ അന്തരീക്ഷം പരിപോഷിപ്പിച്ചുകൊണ്ട്, ഒരു ഐക്യ ടീമെന്ന നിലയിൽ ജീവിത വെല്ലുവിളികളെ നാവിഗേറ്റ് ചെയ്യാൻ കുടുംബങ്ങളെ ശാക്തീകരിക്കുന്നതിനാണ് രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്. ഈ പ്രോഗ്രാമിനെക്കുറിച്ചുള്ള ഫീഡ്ബാക്ക് അല്ലെങ്കിൽ മെച്ചപ്പെടുത്തുന്നതിനുള്ള നിർദ്ദേശങ്ങൾ സ്വാഗതം ചെയ്യുന്നു.</p>
       
       
        </div>


        </div>




 
        


    );
}

