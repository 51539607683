import react from "react";
import React, { useState } from 'react';
import Video from './Videocarousel';
import Carousel from 'react-bootstrap/Carousel';
import "./Home.css";
import Testimonal from './Testimonal';
import Recue from './Recue';
import Card from 'react-bootstrap/Card';
import './Feedback.css';
import Recent from './Recent';
import Feedback from './Feedback';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Awards from './Feedback copy';
import Button from 'react-bootstrap/Button';



export default function Home() {
  return (
    <div>
      <div className="hmain">


        <Dropdown id="language">
          <Dropdown.Toggle id="droplang">
            <i class="fa-solid fa-globe"></i> Languages
          </Dropdown.Toggle>

          <Dropdown.Menu className="droplangslide">
            <Dropdown.Item><a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a> </Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a></Dropdown.Item>
            <Dropdown.Item><a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* 

<div  id="language" >
        
        <a href=""><NavLink to="/tamil-h">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/tamil-hma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/tamil-hhi">Hindi</NavLink></a>
        <a href=""><NavLink to="/tamil-hte">Telugu</NavLink></a>
        <a href=""><NavLink to="/tamil-hka">Kannada</NavLink></a>
        
        "start": "react-scripts start",

        </div> */}
        <div className="main-content">
          <h1>
            Zha Foundation Charitable Trust
          </h1>
          <p>"Professionals with Sustainability Mindset"</p>

          {/* <p> Our Zha Foundation is a Non Profit Organization which was founded in 2017, to help the Indian farmers through our Innovative Tech Consulting for free, that helps farmers have value added benefits in scaling their practices which promotes sustainable life of farmers, enables the youth interest in agriculture activities, thus creates food sustainability in the world. </p> */}
        </div>
        <div className="img-align">
          <img src="../../img/hero.webp"></img>
         
        </div>
        <div className="img-align">
        <NavLink to="/hh"><Button variant="success">Zha Sustainability Mindset Self Assesment</Button></NavLink>
        </div>
      
      </div>
      {/* <div className="whoweare">
        <div className="bar"></div>
        <h4>Who Are We?</h4>
    </div> */}

      {/* <div className="unique">
  <p>80G Unique Registration Number of Zha Foundation - AAATZ1755HF20231
</p>
  <p>12A Unique Registration Number of Zha Foundation - AAATZ1755HE20231</p>
</div> */}

      <section className="visionmi">
        {/* <div className="whoweare">
        <div className="bar"></div>
        <h4>Our Vision</h4>
    </div> */}



        <div className="purpose">
          <h4>Vision</h4>
          <p>To build a better future for the next generations, the ZHA Foundation is dedicated to the dual purpose of nurturing a sustainable mindset and conserving the natural resources of our planet.

          </p>
        </div>

        <div className="missi">
          <h4>Mission</h4>
          <p>The Zha Foundation, established in 2017, strives to cultivate a global community committed to fostering a sustainable mindset. We aim to transcend the negative impacts of unchecked greed and jealousy, recognizing their potential harm to nations and the planet's delicate balance. Collaborating with professionals and business leaders, our foundation advocates for a sustainable future, steering away from conflicts and promoting unity through three guiding principles for our zha members : no politics-based discussions, no discussions based on religion or caste, and a commitment to refrain from speaking negatively about fellow members.


          </p>

          <p>Sustainable development is imperative for a better tomorrow, balancing present needs without jeopardizing the well-being of future generations. The survival of our societies and shared planet hinges on fostering a sustainable mindset. The Sustainable Development Goals (SDGs), comprising seventeen interconnected objectives, serve as a "shared blueprint for peace and prosperity for people and the planet, now and into the future.
          </p>

          {/* <p className="goalpoints">" In which, we are focusing on the following
            <span>Goal 1: </span>Eradicate all forms of poverty through the promotion of sharing and living model concepts.
            <br />
            <span>Goal 2: </span>Achieve Zero Hunger by promoting food sustainability.
            <br />
            <span>Goal 3: </span>Foster health prosperity through the promotion of organic food consumption and provide sports clubs accessible to children, parents, etc., for holistic well-being.
            <br />
            <span>Goal 4: </span>Enhance education by ensuring all levels of students grasp both Plan A and Plan B concepts.
            <br />
            <span>Goal 5: </span>Advocate for gender equality and women's empowerment by emphasizing mutual respect for family wellness.
            <br />
            <span>Goal 6: </span>Ensure Water and Sanitation to minimize environmental impacts globally.<br /></p> */}
        </div>
        

      </section>


      {/* <h4>Zha Sustainability Enabler Framework</h4>
      <div className="img-align-ce">
        <img src="../../img/homepa.png"></img>
      </div> */}





      <h1 id="About-head">Our Offerings</h1>
      <h3>Contributing towards Sustainability Social Services</h3>


      <div className="listprimary">
        <div className="listprimarylist">
          <a href="">  <NavLink to="/Rural-Primary-Health-Care"><img src="../../img/PRIMARY/1.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"><img src="../../img/PRIMARY/2.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Disaster-Relief"><img src="../../img/PRIMARY/3.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Environment"><img src="../../img/PRIMARY/4.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/PRIMARY/9.webp"></img></NavLink></a>

        </div>

        <div className="listprimarylist">
          <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/PRIMARY/11.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students"> <img src="../../img/PRIMARY/6.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Pathfinder-Course">  <img src="../../img/PRIMARY/7.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/zha-farmers-transformation"> <img src="../../img/PRIMARY/8.webp"></img></NavLink></a>
          <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/PRIMARY/10.webp"></img></NavLink></a>
        </div>

      </div>

      <div className="listsecondary">
        <Carousel>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Rural-Primary-Health-Care"> <img src='../../img/PRIMARY/1.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Gender-Equality-and-Women-Empowerment"> <img src='../../img/PRIMARY/2.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Disaster-Relief"> <img src='../../img/PRIMARY/3.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Environment">  <img src='../../img/PRIMARY/4.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students">  <img src='../../img/PRIMARY/6.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Pathfinder-Course"> <img src='../../img/PRIMARY/7.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/zha-farmers-transformation"> <img src='../../img/PRIMARY/8.webp' text="Second slide" /></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Wellness-Program"><img src="../../img/PRIMARY/9.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Ecofriendly-Certification"> <img src="../../img/PRIMARY/10.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={200000}>
            <a href="">  <NavLink to="/Zha-Common-Humans-COPs"><img src="../../img/PRIMARY/11.webp"></img></NavLink></a>
            <Carousel.Caption>
              <div className='description'>
                <h3>  </h3>
                <p>  </p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </div>



      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recognition & Awards</h4>
      </div>

      <div className="feedback">
        <Awards />
      </div>





      <div className="whoweare">
        <div className="bar"></div>
        <h4>Recent Events</h4>
      </div>
      <div className="feedback">
        <Recent />
      </div>

      <div className="whoweare">
        <div className="bar"></div>
        <h4>Feedback</h4>
      </div>

      <div className="feedback">
        <Feedback />
      </div>



      <Video />


      {/* 
<section>
<div className="whoweare">
        <div className="bar"></div>
        <h4>Testimonial</h4>
    </div>
</section>

 
<div className="videoplayerr">
        <div className="ovall-container">
      <video className="ovall-video"  loop  controls>
     
        <source src={'../img/testimonial.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </div> */}




    </div>

  );
}



