import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Students-Sustainability-Club">English</NavLink></a>



</div>
{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>झा स्टूडेंट्स सस्टेनेबिलिटी क्लब</h1>
</div>


<div className="internship">
        <img src="../img/students.jpg"></img>
        </div>
<div className="goalpointsp">

<p> ZHA स्टूडेंट्स सस्टेनेबिलिटी क्लब सदस्यता कार्यक्रम एक समग्र पहल है जो ZHA फाउंडेशन द्वारा स्थापित स्थिरता के चार स्तंभों को शामिल करता है। यह व्यापक कार्यक्रम स्थिरता के विविध पहलुओं को संबोधित करने, भाग लेने वाले छात्रों के लिए एक सर्वांगीण और प्रभावशाली अनुभव सुनिश्चित करने के लिए डिज़ाइन किया गया है।

</p>

<ol className="goalpoints">
    <li><span>मानव कल्याण स्तंभ : </span> </li>
    <ul>
        <li>कार्यक्रम शैक्षिक सामग्री और कार्यशालाओं की पेशकश करके मानव कल्याण को बढ़ावा देता है जो स्थायी जीवन प्रथाओं, पर्यावरण के संबंध में मानसिक स्वास्थ्य और पर्यावरण के प्रति जागरूक मूल्यों के साथ व्यक्तिगत कल्याण पर ध्यान केंद्रित करता है।
 </li>
    </ul>

    <li><span>पृथ्वी कल्याण स्तंभ : </span> </li>
    <ul>
        <li>छात्र उन गतिविधियों और परियोजनाओं में संलग्न होते हैं जो पृथ्वी की भलाई में योगदान करते हैं। इसमें नवीकरणीय ऊर्जा, अपशिष्ट कटौती और पर्यावरण-अनुकूल प्रौद्योगिकियों से संबंधित पहलों के बारे में सीखना और उनमें सक्रिय रूप से भाग लेना शामिल है।
 </li>
    </ul>

    <li><span>प्रकृति कल्याण स्तंभ : </span> </li>
    <ul>
        <li>कार्यक्रम प्रकृति के साथ संबंध पर जोर देता है, छात्रों को जैव विविधता की सराहना करने और उसकी रक्षा करने के लिए प्रोत्साहित करता है। वृक्षारोपण, आवास बहाली और प्रकृति भ्रमण जैसी घटनाओं के माध्यम से, छात्रों में प्राकृतिक दुनिया के संरक्षण के महत्व की गहरी समझ विकसित होती है।
 </li>
    </ul>

    <li><span>लगातार लाभ स्तंभ : </span> </li>
    <ul>
        <li>सतत लाभ के संदर्भ में स्थिरता को छात्रों को स्थायी व्यावसायिक प्रथाओं के बारे में ज्ञान से लैस करके संबोधित किया जाता है। इस स्तंभ में पर्यावरण और सामाजिक जिम्मेदारियों को प्राथमिकता देते हुए हरित उद्यमिता, जिम्मेदार उपभोग और व्यवसायों को फलने-फूलने की रणनीतियों पर कार्यशालाएं शामिल हैं।
 </li>
    </ul>
  </ol>
<p>कुल मिलाकर, ZHA स्टूडेंट्स सस्टेनेबिलिटी क्लब सदस्यता कार्यक्रम अपनी समावेशिता के लिए खड़ा है, जो स्थिरता स्तंभों के पूर्ण स्पेक्ट्रम को कवर करता है। लगातार लाभ के विचारों के साथ मानव, पृथ्वी और प्रकृति कल्याण को एकीकृत करके, कार्यक्रम पर्यावरण के प्रति जागरूक नेताओं का पोषण करता है जो न केवल इन स्तंभों के अंतर्संबंध के बारे में जानते हैं बल्कि एक संतुलित और टिकाऊ भविष्य में सक्रिय रूप से योगदान करते हैं।</p>

<p>स्कूल और कॉलेज ZHA छात्र स्थिरता सदस्यता कार्यक्रम स्थिरता क्लबों की शुरूआत के माध्यम से स्कूलों को कई लाभ प्रदान करता है:
</p>
<ol className="goalpoints">
    <li><span>उन्नत शिक्षा : </span>स्कूलों को एक स्थिरता कोच तक पहुंच प्राप्त होती है जो न केवल ज्ञान प्रदान करता है बल्कि अनुभवात्मक शिक्षा की सुविधा भी देता है, जिससे छात्रों में सामाजिक जिम्मेदारी और स्थिरता की गहरी समझ पैदा होती है।

 </li>
    <li><span>समग्र विकास : </span>स्थिरता क्लब अवधारणा छात्रों की शिक्षा में सामाजिक जिम्मेदारी और स्थिरता मानसिकता को एकीकृत करके, उद्देश्य और वैश्विक नागरिकता की भावना को प्रोत्साहित करके समग्र विकास को बढ़ावा देती है।

 </li>
    <li><span>व्यावहारिक अनुप्रयोग :</span>सदस्यता क्लब गतिविधियों, परियोजनाओं और घटनाओं के माध्यम से स्थिरता सिद्धांतों के व्यावहारिक अनुप्रयोग के लिए एक मंच प्रदान करती है, जिससे छात्रों को सैद्धांतिक ज्ञान को वास्तविक दुनिया के कार्यों में अनुवाद करने की अनुमति मिलती है।
 </li>
    <li><span>सामुदायिक जुड़ाव : </span>स्थिरता क्लब छात्रों को अपने स्थानीय समुदायों के साथ सक्रिय रूप से जुड़ने, सामाजिक कारणों को बढ़ावा देने और आसपास के वातावरण में सकारात्मक प्रभाव पैदा करने के लिए प्रोत्साहित करते हैं।
 </li>
    <li><span>नेतृत्व के अवसर :</span>स्थिरता क्लबों में शामिल छात्रों को नेतृत्व की भूमिका निभाने, नेतृत्व कौशल को बढ़ावा देने और टिकाऊ प्रथाओं के प्रति अपने साथियों का मार्गदर्शन करने में जिम्मेदारी की भावना पैदा करने का अवसर मिलता है।

 </li>
    <li><span>नेटवर्किंग और सहयोग : </span>क्लब की सदस्यता स्कूलों को स्थिरता पहल में भाग लेने वाले शैक्षिक संस्थानों के व्यापक नेटवर्क से जोड़ती है, सहयोग, ज्ञान साझा करने और संयुक्त परियोजनाओं के अवसर पैदा करती है।
 </li>
    <li><span>पाठ्यचर्या के लक्ष्यों के साथ संरेखित : </span>स्थिरता सिद्धांतों का एकीकरण समकालीन शैक्षिक लक्ष्यों के साथ संरेखित होता है, जिससे स्कूलों को वैश्विक चुनौतियों का समाधान करने और सतत विकास में योगदान करने के लिए एक रूपरेखा प्रदान की जाती है।

 </li>
    <li><span>सकारात्मक स्कूल संस्कृति :</span>एक स्थिरता क्लब की उपस्थिति एक सकारात्मक और दूरदर्शी स्कूल संस्कृति में योगदान करती है, जो पर्यावरणीय प्रबंधन, सामाजिक जिम्मेदारी और नैतिक आचरण जैसे मूल्यों पर जोर देती है।

 </li>
    <li><span>मान्यता और मान्यता : </span>ZHA सस्टेनेबिलिटी क्लब से संबद्ध स्कूल स्थिरता के प्रति अपनी प्रतिबद्धता के लिए मान्यता प्राप्त करते हैं, जिससे संभावित रूप से मान्यता या पुरस्कार मिलते हैं जो एक स्थायी शैक्षिक वातावरण को बढ़ावा देने में उनके प्रयासों को उजागर करते हैं।

 </li>
    <li><span>भविष्य के लिए तैयारी : </span>स्थिरता क्लब अवधारणा छात्रों को पर्यावरण और सामाजिक स्थिरता पर तेजी से केंद्रित दुनिया में नेविगेट करने के लिए आवश्यक ज्ञान, कौशल और मूल्यों से लैस करके भविष्य के लिए तैयार करती है।

 </li>
  </ol>

  <p>संक्षेप में, ZHA छात्र स्थिरता सदस्यता स्थिरता शिक्षा को एकीकृत करके, समग्र विकास को बढ़ावा देकर और छात्रों को अधिक टिकाऊ और सामाजिक रूप से जिम्मेदार भविष्य में सक्रिय रूप से योगदान करने के लिए सशक्त बनाकर स्कूलों को लाभ पहुंचाती है।</p>
     
</div>
        

        </div>

    );
}

