import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";


export default function Ecofriendly() {
    return (
        <div>

<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process">English</NavLink></a>

        </div>

<h1 id="mhead">Zha Sustainable Certification Process</h1>
<p className="freecenter">“Free Certification with Zha Compliance”</p>
   
   <div className='second-mcontent'>
           <img src='../../img/certificate.jpg'></img>
       </div>


 

<section className="visionmi">
 
<p>బిల్డర్లు & రియల్టర్ల కోసం పర్యావరణ అనుకూల ధృవీకరణ పొందేందుకు, ఈ సాధారణ దశలను అనుసరించండి:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>రీసెర్చ్ ఝా అక్నాలెడ్జ్‌మెంట్ ప్రోగ్రామ్‌లు : </span> నిర్మాణ పరిశ్రమకు వర్తించే మా ప్రసిద్ధ పర్యావరణ అనుకూల ధృవీకరణ కార్యక్రమాలు, పర్యావరణ మదింపు ప్రమాణాల స్థాపనపై పరిశోధన

</li>

<li><span>అవసరాలను అర్థం చేసుకోండి : </span>  ఎంచుకున్న ధృవీకరణ యొక్క నిర్దిష్ట ప్రమాణాలు మరియు అవసరాలతో మిమ్మల్ని మీరు పరిచయం చేసుకోండి. ఇందులో స్థిరమైన నిర్మాణ వస్తువులు, శక్తి సామర్థ్యం, నీటి సంరక్షణ మరియు ఇండోర్ గాలి నాణ్యత ఉండవచ్చు.

</li>
<li><span>శిక్షణ మరియు విద్య : </span>  మీ బృందం స్థిరమైన నిర్మాణ పద్ధతులపై అవగాహన కలిగి ఉందని నిర్ధారించుకోండి. కొన్ని ధృవపత్రాలకు కీలకమైన సిబ్బందికి నిర్దిష్ట శిక్షణ అవసరం కావచ్చు.

</li>
<li><span>స్థిరమైన పద్ధతులను అమలు చేయండి : </span>  మీ నిర్మాణ ప్రక్రియల్లో పర్యావరణ అనుకూల పద్ధతులను ఏకీకృతం చేయండి. ఇందులో రీసైకిల్ చేసిన పదార్థాలను ఉపయోగించడం, శక్తి వినియోగాన్ని ఆప్టిమైజ్ చేయడం మరియు గ్రీన్ బిల్డింగ్ టెక్నిక్‌లను అనుసరించడం వంటివి ఉండవచ్చు.

</li>
<li><span>డాక్యుమెంటేషన్ : </span> మీ స్థిరమైన ప్రయత్నాల గురించి సమగ్ర రికార్డులను ఉంచండి. ధృవీకరణ కోసం డాక్యుమెంటేషన్ కీలకం, స్థాపించబడిన ప్రమాణాలకు అనుగుణంగా చూపుతుంది.

</li>
<li><span>వాటాదారులను నిమగ్నం చేయండి : </span> పర్యావరణ అనుకూల కార్యక్రమాలలో కాంట్రాక్టర్లు, సరఫరాదారులు మరియు కార్మికులతో సహా అన్ని వాటాదారులను పాల్గొనండి. ప్రతి ఒక్కరూ సర్టిఫికేషన్ సాధించాలనే లక్ష్యంతో మెలగాలి.

</li>
<li><span>ముందస్తు అసెస్‌మెంట్ : </span> అధికారిక ధృవీకరణ ప్రక్రియకు ముందు మెరుగుదల అవసరమయ్యే ప్రాంతాలను గుర్తించడానికి ముందస్తు అసెస్‌మెంట్‌ను పరిగణించండి. ఈ దశ ఏవైనా లోపాలను పరిష్కరించడంలో మరియు విజయావకాశాలను పెంచడంలో సహాయపడుతుంది.
</li>
<li><span>అధికారిక దరఖాస్తు : </span> ధృవీకరణ సంస్థకు అధికారిక దరఖాస్తును సమర్పించండి. అవసరమైన అన్ని డాక్యుమెంటేషన్, సాక్ష్యం మరియు రుసుములను చేర్చండి.


</li>
<li><span>సైట్ తనిఖీ : </span> ధృవీకరణ సంస్థ నుండి ప్రతినిధులచే సైట్ తనిఖీ కోసం సిద్ధం చేయండి. వారు మీ నిర్మాణ ప్రాజెక్ట్ పేర్కొన్న పర్యావరణ అనుకూల ప్రమాణాలకు అనుగుణంగా ఉందో లేదో అంచనా వేస్తారు.

</li>
<li><span>సర్టిఫికేషన్ నిర్ణయం : </span> ధృవీకరణ నిర్ణయం కోసం వేచి ఉండండి. విజయవంతమైతే, మీరు మీ ప్రాజెక్ట్ యొక్క పర్యావరణ అనుకూల స్థితికి అధికారిక గుర్తింపును అందుకుంటారు.

</li>

<li><span>సర్టిఫికేషన్‌ను ప్రోత్సహించండి : </span> ఒకసారి ధృవీకరించబడిన తర్వాత, మీ నిర్మాణ ప్రాజెక్టులను ప్రోత్సహించడానికి పర్యావరణ అనుకూల లేబుల్‌ని ఉపయోగించండి. ఇది మీ కంపెనీ ఖ్యాతిని పెంచుతుంది మరియు పర్యావరణ స్పృహ ఉన్న క్లయింట్‌లను ఆకర్షించగలదు.
</li>

<li><span>ప్రమోషన్‌లలో జోడించవచ్చు : </span> మీరు ZHA పర్యావరణ అనుకూల ధృవీకరణపై ZHA ఫౌండేషన్ నుండి నిర్ధారణ ఇమెయిల్‌ను స్వీకరించిన తర్వాత, బిల్డర్ దానిని దాని ప్రమోషన్ మరియు ప్రకటనలలో ప్రచురించవచ్చు. ZHA ఫౌండేషన్ వెబ్‌సైట్ ZHA ఎకో-ఫ్రెండ్లీ సస్టైనబిలిటీ కన్‌స్ట్రక్టర్‌లో సర్టిఫికేట్ పొందిన బిల్డర్‌లను కూడా జాబితా చేస్తుంది.</li>

</ol>



<p>గుర్తుంచుకోండి, ఎంచుకున్న ధృవీకరణ ప్రోగ్రామ్‌ను బట్టి నిర్దిష్ట దశలు మారవచ్చు, కాబట్టి ఎంచుకున్న ధృవీకరణ సంస్థ యొక్క అవసరాలను పూర్తిగా సమీక్షించడం చాలా అవసరం.</p>


</section>
 

</div>
 
 
);
}

