//Result.js

import React, { useState, useEffect } from 'react';
import useAuth from '../Login/useAuth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, Image } from 'react-bootstrap';
import firebase from '../firebaseConfig';

function Result() {
  const navigate = useNavigate();
  const location = useLocation();
  const allAnswers = location.state.answers;
  const allQuestions = location.state.questions;

  const [user, setUser] = useState({
    Name: '',
    Email: '',
    authenticated: false, // Initialize with false
  });

  const { currentUser } = useAuth();

  useEffect(() => {
    // Automatically send message to Firebase when component mounts
    if (currentUser) {
      sendToFirebase();
      setUser((prevUser) => ({ ...prevUser, authenticated: true }));
    }
  }, [currentUser]); // Make sure to include currentUser as a dependency

  const sendToFirebase = async () => {
    try {
      const data = {
        username: currentUser.email,
        percentile: calculatePercentile(),
      };
  
      console.log('Data to be sent:', data);
  
      const res = await fetch(
        'https://zha-signup-db-default-rtdb.firebaseio.com/UserData.json',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
  
      console.log('Firebase response:', res);
  
      if (!res.ok) {
        throw new Error(`Failed to send data to Firebase: ${res.statusText}`);
      }
  
      alert('Message sent to Firebase');
    } catch (error) {
      console.error('Error sending message to Firebase:', error);
      alert('Error sending message to Firebase');
    }
  };

  const calculatePercentile = () => {
    let correctAnswers = 0;

    allAnswers.forEach((item) => {
      if (item.trueAnswer) {
        correctAnswers += 1;
      }
    });

    return Math.round((correctAnswers / allQuestions.length) * 100);
  };

  return (
    <div className="result">
      <div className="quizresult">
        <div className="result-box">
          {/* <Dropdown align="end">
            <Dropdown.Toggle id="profile-dropdown" variant="white" style={{ border: 'none'}}>
              <Image
                src={currentUser?.photoURL || '/path/to/local/image.jpg'} // Update with your local path
                alt=" "
                className="rounded-circle"
                id='resultprofile'
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {user.authenticated ? (
                <>
                  <Dropdown.Item disabled>
                    Welcome,<br /> {currentUser.email}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              ) : (
                <>
                  <Dropdown.Item as={Link} to="/login">
                    Login
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/signup">
                    Signup
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown> */}

          <div className="result-bg">
            <span className="percentile">{calculatePercentile()}%</span>
          </div>
          <p className="result-detail">
            You answered {calculatePercentile()}% out of {allQuestions.length} questions correctly!
          </p>

          {calculatePercentile() < 50 && (
            <p className="result-message">Improve yourself</p>
          )}
          {calculatePercentile() >= 50 && calculatePercentile() < 70 && (
            <p className="result-message">You are doing okay</p>
          )}
          {calculatePercentile() >= 70 && calculatePercentile() < 100 && (
            <p className="result-message">You are a good human being</p>
          )}
          {calculatePercentile() === 100 && (
            <p className="result-message">You are perfect!</p>
          )}
        </div>

        <h2 className="check-answers-title">Check Correct Answers</h2>
        <div className="check-answers-boxes">
          {allQuestions.map((item, key) => (
            <div
              key={key}
              className={
                allAnswers[key].trueAnswer
                  ? 'check-answer-box correct'
                  : 'check-answer-box wrong'
              }
            >
              <div className="check-answer-top">
                <div className="check-texts">
                  <p className="check-answer-count">Question: {key + 1}</p>
                  <h3 className="check-answer-question">{item.question}</h3>
                </div>
                <div className="check-icon">
                  <i
                    className={
                      allAnswers[key].trueAnswer ? 'bi bi-check' : 'bi bi-x'
                    }
                  ></i>
                </div>
              </div>
              <div className="check-answer-bottom">
                <div className="answer-box">
                  <span className="answer-title">Your Answer</span>
                  <span className="answer-text">{allAnswers[key].answer}</span>
                </div>
                <div className="answer-box">
                  <span className="answer-title">Correct Answer</span>
                  <span className="answer-text">
                    {item.answers.map((ans) =>
                      ans.trueAnswer ? ans.answer : null
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Result;
