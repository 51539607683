import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
 

const questions = [
  {
    id: 1,
    category: "Human Wellness",
    question: "Do you engage in regular physical activity?",
    answers: [
      { answer: "Yes", trueAnswer: true },
      { answer: "No", trueAnswer: false },
    ],
  },
    {
      id: 2,
      category: "Human Wellness",
      question: "Do you prioritize getting enough sleep each night?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 3,
      category: "Human Wellness",
      question: "Do you consume a balanced and nutritious diet?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
     
      id: 4,
      category: "Human Wellness",
      question: "Do you practice stress management techniques regularly?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 5,
      category: "Human Wellness",
      question: "Do you avoid smoking or using tobacco products?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 6,
      category: "Human Wellness",
      question: "Do you limit your alcohol intake to moderate levels?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 7,
      category: "Human Wellness",
      question: "Do you schedule regular health check-ups?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 8,
      category: "Human Wellness",
      question: "Do you actively work to maintain a healthy weight?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 9,
      category: "Human Wellness",
      question: "Do you practice good hygiene habits?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 10,
      category: "Human Wellness",
      question: "Do you avoid excessive screen time and take breaks?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
 
    {
      id: 11,
      category: "Earth Wellness",
      question: "Do you recycle materials regularly?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 12,
      category: "Earth Wellness",
      question: "Do you use energy-efficient appliances in your home?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer:  true },
      ],
    },
    {
      id: 13,
      category: "Earth Wellness",
      question: "Do you avoid single-use plastic products whenever possible?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {

      id: 14,
      category: "Earth Wellness",
      question: "Do you support initiatives for reforestation and conservation?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 15,
      category: "Earth Wellness",
      question: "Do you use public transportation or carpool to reduce emissions?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true  },
      ],
    },
    {
      id: 16,
      category: "Earth Wellness",
      question: "Do you participate in community clean-up events?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 17,
      category: "Earth Wellness",
      question: "Do you conserve water by fixing leaks and using it responsibly?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 18,
      category: "Earth Wellness",
      question: "Do you purchase products with environmentally friendly packaging?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 19,
      category: "Earth Wellness",
      question: "Do you minimize your use of disposable products?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 20,
      category: "Earth Wellness",
      question: "Do you support companies with sustainable business practices?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
  
    {
      id: 21,
      category: "Consistent Profit",
      question: "Do you have a diversified investment portfolio?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 22,
      category: "Consistent Profit",
      question: "Do you actively manage and monitor your finances?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 23,
      category: "Consistent Profit",
      question: "Do you have an emergency fund for unexpected expenses?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 24,
      category: "Consistent Profit",
      question: "Do you invest in your education and skills development?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 25,
      category: "Consistent Profit",
      question: "Do you regularly review and adjust your financial goals?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 26,
      category: "Consistent Profit",
      question: "Do you actively seek new opportunities for income generation?",
      answers: [
        { answer: "Yes", trueAnswer: false},
        { answer: "No", trueAnswer: true},
      ],
    },
    {
      id: 27,
      category: "Consistent Profit",
      question: "Do you have a budget that you consistently follow?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 28,
      category: "Consistent Profit",
      question: "Do you have a long-term financial plan for retirement?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 29,
      category: "Consistent Profit",
      question: "Do you avoid unnecessary debt and manage existing debt responsibly?",
      answers: [
        { answer: "Yes", trueAnswer: false},
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 30,
      category: "Consistent Profit",
      question: "Do you have insurance coverage for your health and assets??",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
 
    {
      id: 31,
      category: "Nature Wellness",
      question: "Do you spend regular time outdoors in nature?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 32,
      category: "Nature Wellness",
      question: "Do you practice sustainable and responsible outdoor activities?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 33,
      category: "Nature Wellness",
      question: "Do you minimize your environmental impact when camping or hiking?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 34,
      category: "Nature Wellness",
      question: "Do you support organizations working for wildlife conservation?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 35,
      category: "Nature Wellness",
      question: "Do you practice Leave No Trace principles in natural settings?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 36,
      category: "Nature Wellness",
      question: "Do you participate in environmental restoration projects?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 37,
      category: "Nature Wellness",
      question: "Do you cultivate a garden or support local agriculture?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 38,
          category: "Nature Wellness",
      question: "Do you minimize your use of harmful pesticides and chemicals?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
    {
      id: 39,
      category: "Nature Wellness",
      question: "Do you appreciate and respect the biodiversity around you?",
      answers: [
        { answer: "Yes", trueAnswer: true },
        { answer: "No", trueAnswer: false },
      ],
    },
    {
      id: 40,
      category: "Nature Wellness",
      question: "Do you engage in activities that foster a connection with nature?",
      answers: [
        { answer: "Yes", trueAnswer: false },
        { answer: "No", trueAnswer: true },
      ],
    },
];
 
function Quiz() {
  const { level } = useParams();
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [time, setTime] = useState(30);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const isNextButton = currentQuestion < questions.length - 1;
  const isResultButton = currentQuestion >= questions.length - 1;

  const selectAnswer = (index) => {
    setSelectedIndex(index);
  };

  const nextQuestion = () => {
    addAnswer(selectedIndex);

    if (currentQuestion >= questions.length - 1) {
      setIsResult(true);
    } else {
      setTime(30);
      setCurrentQuestion(currentQuestion + 1);
      setSelectedIndex(null);
    }
  };

  const addAnswer = (index) => {
    const selectedAnswer =
      index !== null
        ? questions[currentQuestion].answers[index]
        : {
            answer: "Yes",
            trueAnswer: false,
          };
    const newAnswers = [...selectedAnswers, selectedAnswer];
    setSelectedAnswers(newAnswers);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    if (time <= 5) {
      setIsErrorMessage(true);
    } else {
      setIsErrorMessage(false);
    }

    if (time < 0) {
      clearInterval(timer);
      nextQuestion();
    }

    return () => clearInterval(timer);
  }, [time]);

 

  return isResult ? (
    navigate("/result", {
      state: {
        answers: selectedAnswers,
        questions,
      },
    })
  ) : (
    <div>
      <div className="quizhome">
        {/* Progress Box */}
        <div className="progress-box">
          {/* ... (unchanged) */}
        </div>

        {/* Question Box */}
        <div className="question-box">
          {/* Display category at the top */}
          <h2 className="category">{questions[currentQuestion].category}</h2>
          <br />
          <div className="question-text">
            <h2 className="question-title">Question: {currentQuestion + 1}</h2>
            <h3 className="question">
              {questions[currentQuestion].question}
            </h3>
          </div>
          <div
            className="progress-circle time"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ "--value": (time / 30) * 100 }}
          >
            <span className="time">{time}</span>
          </div>
        </div>

        {/* Answers Boxes */}
        <div className="answers-boxes">
          {questions[currentQuestion].answers.map((answer, index) => (
            <label
              onClick={() => selectAnswer(index)}
              key={index}
              htmlFor={index}
              className={
                selectedIndex === index
                  ? "answer-label selected"
                  : "answer-label"
              }
            >
              {answer.answer}
              <input type="radio" name="answer" id={index} />
            </label>
          ))}
        </div>

        {/* Next Button */}
        {isNextButton ? (
          <div className="next">
            <button
              onClick={nextQuestion}
              type="button"
              className="next-btn"
            >
              Next Question
              <div className="icon">
                <i className="bi bi-arrow-right"></i>
              </div>
            </button>
          </div>
        ) : null}

        {/* Result Button */}
        {isResultButton ? (
          <div className="next">
            <button
              onClick={nextQuestion}
              type="button"
              className="next-btn result-btn"
            >
              See Results
              <div className="icon">
                <i className="bi bi-bar-chart"></i>
              </div>
            </button>
          </div>
        ) : null}

        {/* Error Message */}
        {isErrorMessage ? (
          <div className="message animation">
            <div className="icon">
              <i className="bi bi-exclamation-triangle"></i>
            </div>
            <span>You must hurry up!</span>
          </div>
        ) : null}

        {/* Go To Home Page Link */}
        <div className="gotohomepage">
          <Link to="/hh" className="newquiz">
            Go To Home Page
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Quiz;